import { Flex } from "../components/Flex";
import { Box } from "../components/Box";
import { Image } from "../components/Image";
import { Input } from "../components/Input";
import { useState } from "react";
import { H1, H3 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Button } from "../components/Button";
import forgotPasswordSvg from "../assets/folder.svg";
import tillerVerifySvg from "../assets/verify-tiller.svg";
import lock from "../assets/lock.svg";
import { showError } from "../utils/error-handling";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";
import { useQueryParams } from "../utils/url";
import { useVerifyPasswordResetLink } from "../data/useVerifyPasswordResetLink";
import { Loader } from "../components/Loader";
import { Form, Formik } from "formik";
import { createPasswordValidation } from "../utils/validationSchema";
import { useCreatePassword } from "../data/useCreatePassword";

const CreatePassword = () => {
  const navigate = useNavigate();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const params = useQueryParams();
  const redirectUrl = params.get("redirect");
  const { isSuccess: isVerified, isLoading: isVerifyingLink } =
    useVerifyPasswordResetLink(redirectUrl);

  const { mutate: passwordCreate, isLoading } = useCreatePassword();

  const handleSubmit = ({ createPassword }) => {
    passwordCreate(
      {
        password: createPassword,
        link: redirectUrl,
      },
      {
        onError: (err: any) => {
          showError(err, "A problem occurred while creating your password.");
        },
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) setHasSubmitted(true);
        },
      }
    );
  };

  const step1 = (
    <Box>
      {isVerified ? (
        <>
          <H1 color="darkBlue">Welcome to Verify!</H1>
          <Paragraph color="darkBlue">
            Please create a secure password to access your Verify account.
          </Paragraph>

          <Formik
            initialValues={{
              createPassword: "",
              confirmPassword: "",
            }}
            validationSchema={createPasswordValidation}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <Input
                  onChange={props.handleChange}
                  value={props.values.createPassword}
                  type="password"
                  placeholder="Enter your password"
                  hasError={Boolean(props.errors.createPassword)}
                  errorMessage={props.errors.createPassword}
                  mt="3"
                  label="Create password"
                  name="createPassword"
                />
                <Paragraph color="gray.50" fontWeight="400" fontSize="0">
                  The password should be at least twelve characters long. To
                  make it stronger, use upper and lower case letters, numbers,
                  and symbols like !@#%&)
                </Paragraph>
                <Input
                  onChange={props.handleChange}
                  value={props.values.confirmPassword}
                  hasError={Boolean(props.errors.confirmPassword)}
                  errorMessage={props.errors.confirmPassword}
                  type="password"
                  placeholder="Confirm password"
                  mt="2"
                  label="Confirm password"
                  name="confirmPassword"
                />
                <Button
                  type="submit"
                  mt="4"
                  isDisabled={
                    !props.dirty || (props.dirty && !props.isValid) || isLoading
                  }
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <H1 color="darkBlue">Create password link expired</H1>
          <Paragraph color="darkBlue">
            This create password link has expired.
          </Paragraph>
          <Button
            onClick={() => navigate(ROUTE.RESET_PASSWORD)}
            size="large"
            mt="4"
            width="100%"
          >
            Get a new link
          </Button>
        </>
      )}
    </Box>
  );

  const step2 = (
    <Box>
      <H1 color="darkBlue">Password created!</H1>
      <Paragraph color="darkBlue">Use your new password to login.</Paragraph>
      <Link to={ROUTE.LOGIN}>
        <Button size="large" width="100%" mt="3">
          Go to login
        </Button>
      </Link>
    </Box>
  );

  if (!redirectUrl) {
    return <Navigate to={ROUTE.ROOT} replace />;
  }

  return (
    <Flex height="100vh">
      <Flex
        width="900px"
        justifyContent="space-between"
        flexDirection="column"
        p="60px"
      >
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="80px"
          width="100px"
        />
        {isVerifyingLink && (
          <Flex justifyContent="center" alignItems="center" minHeight="25vh">
            <Loader />
          </Flex>
        )}
        {!isVerifyingLink && (hasSubmitted ? step2 : step1)}
        <Box />
      </Flex>
      <Flex
        bg={"background"}
        height="100vh"
        width="100%"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
      >
        <Box width="55%">
          <Image src={lock} height="80px" width="100px" mb="60px" />
          <H3 color="darkBlue">
            Create a password to get access to your Verify by Tiller account.
          </H3>
          <Paragraph color="gray.50">
            Please ensure the password is always kept secure.{" "}
          </Paragraph>
        </Box>
        <Image
          src={forgotPasswordSvg}
          alt="folder"
          display="block"
          maxWidth="55%"
        />
      </Flex>
    </Flex>
  );
};

export default CreatePassword;
