import styled from "styled-components";
import { fadeIn } from "../animation";
import { Paragraph } from "../Paragraph";
import type { Checkpoint } from "../../types/Check";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Image } from "../Image";
import * as icons from "../../assets/checkpoints";
import { Button } from "../Button";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useState } from "react";
import classnames from "classnames";

const Wrapper = styled(Box)`
  animation: fadein 0.25s;
  ${fadeIn}

  ${({ theme: { colors, radii, space } }) => `
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;
      color: inherit;
      background: ${colors.gray[10]};
      border-radius: ${radii[1]};
      padding: ${space[2]};
      position: relative;
      transition: all 0.2s ease 0s;
      border: 1px solid white;

      &:active {
        transform: scale(1, 1);
      }

      // Dim the card when disabled
      &.disabled {
        opacity: 0.5;
        pointer-events: none;  // Prevents all interactions if disabled
      }
  `}
`;

const StyledDiv = styled.div`
  ${({ theme: { colors } }) => `
  &.light-grey > button {
    background-color: ${colors.gray[10]} !important;
    border: none !important;
  }

  &.disabled > button {
    background-color: ${colors.gray[200]} !important;
    color: ${colors.gray[500]} !important;
    border: none !important;
    cursor: not-allowed;
  }
  `}
`;

interface CheckCardProps extends Checkpoint {
  handleCheckpoints: (id: number, isAdding: boolean) => void;
  is_disabled?: boolean;
}

export const CheckCard = (props: CheckCardProps) => {
  const { id, name, actual_name, check_type, is_disabled = false } = props;
  const [isAdded, setIsAdded] = useState(false);

  const imageName = name.replace("_&", "") + "_large";

  return (
    <Wrapper className={classnames({ disabled: is_disabled })}>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Flex gap="12" justifyContent="space-between">
          <Image
            src={icons[imageName]}
            alt={`${actual_name} icon`}
            width="56px"
          />
          <Paragraph pl={check_type === "CORE" ? "" : "2"}>
            {actual_name}
          </Paragraph>
        </Flex>
        <Flex flexDirection="column" justifyContent={"start"}>
          <StyledDiv
            className={classnames({
              "light-grey": isAdded,
              disabled: is_disabled,
            })}
          >
            <Button
              Icon={isAdded ? FaTrash : FaPlus}
              iconSize="16px"
              size="regular"
              onClick={() => {
                if (!is_disabled) {
                  props.handleCheckpoints(id, !isAdded);
                  setIsAdded(!isAdded);
                }
              }}
              isDisabled={is_disabled}
              variant="secondary"
              border="1.5px solid #CCD3E0"
            >
              {isAdded ? "Remove" : "Add"}
            </Button>
          </StyledDiv>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
