import { createContext, useState, useContext } from "react";

const DirtyFormContext = createContext();

export const DirtyFormProvider = ({ children, formRef }) => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  const setFormDirty = (dirty) => {
    setIsFormDirty(dirty);
  };

  return (
    <DirtyFormContext.Provider value={{ isFormDirty, setFormDirty, formRef }}>
      {children}
    </DirtyFormContext.Provider>
  );
};

export const useDirtyForm = () => useContext(DirtyFormContext);
