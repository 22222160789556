import { useState } from "react";
import styled from "styled-components";
import {
  layout,
  color,
  typography,
  flexbox,
  border,
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  BorderProps,
} from "styled-system";
import { Flex } from "../Flex";
import { Paragraph } from "../Paragraph";
import { IconButton } from "../IconButton";
import { FiX, FiCheckCircle, FiAlertCircle, FiInfo, FiXCircle } from "react-icons/fi";
import { fadeIn } from "../animation";

type StyledSystemProps = SpaceProps &
  LayoutProps &
  ColorProps &
  TypographyProps &
  FlexboxProps &
  BorderProps;

type AlertType = "success" | "warning" | "error" | "info";

const getBackgroundColor = (alertType: AlertType, colors: any) => {
  switch (alertType) {
    case "success":
      return colors.green.primary;
    case "warning":
      return colors.orange.primary;
    case "error":
      return colors.red.primary;
    case "info":
      return colors.gray["10"];
    default:
      return colors.background;
  }
};

const getIcon = (alertType: AlertType) => {
  switch (alertType) {
    case "success":
      return <FiCheckCircle size="32" />;
    case "warning":
      return <FiAlertCircle size="32" color="#F48D53" />;
    case "error":
      return <FiXCircle size="32" />;
    case "info":
      return <FiInfo size="32" color="#B0B7C5" />;
    default:
      return null;
  }
};

interface AlertBoxContainerProps extends StyledSystemProps {
  alertType: AlertType;
  isFadingOut: boolean;
}

const AlertBoxContainer = styled.div<AlertBoxContainerProps>`
  animation: fadein 0.7s;
  ${fadeIn}

  ${({ isFadingOut }) => `
    opacity: ${isFadingOut ? 0 : 1};
  `}

  ${({ theme: { colors, radii, space }, alertType }) => `
    width: 100%;
    border-radius: ${radii[1]};
    padding: 24px;
    background-color: ${getBackgroundColor(alertType, colors)};
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${border}
  `}
`;

interface AlertBoxProps {
  closeAlert?: () => void;
  alertType: AlertType;
  showCloseButton?: boolean;
  children: React.ReactNode;
}

export const AlertBox: React.FC<AlertBoxProps> = ({
  closeAlert,
  alertType,
  children,
}) => {
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleClose = () => {
    setIsFadingOut(true);
    setTimeout(closeAlert, 250);
  };

  return (
    <AlertBoxContainer
      alertType={alertType}
      isFadingOut={isFadingOut}
      onAnimationEnd={() => isFadingOut && closeAlert()}
    >
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          {getIcon(alertType)}
          <Paragraph ml={2} fontSize={1}>
            {children}
          </Paragraph>
        </Flex>
        {closeAlert && (
          <IconButton
            Type={FiX}
            onClick={handleClose}
            aria-label="Close alert"
            color="gray.50"
          />
        )}
      </Flex>
    </AlertBoxContainer>
  );
};
