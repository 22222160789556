import { Formik, Form } from "formik";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { H3 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import { useChangeUserPassword } from "../../data/useChangeUserPassword";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { passwordResetValidation } from "../../utils/validationSchema";

export const ProfilePasswordReset = () => {
  const { mutate: updateUser, isLoading } = useChangeUserPassword();

  const onSubmit = (data, clearForm) => {
    const payload = {
      current_password: data.currentPassword,
      new_password: data.newPassword,
      confirm_password: data.confirmPassword,
    };

    updateUser(payload, {
      onSuccess: (res) => {
        if (res?.status?.toString().startsWith("2")) {
          notify("Password changed", {
            type: "success",
          });
        }
        clearForm();
      },
      onError: (err: any) => {
        showError(err, "A problem occurred while changing the password.");
        // displayError(
        //   collapseErrors(extractErrors(err)) ||
        //     "A problem occurred while changing the password."
        // );
        // logError(err);
      },
    });
  };

  return (
    <Box bg="white" borderRadius={0} p={5} maxWidth={900}>
      <H3 mb={3}>Change password</H3>

      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={passwordResetValidation}
        onSubmit={(values, actions) => {
          onSubmit(values, actions.resetForm);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Input
              onChange={props.handleChange}
              value={props.values.currentPassword}
              type="password"
              placeholder="Current password"
              hasError={Boolean(props.errors.currentPassword)}
              errorMessage={props.errors.currentPassword}
              label="Current password"
              name="currentPassword"
            />
            <Input
              onChange={props.handleChange}
              value={props.values.newPassword}
              type="password"
              placeholder="Enter new password"
              hasError={Boolean(props.errors.newPassword)}
              errorMessage={props.errors.newPassword}
              mt="3"
              label="New Password"
              name="newPassword"
            />
            <Paragraph color="gray.50" fontWeight="400" fontSize="0">
              The password should be at least twelve characters long. To make it
              stronger, use upper and lower case letters, numbers, and symbols
              like !@#%&)
            </Paragraph>
            <Input
              onChange={props.handleChange}
              value={props.values.confirmPassword}
              hasError={Boolean(props.errors.confirmPassword)}
              errorMessage={props.errors.confirmPassword}
              type="password"
              placeholder="Confirm password"
              mt="2"
              label="Confirm password"
              name="confirmPassword"
            />
            <Button type="submit" mt="4" isDisabled={isLoading}>
              Save
            </Button>
          </Form>
        )}
      </Formik>
      <Box />
    </Box>
  );
};
