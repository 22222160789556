import { Box } from "../../components/Box";
import { GrayCard } from "../../components/Card";
import { H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { IndividualStatus } from "../../context/MandateStatusContext";
import { IndividualCheckResponse } from "../../types/Individual";
import { Image } from "../../components/Image";
import success from "../../assets/card-success-icon.svg";
import inProgress from "../../assets/card-indeterminate-icon-gray.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import styled from "styled-components";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ${({ theme: { space } }) => `
    grid-gap: ${space[3]};
  `}
`;

type Props = {
  individual_checkfield_result: IndividualCheckResponse["individual_checkfield_result"];
};

export const CheckFields = (props: Props) => {
  const { individual_checkfield_result } = props;

  return (
    <>
      {individual_checkfield_result.length > 0 && (
        <GrayCard>
          {/* <Flex gap="30" flexWrap={"wrap"}> */}
          <Grid>
            {individual_checkfield_result?.map(
              (checkfield, i) =>
                checkfield?.check_field?.slug_name !== "latitude" &&
                checkfield?.check_field?.slug_name !== "longitude" && (
                  <Box key={i}>
                    {/* flex={1} maxWidth={"75%"}  */}
                    <Paragraph>
                      <Image
                        src={
                          checkfield?.status.status ===
                          IndividualStatus.Accepted
                            ? success
                            : checkfield?.status.status ===
                              IndividualStatus.Rejected
                            ? inReview
                            : inProgress
                        }
                        alt="status icon"
                        height="20px"
                        width="auto"
                        mr={1}
                      />
                    </Paragraph>
                    <H3>{checkfield?.check_field?.name}</H3>
                    <Paragraph my={1}>{checkfield?.result}</Paragraph>
                  </Box>
                )
            )}
          </Grid>
          {/* </Flex> */}
        </GrayCard>
      )}
    </>
  );
};
