import { Modal } from "../Modal";

interface Props {
  handleConfirm: () => void;
  isOpen: boolean;
  onRequestClose: () => void;
  title?: string;
  content?: string;
}

export const ConfirmModal = (props: Props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onRequestClose}
      width="300px"
      title={props.title || "Are you sure?"}
      content={props.content}
      primaryButtonText="Confirm"
      primaryButtonVariant="danger"
      secondaryButtonText="Cancel"
      hasSecondaryButton
      onModalConfirmClick={props.handleConfirm}
    />
  );
};
