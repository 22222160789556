import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { Client } from "../types/Client";
import { env } from "../utils/env";

export const useUpdateClient = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Client) => {
    return axios.patch(
      `${env.verifyServiceUrl}common/tenant/${params.id}/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
