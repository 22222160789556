import styled from "styled-components";
import { space } from "styled-system";
import { Box, BoxProps } from "../Box";
import { Icon } from "../Icon";
import { IoMdSend } from "react-icons/io";

const InputBase = styled.textarea`
  ${({
    hasError,
    buttonSize,
    theme: { radii, colors, space, fontSizes },
  }: any) => `
        resize: none;
        width: 100%;
        box-sizing: border-box;
        border-radius: ${radii[0]};
        border: 2px solid ${hasError ? colors.red.primary : colors.gray[30]};
        font-family: "Inter", sans-serif;
        color: ${colors.blue[100]};
        font-size: ${fontSizes[1]};
        padding: ${space[2]};
        transition: all .1s ease;

        ::placeholder {
            color: ${colors.gray[50]};
        }

        :hover {
            ${
              hasError
                ? ""
                : `
                border: 2px solid ${colors.gray[40]};
            `
            }
        }
    `}

  ${space}
`;

export interface CommentTextAreaProps extends BoxProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
  color?: string;
  onSendClick?: () => void;
}

export const CommentTextArea = (props: CommentTextAreaProps) => {
  const { onChange, value, placeholder, name, onSendClick, ...styleProps } =
    props;

  const inputProps = {
    placeholder,
    name,
    value,
  };

  const handleChange = (event: any) => {
    onChange(event);
  };

  const handleSend = () => {
    if (value && value.trim()) {
      onSendClick();
    }
  };

  return (
    <Box position="relative" {...styleProps}>
      <InputBase onChange={handleChange} {...inputProps} />
      <Icon
        Type={IoMdSend}
        size="26px"
        position="absolute"
        right="2"
        top="50%"
        cursor={value && value.trim() ? "pointer" : "not-allowed"}
        onClick={handleSend}
        color={value ? "blue.primary" : "gray.40"}
      />
    </Box>
  );
};
