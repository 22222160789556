import { useAuth } from "../context/AuthContext";

const Logout = () => {
  const { logout } = useAuth();
  logout();

  return undefined;
};

export default Logout;
