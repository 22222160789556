import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";


interface Params {
  id?: number;
  file: File;
}

export const useUpdateClientLogo = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    const formData = new FormData();
    formData.append("company_logo", params.file);

    return axios.patch(
      `${env.verifyServiceUrl}common/tenant/${params.id}/company_logo/`,
      formData,
      {
        headers: {
          Authorization: authHeader,
          'Content-Type': 'multipart/form-data'
        },
      }
    );
  });
};
