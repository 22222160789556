export const env = {
  verifyServiceUrl: process.env.REACT_APP_VERIFY_SERVICE_URL as string,
  cookieExpiryMs: Number.parseInt(
    process.env.REACT_APP_COOKIE_EXPIRY_MS as string
  ),
  cookieName: process.env.REACT_APP_COOKIE_NAME as string,
  verifyTermsAndConditionsUrl: process.env
    .REACT_APP_VERIFY_TERMS_AND_CONDITIONS_URL as string,
  verifyPrivacyPolicyUrl: process.env
    .REACT_APP_VERIFY_PRIVACY_POLICY_URL as string,
  isFilterApplicationsFeatureOn:
    process.env.REACT_APP_IS_FILTER_APPLICATIONS_FEATURE_ON === "true",
  isSearchApplicationsFeatureOn:
    process.env.REACT_APP_IS_SEARCH_APPLICATIONS_FEATURE_ON === "true",
  isSearchChecksFeatureOn:
    process.env.REACT_APP_IS_SEARCH_CHECKS_FEATURE_ON === "true",
  isSearchMonitoringFeatureOn:
  process.env.REACT_APP_IS_SEARCH_MONITORING_FEATURE_ON === "true",
  verifyGoogleMapKey: process.env.REACT_APP_GOOGLEMAP_KEY as string,
  verifyApplicationTypesLearnMoreUrl: process.env
  .REACT_APP_APPLICATIONTYPES_LEARN_MORE_URL as string,
  verifyApplicationSourceOFFundsLearnMoreUrl: process.env
  .REACT_APP_APPLICATIONSOURCEOFFUNDS_LEARN_MORE_URL as string,
  verifyApplicationPEPLearnMoreUrl: process.env
  .REACT_APP_APPLICATIONPEP_LEARN_MORE_URL as string,
  verifyApplicationSplashScreenLearnMoreUrl: process.env
  .REACT_APP_APPLICATIONSPLASHSCREEN_LEARN_MORE_URL as string,
};
