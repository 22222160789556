import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  user?: number;
  comment: string;
  comment_for: "Mandate" | "Individual" | "IndividualCheck";
  reference: number;
}

export const useCreateComment = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(`${env.verifyServiceUrl}individual/comments/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
