import { Formik, Form } from "formik";
import { Navigate } from "react-router-dom";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { H1 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Link } from "../../components/Link";
import { Paragraph } from "../../components/Paragraph";
import Select, { Option } from "../../components/Select";
import { useAuth } from "../../context/AuthContext";
import { useIndustries } from "../../data/useIndustries";
import { ROUTE } from "../../routes";
import { env } from "../../utils/env";
import { companyDetailsSignUpValidation } from "../../utils/validationSchema";
import { useCompanyTypes } from "../../data/useCompanyTypes";

export const CompanyDetailsStep = ({ onSubmit }: any) => {
  const { data: industries } = useIndustries();
  const { data: companyTypes } = useCompanyTypes();


  const { isLoggedIn } = useAuth();

  const industryOptions = industries?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  })) as Option[];

  const companyTypeOptions = companyTypes?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  })) as Option[];


  /** User is not ready for this step of the flow. */
  if (!isLoggedIn) {
    return <Navigate to={ROUTE.SIGN_UP} replace />;
  }

  return (
    <>
      <Formik
        initialValues={{
          companyName: "",
          companyType:"",
          companyWebsite: "",
          companyReg: "",
          companyIndustry: "",
          supportEmailAddress:"",
          supportPhoneNumber:"",
          hasAgreedTC: false,
        }}
        validationSchema={companyDetailsSignUpValidation}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Box>
              <H1 color="darkBlue">Get Started</H1>
              <Paragraph color="darkBlue">
                Create business profile and set up users
              </Paragraph>
              <Select
                value={companyTypeOptions?.find(
                  ({ value }) => value === props.values.companyType
                )}
                options={companyTypeOptions}
                onChange={(option: Option) =>
                  props.setFieldValue("companyType", option.value)
                }
                mt="2"
                label="Business type"
                name="companyType"
                isRequired
                hasError={Boolean(props.errors.companyType)}
                errorMessage={props.errors.companyType}
              />
              <Input
                onChange={props.handleChange}
                value={props.values.companyName}
                placeholder="Enter your company/trading name"
                mt="4"
                label="Business/Trading name"
                name="companyName"
                isRequired
                hasError={Boolean(props.errors.companyName)}
                errorMessage={props.errors.companyName}
              />
              <Input
                onChange={props.handleChange}
                value={props.values.companyWebsite}
                placeholder="Enter your company's website"
                mt="2"
                label="Business website"
                name="companyWebsite"
                isRequired
                hasError={Boolean(props.errors.companyWebsite)}
                errorMessage={props.errors.companyWebsite}
              />
              <Input
                onChange={props.handleChange}
                value={props.values.companyReg}
                placeholder="Enter your company's registration number"
                mt="4"
                label="Registration number (optional)"
                name="companyReg"
                hasError={Boolean(props.errors.companyReg)}
                errorMessage={props.errors.companyReg}
              />
                            <Select
                value={industryOptions?.find(
                  ({ value }) => value === props.values.companyIndustry
                )}
                options={industryOptions}
                onChange={(option: Option) =>
                  props.setFieldValue("companyIndustry", option.value)
                }
                mt="2"
                label="Industry"
                name="companyIndustry"
                isRequired
                hasError={Boolean(props.errors.companyIndustry)}
                errorMessage={props.errors.companyIndustry}
              />
              <Input
                onChange={props.handleChange}
                value={props.values.supportEmailAddress}
                placeholder="Enter your company's support email address"
                mt="4"
                label="Company support email address"
                name="supportEmailAddress"
                hasError={Boolean(props.errors.supportEmailAddress)}
                errorMessage={props.errors.supportEmailAddress}
              />
              <Input
                onChange={props.handleChange}
                value={props.values.supportPhoneNumber}
                placeholder="Enter your company's support phone number"
                mt="4"
                label="Company support phone number"
                name="supportPhoneNumber"
                hasError={Boolean(props.errors.supportPhoneNumber)}
                errorMessage={props.errors.supportPhoneNumber}
              />


              <Checkbox
                onChange={props.handleChange}
                label={
                  <Paragraph
                    color="gray.50"
                    fontSize="1"
                    fontWeight="400"
                    mt="2"
                  >
                    I have authority to and agree to the Verify by Tiller{" "}
                    <Link
                      fontSize="1"
                      href={env.verifyTermsAndConditionsUrl}
                      target="_blank"
                    >
                      Terms and Conditions{" "}
                    </Link>
                    on behalf of the company as well as acknowledge the{" "}
                    <Link
                      fontSize="1"
                      href={env.verifyPrivacyPolicyUrl}
                      target="_blank"
                    >
                      Privacy Policy.{" "}
                    </Link>
                  </Paragraph>
                }
                isChecked={props.values.hasAgreedTC}
                mt="2"
                name="hasAgreedTC"
              />
            </Box>
            <Box>
              <Button
                isDisabled={!props.values.hasAgreedTC || !props.isValid}
                size="large"
                width="100%"
                mt="2"
                mb="0"
                type="submit"
              >
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Box />
    </>
  );
};
