import { Flex } from "../../components/Flex";
import { Box } from "../../components/Box";
import { Image } from "../../components/Image";
import { H1 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import signUpSvg from "../../assets/sign-up.svg";
import tillerVerifySvg from "../../assets/verify-tiller.svg";
import lock from "../../assets/lock.svg";
import { ROUTE } from "../../routes";
import { UserDetailsStep } from "./UserDetailsStep";
import { EmailVerificationStep } from "./EmailVerificationStep";
import { CompanyDetailsStep } from "./CompanyDetailsStep";
import { CompanyAddressStep } from "./CompanyAddressStep";
import { useResendVerificationCode } from "../../data/useResendVerificationCode";
import { useAuth, UserVerificationStatus } from "../../context/AuthContext";
import { displayError, logError, showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { useState } from "react";
import { useVerifyCode } from "../../data/useVerifyCode";
import { Button } from "../../components/Button";
import { useCreateClient } from "../../data/useCreateClient";
import { useCreatePrimaryPurpose } from "../../data/useCreatePrimaryPurpose";
import { BillingInformationStep } from "./BillingInformationStep";
import { BillingAddressStep } from "./BillingAddressStep";
import { useCreateBillingInfo } from "../../data/useCreateBillingInfo";
import { PrimaryPurposeStep } from "./PrimaryPurposeStep";
import { DPOStep } from "./DPOStep";
import { useCreateDPODetail } from "../../data/useCreateDPODetail";
import { DPO } from "../../types/DPO";

interface BillingInfo {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  taxRegNumber: string;
  same_as_company_address: boolean;
}
const SignUp = () => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const { credentials, logout, userVerificationStatus, isLoggedIn } = useAuth();
  const { mutate: createClient } = useCreateClient();
  const { mutate: createBillingInfo } = useCreateBillingInfo();
  const { mutate: createPrimaryPurpose } = useCreatePrimaryPurpose();
  const { mutate: createDPODetail } = useCreateDPODetail();
  const [billingInfo, setBillingInfo] = useState(null);

  const [doubleClickOnNext, setDoubleClickOnNext] = useState(false);

  const doubleClickEvent = (value: boolean) => {
    setDoubleClickOnNext(value);
  };

  const [companyDetails, setCompanyDetails] = useState(null);

  const { mutate: verifyCode, isLoading: isLoadingVerifyCode } =
    useVerifyCode();
  const {
    mutate: resendVerificationCode,
    isLoading: isLoadingResendVerifyCode,
  } = useResendVerificationCode();

  const [hasCodeBeenResent, setHasCodeBeenResent] = useState(false);

  const isLoadingVerify = isLoadingVerifyCode || isLoadingResendVerifyCode;

  const handleVerifyButtonClick = (code: string) => {
    if (credentials) {
      verifyCode(
        {
          email: credentials?.email,
          code,
        },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2"))
              navigate(ROUTE.ADD_COMPANY);
          },
          onError: (err: any) => {
            showError(err, "A problem occurred while verifying your code.");
            // displayError(
            //   extractErrors(err)?.verify_code?.[0] ||
            //     "A problem occurred while verifying your code."
            // );
            logError(err);
          },
        }
      );
    }
  };

  const handleResendVerificationCode = () => {
    if (credentials) {
      setHasCodeBeenResent(true);

      resendVerificationCode(
        { email: credentials?.email },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2"))
              notify("Code successfully sent, please check your email.", {
                position: "bottom-left",
                type: "success",
              });
          },
          onError: (err: any) => {
            setHasCodeBeenResent(false);
            showError(err, "A problem occurred while resending code.");
            // displayError("A problem occurred while resending code.");
            // logError(err);
          },
        }
      );
    } else {
      logError("Cannot resend verification code, no user logged-in.");
    }
  };

  const handleSaveCompanyDetails = (data) => {
    setCompanyDetails(data);
    navigate(ROUTE.ADD_COMPANY_ADDRESS);
  };

  const handleCreateCompany = (addressDetails: any) => {
    createClient(
      {
        name: companyDetails.companyName,
        company_type: companyDetails.companyType,
        address: companyDetails.companyAddress,
        registration_number: companyDetails.companyReg,
        support_email_address: companyDetails.supportEmailAddress,
        support_phone_number: companyDetails.supportPhoneNumber,
        industry: companyDetails.companyIndustry,
        website: companyDetails.companyWebsite,
        ...addressDetails,
      },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            navigate(ROUTE.ADD_BILLING_INFORMATION);
            doubleClickEvent(false);
          }
        },
        onError: (err: any) => {
          doubleClickEvent(false);
          showError(err, "A problem occurred while creating this tenant.");
        },
      }
    );
  };
  const handleBillingInfo = (billingData: BillingInfo) => {
    if (billingData.same_as_company_address) {
      const billingObj: any = {
        first_name: billingData.firstName,
        last_name: billingData.lastName,
        email: billingData.email,
        phone_number: billingData.telephone,
        tax_registeration_number: billingData.taxRegNumber,
        same_as_company_address: billingData.same_as_company_address,
      };
      createBillingInfo(billingObj, {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            navigate(ROUTE.ADD_PRIMARY_PURPOSE);
            doubleClickEvent(false);
          }
        },
        onError: (err: any) => {
          showError(
            err,
            "A problem occurred while adding billing informations."
          );
          doubleClickEvent(false);
        },
      });
    } else {
      setBillingInfo(billingData);
      navigate(ROUTE.ADD_BILLING_ADDRESS);
    }
  };

  const handleBillingAddress = (billingAddressDetails: any) => {
    const billingDetails = billingAddressDetails.same_as_company_address
      ? {
          first_name: billingInfo.firstName,
          last_name: billingInfo.lastName,
          email: billingInfo.email,
          phone_number: billingInfo.telephone,
          tax_registeration_number: billingInfo.taxRegNumber,
          same_as_company_address:
            billingAddressDetails.same_as_company_address,
        }
      : {
          first_name: billingInfo.firstName,
          last_name: billingInfo.lastName,
          email: billingInfo.email,
          phone_number: billingInfo.telephone,
          tax_registeration_number: billingInfo.taxRegNumber,
          ...billingAddressDetails,
        };

    createBillingInfo(billingDetails, {
      onSuccess: (res) => {
        if (res?.status?.toString().startsWith("2")) {
          navigate(ROUTE.ADD_PRIMARY_PURPOSE);
          doubleClickEvent(false);
        }
      },
      onError: (err: any) => {
        showError(err, "A problem occurred while adding billing informations.");
        doubleClickEvent(false);
      },
    });
  };

  const handleDPODetails = (DPO_Data: DPO) => {
    createDPODetail(DPO_Data, {
      onSuccess: (res) => {
        if (res?.status?.toString().startsWith("2")) {
          navigate(ROUTE.ACCOUNT_PENDING);
          doubleClickEvent(false);
        }
      },
      onError: (err: any) => {
        showError(err, "A problem occurred while adding DPO details.");
        doubleClickEvent(false);
      },
    });
  };

  const handlePrimaryPurpose = (primary_purpose_data: any) => {
    createPrimaryPurpose(primary_purpose_data, {
      onSuccess: (res) => {
        if (res?.status?.toString().startsWith("2")) {
          if (primary_purpose_data.dpo === true) {
            navigate(ROUTE.ADD_DPO);
          } else {
            navigate(ROUTE.ACCOUNT_PENDING);
          }

          doubleClickEvent(false);
        }
      },
      onError: (err: any) => {
        showError(
          err,
          "A problem occurred while adding primary purpose details."
        );
        doubleClickEvent(false);
      },
    });
  };

  /** User has already completed sign-up and verification. */
  if (userVerificationStatus === UserVerificationStatus.Verified) {
    return <Navigate to={ROUTE.ROOT} replace />;
  }

  return (
    <Flex height="100vh">
      <Flex
        width="900px"
        justifyContent="flex-start"
        // justifyContent="space-between"
        flexDirection="column"
        p="60px"
        overflowY="auto"
      >
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="80px"
          width="100px"
        />
        {[ROUTE.SIGN_UP].includes(path) && <UserDetailsStep />}
        {[ROUTE.VERIFY].includes(path) && (
          <EmailVerificationStep
            onVerifyButtonClick={handleVerifyButtonClick}
            onResendCodeClick={handleResendVerificationCode}
            hasCodeBeenResent={hasCodeBeenResent}
            isLoading={isLoadingVerify}
          />
        )}
        {[ROUTE.ADD_COMPANY].includes(path) && (
          <CompanyDetailsStep onSubmit={handleSaveCompanyDetails} />
        )}
        {[ROUTE.ADD_COMPANY_ADDRESS].includes(path) && (
          // <CompanyAddressStep onSubmit={handleCreateCompany} />
          <CompanyAddressStep
            onSubmit={handleCreateCompany}
            doubleClickCompany={doubleClickOnNext}
            doubleClick={doubleClickEvent}
          />
        )}
        {[ROUTE.ADD_BILLING_INFORMATION].includes(path) && (
          <BillingInformationStep onSubmit={handleBillingInfo} />
        )}
        {[ROUTE.ADD_BILLING_ADDRESS].includes(path) && (
          <BillingAddressStep
            onSubmit={handleBillingAddress}
            doubleClickBilling={doubleClickOnNext}
            doubleClick={doubleClickEvent}
          />
        )}
        {[ROUTE.ADD_PRIMARY_PURPOSE].includes(path) && (
          <PrimaryPurposeStep onSubmit={handlePrimaryPurpose} />
        )}
        {[ROUTE.ADD_DPO].includes(path) && (
          <DPOStep
            onSubmit={handleDPODetails}
            doubleClickMlro={doubleClickOnNext}
            doubleClick={doubleClickEvent}
          />
        )}
      </Flex>
      <Flex
        bg={"background"}
        display={{ _: "none", lg: "flex" }}
        height="100vh"
        width="100%"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
      >
        <Box width="55%">
          <Image src={lock} height="80px" width="100px" mb="60px" mt="2" />
          <H1 color="darkBlue">
            Unlock Verify’s full features. What are you waiting for? Let’s get
            started today!
          </H1>
          <Paragraph color="gray.50" fontSize="3">
            Create your account today and unlock all Verify’s features.
          </Paragraph>
        </Box>
        <Image
          src={signUpSvg}
          alt="lock image"
          display="block"
          width="55%"
          height="60%"
        />
      </Flex>
      {isLoggedIn && (
        <Button
          position="absolute"
          top={2}
          right={2}
          variant="secondary"
          onClick={() => logout()}
        >
          Sign In
        </Button>
      )}
    </Flex>
  );
};

export default SignUp;
