import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

export const useUpdateTenantFilters = () => {
  const { authHeader } = useAuth();

  return useMutation((params: any) => {
    return axios.put(`${env.verifyServiceUrl}common/tenant/filter/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
