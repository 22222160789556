import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { AdditionalDocument } from "../types/AdditionalDocumentsType";

const fetch = (authHeader: string, id: number) => () =>
  axios.get<AdditionalDocument>(`${env.verifyServiceUrl}individual/additional_document/${id}/`, {
    headers: {
      Authorization: authHeader,
    },
  }).then(response => response.data); // Return only the data part of the response

export const useAdditionalDocumentById = (id: number, enabled: boolean) => {
  const { authHeader } = useAuth();

  return useQuery(["documentDetails", id], fetch(authHeader, id), {
    enabled,
    retry: false,
  });
};
