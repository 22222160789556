import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { form, Section } from "../types/Forms";

interface FormSectionResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Section[];
}

const fetch = (authHeader: string, id: number) => () =>
  axios.get<FormSectionResponse>(
    `${env.verifyServiceUrl}individual/section/?_form=${id}&all=true/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useFormSections = (id: number) => {
  const { authHeader } = useAuth();
  return useQuery(["formSections", id], fetch(authHeader, id), {
    enabled: Number.isInteger(id),
    retry: false,
    select: (response) => response.data.results,
  });
};
