import ReactModal from "react-modal";
import styled from "styled-components";
import {
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  PositionProps,
} from "styled-system";
import { H2 } from "../Heading";
import { Paragraph } from "../Paragraph";
import { Flex } from "../Flex";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { IoMdClose } from "react-icons/io";
import { Image } from "../Image";
import success from "../../assets/success-icon.svg";
import error from "../../assets/error-icon.svg";
import { useState } from "react";
import bannerImage from "../../assets/modal-banner.svg";
import { Box } from "../Box";

type ModalProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  PositionProps & {
    /** Must be a styled-system color key. */
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    hasModalBannerImage?: boolean;
    content?: string;
    onModalConfirmClick?: any;
    onSecondaryButtonClick?: any;
    secondaryButtonText?: string;
    primaryButtonText?: string;
    primaryButtonVariant?: "danger" | "primary";
    secondaryButtonVariant?: "danger" | "primary" | "text" | "danger-text";
    hasSecondaryButton?: boolean;
    hasSecondaryActionButton?: boolean;
    hasPrimaryButton?: boolean;
    isSuccessful?: boolean;
    isClosingDisabled?: boolean;
    isLoadingAction?: boolean;
    hasIcon?: boolean;
    hasCloseIcon?: boolean;
    children?: any;
    width?: string;
    loadingActionText?: string;
    doubleClick?: boolean;
    hasCommentBox?: boolean;
  };

const ModalStyle = styled.div<any>`
  margin: 2rem;
  padding: 20px;
  border-radius: 15px;
  top: "50%";
  left: "50%";
  right: "auto";
  bottom: "auto";
  marginright: "-50%";
  transform: "translate(-50%, -50%)";
  overflow: unset;

  ${({ theme: { breakpoints }, width }) => `

    ${width ? `width: ${width};` : ""}

    @media screen and (max-width: ${breakpoints.md}) {
      width: 80%;
      height: 50vh;
    }
  `};
`;

const CommentTextArea = styled.textarea<any>`
  ${({ theme: { radii, colors, space, fontSizes } }: any) => `
      resize: none;
      width: 100%;
      box-sizing: border-box;
      border-radius: ${radii[0]};
      border: 2px solid ${colors.gray[30]};
      font-family: "Inter", sans-serif;
      color: ${colors.blue[100]};
      font-size: ${fontSizes[1]};
      padding: ${space[2]};
      transition: all .1s ease;

      ::placeholder {
          color: ${colors.gray[50]};
      }
      

        ::placeholder {
            color: ${colors.gray[50]};
        }

        :hover {
            ${`
                border: 2px solid ${colors.gray[40]};
            `}
        }
    `}
`;

const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: red;
  overflow-y: auto;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    border: "none",
    padding: "30px",
    maxHeight: "95%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

export const Modal = (props: ModalProps) => {
  const {
    hasModalBannerImage,
    isOpen,
    onClose,
    title,
    content,
    onModalConfirmClick,
    onSecondaryButtonClick,
    secondaryButtonText,
    primaryButtonText,
    primaryButtonVariant,
    secondaryButtonVariant,
    hasSecondaryButton,
    hasSecondaryActionButton,
    hasPrimaryButton = true,
    isClosingDisabled,
    isLoadingAction,
    loadingActionText,
    isSuccessful,
    hasIcon,
    hasCloseIcon = true,
    children,
    width,
    doubleClick,
    hasCommentBox = false,
  } = props;
  const [value, setValue] = useState("");
  const [isCommentBoxHasValue, setIsCommentBoxHasValue] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value.length > 2) {
      setIsCommentBoxHasValue(true);
    } else {
      setIsCommentBoxHasValue(false);
    }
  };

  let formattedContent = {
    __html:
      content || typeof content === "string"
        ? content.replace(/\r\n/g, "<br>")
        : content,
  };

  return (
    <ReactModal
      shouldCloseOnOverlayClick={!isClosingDisabled}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById("root") || undefined}
      contentElement={(props, children) => (
        <ModalStyle {...props} width={width}>
          {children}
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => (
        <OverlayStyle {...props}>{contentElement}</OverlayStyle>
      )}
      style={customStyles}
    >
      {hasCloseIcon && (
        <IconButton
          isDisabled={isClosingDisabled}
          onClick={
            isClosingDisabled
              ? () => {}
              : () => {
                  setValue("");
                  onClose();
                }
          }
          Type={IoMdClose}
          position="absolute"
          right={2}
          size="23px"
          top={2}
        />
      )}
      {hasModalBannerImage && (
        <Box
          backgroundColor={"#D0D7E2"}
          p={2}
          borderRadius={10}
          textAlign={"center"}
          mb={2}
        >
          <Image
            src={bannerImage}
            alt="feature banner"
            // height="100%"
            // width="100%"
          />
        </Box>
      )}
      {children ? (
        children
      ) : (
        <>
          {hasIcon && (
            <Image
              src={isSuccessful ? success : error}
              alt="indicator icon"
              height="48px"
              width="48px"
            />
          )}
          <H2 mr={3}>{title}</H2>
          <Paragraph
            fontSize="1"
            color="gray.40"
            dangerouslySetInnerHTML={formattedContent}
          />

          {hasCommentBox && (
            <CommentTextArea
              name="textarea"
              value={value}
              onChange={handleChange}
            ></CommentTextArea>
          )}
          <Flex justifyContent="end">
            {hasSecondaryButton && (
              <Button
                isDisabled={isClosingDisabled}
                // onClick={onClose}
                onClick={() => {
                  setValue("");
                  onClose();
                }}
                variant={
                  secondaryButtonVariant ? secondaryButtonVariant : "text"
                }
                my="3"
                mr={2}
                // color="gray.50"
                color={
                  secondaryButtonVariant === "danger-text"
                    ? "red.primary"
                    : "gray.50"
                }
              >
                {secondaryButtonText}
              </Button>
            )}
            {hasSecondaryActionButton && (
              <Button
                isDisabled={isClosingDisabled}
                onClick={() => {
                  onSecondaryButtonClick(value);
                }}
                variant={
                  secondaryButtonVariant ? secondaryButtonVariant : "text"
                }
                my="3"
                mr={2}
                color={
                  secondaryButtonVariant === "danger-text"
                    ? "red.primary"
                    : "gray.50"
                }
              >
                {secondaryButtonText}
              </Button>
            )}
            {hasPrimaryButton && (
              <Button
                isDisabled={
                  isClosingDisabled ||
                  doubleClick ||
                  (hasCommentBox && !isCommentBoxHasValue)
                }
                onClick={() => {
                  setValue("");
                  onModalConfirmClick(value);
                }}
                variant={primaryButtonVariant}
                my="3"
              >
                {isLoadingAction ? loadingActionText : primaryButtonText}
              </Button>
            )}
          </Flex>
        </>
      )}
    </ReactModal>
  );
};
