import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { FilterOption } from "../types/TenantFilter";

const fetchFilterOptions = (authHeader: string, categories: string[]) => {
  const requests = categories.map((category) =>
    axios.get<FilterOption[]>(
      `${env.verifyServiceUrl}common/tenant/filter/option?category=${category}`,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    )
  );
  return axios.all(requests).then(
    axios.spread((...responses) => {
      return responses.map((response) => response.data);
    })
  );
};

export const useMultipleFilterOptions = (categories: string[]) => {
  const { authHeader } = useAuth();
  return useQuery(
    ["tenantFilterOptions", ...categories],
    () => fetchFilterOptions(authHeader, categories),
    {
      retry: true,
    }
  );
};
