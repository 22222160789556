import { FaSpinner } from "react-icons/fa";
import styled from "styled-components";
import { spin } from "../animation";
import { Box, BoxProps } from "../Box";
import { Icon } from "../Icon";

const Wrapper = styled.div`
  animation: spin 2s linear infinite;
  position: absolute;

  ${spin};
`;

export const Loader = (props: BoxProps) => {
  return (
    <Box {...props}>
      <Wrapper>
        <Icon size="36px" Type={FaSpinner} />
      </Wrapper>
    </Box>
  );
};
