import styled from "styled-components";

const Label = styled.label`
  ${({ theme: { colors, fontSizes } }) => `
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[1]};
        color: ${colors.blue[100]};
        display: flex;
        align-items: "center";        
    `}
`;

const RadioButton = ({ label, value, checked, onChange }) => {
  return (
    <Label>
      <input type="radio" value={value} checked={checked} onChange={onChange} />
      {label}
    </Label>
  );
};

export default RadioButton;
