import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { form } from "../types/Forms";

const fetch = (authHeader: string, id: number) => () =>
  axios.get<form>(`${env.verifyServiceUrl}individual/form/${id}/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useForm = (id: number) => {
  const { authHeader } = useAuth();
  return useQuery(["form", id], fetch(authHeader, id), {
    enabled: Number.isInteger(id),
    retry: false,
  });
};
