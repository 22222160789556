import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "../../../components/Box";
import { Button } from "../../../components/Button";
import { Flex } from "../../../components/Flex";
import { H3 } from "../../../components/Heading";
import { FormDetails } from "./FormDetails";
import SettingsNewFormQuestions from "./SettingsNewFormQuestions";
import { useForm } from "../../../data/useForm";
import { useFormSections } from "../../../data/useFormSections";
import { showError } from "../../../utils/error-handling";
import { useDeleteForm } from "../../../data/useDeleteForm";
import { ROUTE } from "../../../routes";
import { Loader } from "../../../components/Loader";
import { FaArrowLeft } from "react-icons/fa";
import { IconButton } from "../../../components/IconButton";

const SettingsFormView = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: form, isLoading } = useForm(parseInt(params.id));
  const { data: sections, isLoading: isSectionsLoading } = useFormSections(parseInt(params.id));
  const { mutate: deleteForm } = useDeleteForm();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    sections: []
  });

  const handleFormDelete = () => {
    deleteForm(Number(params.id), {
      onSuccess: () => navigate(ROUTE.SETTINGS_FORMS),
      onError: (err: any) => showError(err, "A problem occurred while deleting the individual.")
    });
  };

  useEffect(() => {
    if (form && sections) {
      setFormData({
        title: form.data?.name || "",
        description: form.data?.description || "",
        sections
      });
    }
  }, [form, sections]);

  return (
    <>
      {(isLoading || isSectionsLoading) ? (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      ) : (
        <Box bg="white" width="96%" flexWrap="wrap" borderRadius="16px" padding={32}>
          <Flex mb={3} justifyContent="space-between" alignItems="center">
            <Flex gap="16">
              <IconButton
                onClick={() => navigate(ROUTE.SETTINGS_FORMS)}
                Type={FaArrowLeft}
                right={2}
                size="14px"
                top={2}
                color="gray.50"
              />
              <H3 mb="0">{formData.title}</H3>
            </Flex>
            <Button variant="danger" type="button" onClick={handleFormDelete}>
              Delete
            </Button>
          </Flex>

          <Box>
            <Box borderBottom="1px solid" borderBottomColor="gray.10" mb={2}>
              <FormDetails
                initialValues={{ name: formData.title, description: formData.description }}
                editMode={true}
              />
            </Box>
            <SettingsNewFormQuestions />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SettingsFormView;
