import { IoMdInformationCircleOutline } from "react-icons/io";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { CheckCard } from "../../components/CheckCard";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { ROUTE } from "../../routes";
import { ApplicationTypeNewFrame } from "./ApplicationTypeNewFrame";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useChecks } from "../../data/useChecks";
import styled from "styled-components";
import { notify } from "../../utils/notify";
import { useUpdateMandateType } from "../../data/useUpdateMandateType";
import { Icon } from "../../components/Icon";
import { Tooltip } from "../../components/Tooltip";
import { useBillingInfo } from "../../data/useBillingInfo";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(334px, 1fr));
  ${({ theme: { space } }) => `
  grid-gap: ${space[3]};
  overflow: hidden;
  margin-top: 26px;
  `}
`;

const StyledLink = styled.a`
  color: #3158f2;
  text-decoration: none;

  &:hover {
    text-decoration: underline; /* Optional hover effect */
  }
`;

const SettingsApplicationTypeNewChecks = () => {
  const [checkpoints, setCheckpoints] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const { mutate: updateMandateType } = useUpdateMandateType();
  const { data: billingData, isLoading: billingInfoLoading } = useBillingInfo();
  const [selectedMandateTypeId, setSelectedMandateTypeId] = useState(null);

  // Safeguard to check if billingData and nested objects are available
  const billingInfo =
    billingData?.data?.results?.length > 0
      ? billingData?.data?.results[0]
      : null;

  const { data: checkListQuery } = useChecks();
  const checkList = checkListQuery?.data;

  const personalDetailCheck = checkList?.find(
    (check) => check.name === "personal_details_match_check"
  );

  const personalDetailCheckId = personalDetailCheck?.id;

  const coreChecks = checkList
    ?.filter((check) => check.billing_type.includes("Basic"))
    .sort((a, b) => a.order - b.order);

  const addOnChecks = checkList
    ?.filter((check) => !check.billing_type.includes("Basic"))
    .sort((a, b) => a.order - b.order);

  useEffect(() => {
    const id = parseInt(params.id);
    setSelectedMandateTypeId(id);
  }, [params.id]);

  useEffect(() => {
    if (personalDetailCheckId) {
      setCheckpoints([personalDetailCheckId]);
    }
  }, [personalDetailCheckId]);

  const handleCheckpoints = (id: number, isAdding: boolean) => {
    if (isAdding) {
      setCheckpoints([...checkpoints, id]);
    } else {
      setCheckpoints(checkpoints.filter((checkpoint) => checkpoint !== id));
    }
  };

  const handleSubmit = () => {
    updateMandateType(
      { id: selectedMandateTypeId, checkpoint: checkpoints },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            notify("Checks added successfully!", {
              type: "success",
            });
          }
          navigate(
            `/settings/application-types/${selectedMandateTypeId}/documents`
          );
        },
      }
    );
  };

  return (
    <>
      <ApplicationTypeNewFrame step="checks">
        <Box pl={4} pr={2} pt={4} flex={4} minWidth={400}>
          <H2 fontSize="3">Create New Application Type</H2>

          <Box pt={2}>
            <H2 fontSize={3} fontWeight={500}>
              Basic Checks
            </H2>
            {!coreChecks || coreChecks.length === 0 ? (
              <Flex
                justifyContent="center"
                alignItems="center"
                minHeight="25vh"
              >
                <Loader />
              </Flex>
            ) : (
              <Grid>
                {coreChecks?.map((check) => (
                  <CheckCard
                    key={check.id}
                    id={check.id}
                    name={check.name}
                    actual_name={check.actual_name}
                    check_type={check.check_type}
                    handleCheckpoints={handleCheckpoints}
                  />
                ))}
              </Grid>
            )}
          </Box>
          <Box
            borderRadius={0}
            pr={4}
            pt={4}
            flex={2}
            height="fit-content"
            position="relative"
          >
            <Flex gap="8" mt={4} pt={1} alignItems={"center"}>
              <H2 fontSize={3} fontWeight={500}>
                Additional Checks
              </H2>
              <Tooltip
                position="bottom"
                content={
                  <>
                    Check our plans and upgrade in order to unlock additional
                    checks that you can add to your application type.{" "}
                    <StyledLink
                      href="https://www.tiller-verify.com/pricing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      pricing page
                    </StyledLink>
                  </>
                }
              >
                <Icon Type={IoMdInformationCircleOutline} aria-hidden="true" />
              </Tooltip>
            </Flex>
            {!addOnChecks || addOnChecks.length === 0 ? (
              <Flex
                justifyContent="center"
                alignItems="center"
                minHeight="25vh"
              >
                <Loader />
              </Flex>
            ) : (
              <Grid>
                {addOnChecks?.map((check) => {
                  if (
                    check.name !== "personal_details_match_check" &&
                    check.name !== "additional_document_check"
                  ) {
                    return (
                      <CheckCard
                        key={check.id}
                        id={check.id}
                        name={check.name}
                        actual_name={check.actual_name}
                        check_type={check.check_type}
                        handleCheckpoints={handleCheckpoints}
                        is_disabled={billingInfo.billing_type === "Basic"}
                      />
                    );
                  }
                  return null; // Return null if the condition is false to avoid rendering anything
                })}
              </Grid>
            )}
            <Flex mt={4} justifyContent="end">
              <Button
                mr="2"
                size="regular"
                variant="secondary"
                onClick={() => navigate(ROUTE.SETTINGS_APPLICATION_TYPES)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={handleSubmit}
                size="regular"
                isDisabled={!checkpoints || checkpoints.length === 0}
              >
                Create
              </Button>
            </Flex>
          </Box>
        </Box>
      </ApplicationTypeNewFrame>
    </>
  );
};

export default SettingsApplicationTypeNewChecks;
