import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";
import { axios } from "./axios";
import { MandateCheckList } from "../types/MandateCheckList";
import { env } from "../utils/env";

const fetch = (authHeader: string, minimal: boolean) => () =>
  axios.get<MandateCheckList[]>(
    `${env.verifyServiceUrl}individual/mandate_check/?name__in=address_verification_check,pep_%26_sanctions_check,adverse_media_check&minimal=${minimal}`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useMandateCheckList = (minimal: boolean = false) => {
  const { authHeader } = useAuth();
  return useQuery(["mandate_check_list", minimal], fetch(authHeader, minimal), { retry: false });
};
