import ReactSelect, { components } from "react-select";
import { useTheme } from "styled-components";
import { Box, BoxProps } from "../Box";
import { Label } from "../Label";
import { ProfileOrb } from "../ProfileOrb";
import "./styles.css";

export type Option = {
  label: string;
  value: string | number;
  firstName: string;
  lastName: string;
  colour: string;
};

interface SelectProps extends BoxProps {
  options?: Option[] | Option;
  onChange: (newValue: Option[] | Option) => void;
  value: Option[];
  color?: string;
  label?: string;
  size?: "regular" | "large";
  name?: string;
  isMulti?: boolean;
  initialValue?: Option[];
  isDisabled?: boolean;
}

export const UserFilter = (props: SelectProps) => {
  const {
    options,
    onChange,
    value,
    label,
    name,
    size = "large",
    initialValue,
    isMulti,
    isDisabled,
    ...styleProps
  } = props;

  const { colors, radii, space } = useTheme() as any;

  const CustomInput = (inputProps: any) => {
    return (
      <>
        <components.Input className="main" {...inputProps} />
      </>
    );
  };

  return (
    <Box {...styleProps}>
      {label && <Label>{label}</Label>}
      <ReactSelect
        isDisabled={isDisabled}
        isMulti={isMulti}
        options={options as any}
        onChange={onChange as any}
        value={value ?? initialValue}
        placeholder="User"
        name={name}
        closeMenuOnSelect
        components={{
          // Input: () => <></>,
          Input: CustomInput,
          SingleValue: ({ data, clearValue }) => (
            <ProfileOrb
              firstName={data.firstName}
              lastName={data.lastName}
              onClick={() => {
                clearValue();
              }}
              colour={data.colour}
              cursor="pointer"
              top="-16px"
            />
          ),
          MultiValue: ({ data }) => (
            <ProfileOrb
              firstName={data.firstName}
              lastName={data.lastName}
              onClick={() =>
                onChange(value.filter(({ value }) => value !== data.value))
              }
              colour={data.colour}
              cursor="pointer"
              mr="-7px"
            />
          ),
        }}
        styles={{
          control: (base, { menuIsOpen }) => ({
            ...base,
            border: `2px solid ${
              menuIsOpen ? colors.blue.primary : colors.gray[30]
            }`,
            borderRadius: radii[0],
            height: 60,
            paddingLeft: space[1],
            fontSize: "14px",
            fontFamily: "Inter",
            boxShadow: "none",
            maxHeight: size === "regular" && 40,
            "::placeholder": {
              color: colors.gray[50],
            },
            ":hover": {
              border: `2px solid ${
                menuIsOpen ? colors.blue.primary : colors.gray[40]
              }`,
            },
            ...space,
          }),
          menu: (base) => ({
            ...base,
            minWidth: "220px",
            left: "-100px",
          }),
          option: (base, { isSelected, isFocused }) => ({
            ...base,
            cursor: "pointer",
            fontFamily: "Inter",
            height: size === "regular" ? 40 : 60,
            display: "flex",
            alignItems: "center",
            minWidth: "220px",
            fontWeight: isSelected ? "bold" : "regular",
            backgroundColor:
              isFocused || isSelected ? colors.background : colors.white,
            color: "#aaaaaaa",
            ":hover": {
              backgroundColor: colors.background,
            },
            ...space,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            height: "100%",
          }),
          indicatorSeparator: (base) => ({
            ...base,
            marginBottom: "0px",
            height: "46%",
          }),
        }}
      />
    </Box>
  );
};
