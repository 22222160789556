/* eslint-disable import/no-anonymous-default-export */
const breakpoints = ["40em", "52em", "64em", "80em"] as any;

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export default {
  fontFamily: "'Inter', sans-serif",
  fontSizes: ["12px", "14px", "16px", "20px", "28px", "32px", "40px"],
  radii: ["8px", "20px"],
  space: ["4px", "8px", "16px", "24px", "32px", "40px"],
  breakpoints,
  colors: {
    white: "#ffffff",
    gray: {
      5: "#F4F4F4",
      10: "#F1F4F8",
      20: "#cccccc",
      30: "#CCD3E0",
      40: "#B0B7C5",
      50: "#828B9D",
      60: "#677084",
      80: "#2D374B",
      100: "#E6E7EB",
    },
    green: {
      primary: "#40DA9E",
      50: "#DFF9EF",
    },
    orange: {
      primary: "#FDECE2",
      50: "#F48D53",
      100: "#F48D52"
    },
    blue: {
      primary: "#3158F2",
      primaryEmphasis: "#0d37da",
      10: "#CFD8FC",
      20: "#BCC9FB",
      50: "#DDE3FD",
      100: "#09093E",
    },
    red: {
      primary: "#F75E6E",
      50: "#FEE4E7",
      20: "#ff9ca5",
      40: "#F64153",
    },
    background: "#F1F4F8",
    darkBlue: "#09093E",
    pink:{
      100: "#FEECEE",
    }
  },
};
