import { useMutation } from "@tanstack/react-query"
import { axios } from "./axios"
import { useAuth } from "../context/AuthContext"
import { env } from "../utils/env"
import { MandateType } from "../types/MandateType"

export const useCreateMandateType = () => {
  const { authHeader } = useAuth()

  return useMutation((params: MandateType) => {
    return axios.post<MandateType>(
      `${env.verifyServiceUrl}individual/mandate_type/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    )
  })
}
