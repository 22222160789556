import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Box } from "../../../components/Box";
import {
  StaticBreadcrumb,
  Crumb,
  BreadcrumbDivider,
} from "../../../components/Breadcrumbs";
import { Flex } from "../../../components/Flex";
import { IconButton } from "../../../components/IconButton";
import { ROUTE } from "../../../routes";
import { H2 } from "../../../components/Heading";

export const SettingsFormFrame = ({
  children,
  step,
}: {
  children: any;
  step: "details" | "questions";
}) => {
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      isActive: step === "details",
      title: "01 Details",
      key: 1,
    },
    {
      isActive: step === "questions",
      title: "02 Questions",
      key: 2,
    },
  ];

  return (
    <>
      <Box height="calc(100vh - 83px)" bg="white" borderRadius={8}>
        <Box bg="white" borderRadius={8}>
          <Box>
            <Flex justifyContent={"space-between"}>
              <H2 p="4">Create New Form</H2>
              <Flex gap="12" alignItems={"center"} padding={2} marginLeft={2}>
                <StaticBreadcrumb>
                  {breadcrumbs.map(({ isActive, title, key }, index) => (
                    <Crumb
                      key={key}
                      isActive={isActive}
                      iconDivider={
                        index < breadcrumbs.length - 1 && <BreadcrumbDivider />
                      }
                    >
                      {title}
                    </Crumb>
                  ))}
                </StaticBreadcrumb>

                <IconButton
                  Type={IoMdClose}
                  size="23px"
                  zIndex={9}
                  onClick={() => navigate(ROUTE.SETTINGS_FORMS)}
                />
              </Flex>
            </Flex>
          </Box>
          <Box>{children}</Box>
        </Box>
      </Box>
    </>
  );
};
