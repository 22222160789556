import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { MandateType } from "../types/MandateType";
import { env } from "../utils/env";

const fetchMandateTypes = (authHeader: string, mandateId?: number) => () =>
  axios.get<MandateType[]>(`${env.verifyServiceUrl}individual/mandate_type/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useMandateTypes = () => {
  const { authHeader } = useAuth();
  return useQuery(["mandate_types"], fetchMandateTypes(authHeader), { retry: false });
};
