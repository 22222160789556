import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import axios from "axios";
import { DPOPeriod } from "../types/DPO";

export const useCreateDataRetentionDetail = () => {
  const { authHeader } = useAuth();

  return useMutation((params: DPOPeriod) => {
    return axios.post(`${env.verifyServiceUrl}individual/data_retention_setting/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
