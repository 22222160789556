import ReactDatePicker from "react-date-picker";
import { Box, BoxProps } from "../Box";
import { Label } from "../Label";
import { Paragraph } from "../Paragraph";
import "./styles.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

type DatePickerProps = BoxProps & {
  value: Date;
  onChange: (value: any) => void;
  name?: string;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  hasError?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  // className?: string;
};

export const DatePicker = (props: DatePickerProps) => {
  const {
    value,
    label,
    onChange,
    maxDate,
    minDate,
    errorMessage,
    hasError,
    name,
    isRequired,
    // className,
    ...styleProps
  } = props;

  return (
    <Box position="relative" {...styleProps}>
      {label && (
        <Label>
          {label}
          {isRequired ? " *" : ""}
        </Label>
      )}
      <ReactDatePicker
        onChange={onChange}
        value={value}
        showLeadingZeros
        locale="en-GB"
        className={
          hasError
            ? "react-date-picker-error react-date-picker"
            : "react-date-picker"
        }
        maxDate={maxDate}
        minDate={minDate}
        name={name}
      />

      {errorMessage && (
        <Paragraph color="red.primary" fontSize="1" mt="1" textAlign="right">
          {errorMessage}
        </Paragraph>
      )}
    </Box>
  );
};
