import { Form, Formik } from "formik";
import { Box } from "../../../components/Box";
import { Button } from "../../../components/Button";
import { Flex } from "../../../components/Flex";
import { H3 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import { Paragraph } from "../../../components/Paragraph";
import { TextArea } from "../../../components/TextArea";
import { validateForm } from "../../../utils/validationSchema";
import { useUpdateForm } from "../../../data/useUpdateForm";
import { showError } from "../../../utils/error-handling";
import { notify } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateForm } from "../../../data/useCreateForm";
import { queryClient } from "../../../queryClient";

interface FormDetailsProps {
  initialValues: any;
  editMode: boolean;
}

export const FormDetails: React.FC<FormDetailsProps> = ({ initialValues, editMode }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { mutate: updateForm } = useUpdateForm();
  const { mutate: createForm } = useCreateForm();

  const handleSubmit = (values: any) => {
    const onSuccess = (res: any, message: string, redirectUrl?: string) => {
      if (res?.status?.toString().startsWith("2")) {
        queryClient.invalidateQueries(["form"]);
        if (redirectUrl) navigate(redirectUrl);
        notify(message, { type: "success", position: "bottom-left" });
      }
    };

    const onError = (err: any, message: string) => showError(err, message);

    if (editMode || id) {
      updateForm(
        { params: values, id: Number(id) },
        {
          onError: (err) => onError(err, "A problem occurred while updating form details."),
          onSuccess: (res) => onSuccess(res, "Form details updated successfully!"),
        }
      );
    } else {
      createForm(values, {
        onError: (err) => onError(err, "A problem occurred while creating form."),
        onSuccess: (res) => onSuccess(res, "Form created successfully!", `/settings/forms/${res.data.id}/questions`),
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validateForm}
    >
      {({ handleChange, handleSubmit, values, errors, dirty, isValid }) => (
        <Form onSubmit={handleSubmit}>
          <Flex
            bg="white"
            width="100%"
            gap="12"
            justifyContent="space-between"
            flexWrap="wrap"
            borderRadius="16px"
            pb={2}
          >
            <Box pr={2} flex={4} minWidth={400}>
              <H3 fontSize="2">Form Details</H3>
              <Paragraph fontWeight={500} color="gray.60" fontSize={1}>
                This details will display on the mobile app.
              </Paragraph>

              <Box>
                <Box my={3}>
                  <Input
                    onChange={handleChange}
                    value={values.name}
                    placeholder="Form name"
                    label="Form Name"
                    name="name"
                    isRequired
                    hasError={Boolean(errors.name)}
                    errorMessage={errors.name as string}
                  />
                </Box>
                <Box my={3}>
                  <TextArea
                    onChange={handleChange}
                    value={values.description}
                    placeholder="Form description"
                    label="Description"
                    name="description"
                    rows={4}
                    isRequired
                    hasError={Boolean(errors.description)}
                    errorMessage={errors.description as string}
                  />
                </Box>

                <Box mt={4}>
                  <Flex justifyContent="end">
                    <Button
                      size="large"
                      mb="0"
                      type="submit"
                      isDisabled={!dirty || (dirty && !isValid)}
                    >
                      {editMode || id ? "Save" : "Create"}
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
