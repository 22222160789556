import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

export const useUpdateSplashScreen = () => {
  const { authHeader } = useAuth();

  return useMutation(({ id, params }: { id: number; params }) => {
    return axios.patch(
      `${env.verifyServiceUrl}individual/splash_screen/${id}/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
