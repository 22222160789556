export interface FeatureFlag {
  enabled: boolean;
}

export enum FeatureFlagKey {
  RESET_PASSWORD,
  BILLING_DETAILS,
  CAN_EDIT_PROFILE,
}

const featureFlags: { [K in FeatureFlagKey]: FeatureFlag } = {
  [FeatureFlagKey.RESET_PASSWORD]: {
    enabled: true,
  },
  [FeatureFlagKey.BILLING_DETAILS]: {
    enabled: false,
  },
  [FeatureFlagKey.CAN_EDIT_PROFILE]: {
    enabled: false,
  },
};

export const isEnabled = (key: FeatureFlagKey) => {
  return featureFlags[key]?.enabled;
};
