import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { MandateType } from "../types/MandateType";
import { env } from "../utils/env";

const fetch = (authHeader: string, typeId: number) => () =>
  axios.get<MandateType>(`${env.verifyServiceUrl}individual/mandate_type/${typeId}/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useMandateType = (typeId: number) => {
  const { authHeader } = useAuth();
  return useQuery(["mandate", typeId], fetch(authHeader, typeId), {
    enabled: Number.isInteger(typeId),
    retry: false,
  });
};
