import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";

export const useVerifyCode = () => {
  return useMutation(({ email, code }: { email: string; code: string }) => {
    return axios.post(`${env.verifyServiceUrl}user/confirm_verify_code/`, {
      email,
      verify_code: code,
    });
  });
};
