import {
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState, useEffect, useMemo } from "react";
import { Flex } from "../Flex";
import { Loader } from "../Loader";
import { useMandateStatuses } from "../../context/MandateStatusContext";
import "./table.css";
import { DateTime } from "luxon";
import { useTableMandates } from "../../data/useMandates";
import { Image } from "../Image";
import person from "../../assets/card-person-gray.svg";
import theme from "../../theme";
import { StyledLink } from "../Breadcrumbs";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import { Icon } from "../Icon";
import { Paragraph } from "../Paragraph";
import { StatusDiv } from "../../pages/ApplicationView";

export const ApplicationsTable = (props) => {
  const { searchTerm, filterUserIds, filters, isBannerVisible, isBannerLarge } =
    props;

  const { statuses, isLoadingStatuses } = useMandateStatuses();
  const [data, setData] = useState<any>({});
  const [filtersUpdate, setFiltersUpdate] = useState({ ...filters });
  const [searchTermUpdate, setSearchTermUpdate] = useState(props.searchTerm);
  const [filterUserIdsUpdate, setFilterUserIdsUpdate] = useState(
    props.filterUserIds
  );

  const cols = [
    {
      header: () => "Name",
      accessorKey: "name",
      cell: (info) => {
        return (
          <span
            style={{ fontWeight: "bold", fontSize: "18px", color: "#09093E" }}
          >
            {info.getValue()}
          </span>
        );
      },
    },
    {
      header: () => "Type",
      accessorKey: "mandate_type__name",
      cell: ({ row }) => {
        return (
          <span className="typeColumn">{row.original.mandate_type.name}</span>
        );
      },
    },
    {
      header: () => "Check status",
      accessorKey: "status",
      cell: (info) => {
        return (
          <StatusDiv status={getStatus(info.getValue())}>
            {getStatus(info.getValue())}
          </StatusDiv>
        );
      },
    },
    {
      header: () => "Individuals",
      accessorKey: "individual_count",
      cell: (info) => {
        return (
          <Flex justifyContent="start" alignItems="center" position="relative">
            <Image
              src={person}
              alt="indicator icon"
              height="20px"
              width="24px"
              marginRight={"12px"}
            />
            {info.getValue()}
          </Flex>
        );
      },
    },
    {
      header: () => "ID",
      accessorKey: "reference",
      cell: (info) => {
        return (
          <Flex
            justifyContent="start"
            alignItems="center"
            position="relative"
            color={theme.colors.gray[40]}
          >
            {info.getValue()}
          </Flex>
        );
      },
    },
    {
      header: () => "Date started",
      accessorKey: "created_on",
      cell: (info) => {
        return (
          <Flex color={theme.colors.gray[40]}>
            {DateTime.fromISO(info.getValue()).toFormat("dd/MM/yyyy")}
          </Flex>
        );
      },
    },
    {
      header: () => "",
      accessorKey: "assigned_user",
      cell: (info) => {
        return (
          info.getValue() &&
          (info.getValue()?.first_name || info.getValue()?.last_name) && (
            <Flex className="userCell">
              {info.getValue()?.first_name?.charAt(0)?.toUpperCase()}
              {info.getValue()?.last_name?.charAt(0)?.toUpperCase()}
            </Flex>
          )
        );
      },
    },
    {
      header: () => "",
      id: "view_id",
      accessorKey: "id",
      cell: (info) => {
        return (
          <StyledLink to={`${info.getValue()}`}>
            <span
              style={{
                color: theme.colors.blue.primary,
              }}
            >
              View
            </span>
          </StyledLink>
        );
      },
    },
  ];

  const [columns] = useState([...cols]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([
    { id: "created_on", desc: true },
  ]);

  const getMandatesData = useTableMandates(
    filterUserIdsUpdate,
    searchTermUpdate,
    filtersUpdate,
    pageIndex + 1,
    pageSize,
    sorting[0]?.desc ? "dsc" : "asc",
    sorting[0]?.id
  );

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    // if (filters) {
    setFiltersUpdate(filters);
    // }
    // if (searchTerm) {
    setSearchTermUpdate(searchTerm);
    // }
    // if (filterUserIds) {
    setFilterUserIdsUpdate(filterUserIds);
    // }
  }, [searchTerm, searchTermUpdate, filterUserIds, filters]);

  useEffect(() => {
    setData(() => getMandatesData.data?.data);
  }, [getMandatesData]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data?.results ?? [],
    columns,
    state: {
      pagination,
      sorting,
    },
    pageCount: Math.ceil(data?.count / pageSize) ?? -1,
    debugTable: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const getStatus = (statusId: number) => {
    if (statusId) {
      return statuses?.data?.find((s) => s.id === statusId).status;
    }
  };

  return (
    <div>
      {(getMandatesData.isFetching || isLoadingStatuses) && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}
      {!getMandatesData.isFetching &&
        !isLoadingStatuses &&
        data?.results?.length === 0 && (
          <Paragraph m={3} color="gray.40" textAlign="center">
            No Applications
          </Paragraph>
        )}

      {!getMandatesData.isFetching && data?.results?.length > 0 && (
        <div
          className="tableContainer"
          style={{
            height: isBannerVisible
              ? isBannerLarge
                ? searchTerm
                  ? "calc(100vh - (305px + 330px))"
                  : "calc(100vh - (250px + 330px))"
                : searchTerm
                ? "calc(100vh - (305px + 150px))"
                : "calc(100vh - (250px + 150px))"
              : searchTerm
              ? "calc(100vh - 305px)"
              : "calc(100vh - 250px)",
          }}
        >
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "select-none cursor-pointer flex items-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: (
                                <Icon
                                  display="inline-block"
                                  ml={1}
                                  size="20px"
                                  color="gray.40"
                                  Type={FaSortDown}
                                ></Icon>
                              ),
                              desc: (
                                <Icon
                                  display="inline-block"
                                  ml={1}
                                  size="20px"
                                  color="gray.40"
                                  Type={FaSortUp}
                                ></Icon>
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Flex
        className="pagination"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <span>
            Rows per page &nbsp;&nbsp;
            <select
              className="pageSizeDropdown"
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
            >
              {[5, 10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </span>
        </Flex>

        <Flex>
          <span style={{ display: "flex", alignItems: "center" }}>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </span>
          &nbsp;&nbsp;&nbsp;
          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.setPageIndex(0)}
          >
            &lt;&lt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
          >
            &lt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            &gt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          >
            &gt;&gt;
          </button>
        </Flex>
      </Flex>
    </div>
  );
};
