import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { axios } from "./axios";

interface Params {
  id: number;
  deleted: boolean;
}

export const useDeleteMandateType = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.patch<Params>(
      `${env.verifyServiceUrl}individual/mandate_type/${params.id}/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
