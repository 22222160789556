import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

const fetch = (authHeader: string) => () =>
  axios.get(`${env.verifyServiceUrl}individual/mandate/status_report/`, {
    headers: {
      Authorization: authHeader,
    },
  });
  
export const useStatusReport = () => {
  const { authHeader, isLoggedIn } = useAuth();
  return useQuery(["statusReport"], fetch(authHeader), {
    enabled: isLoggedIn,
    retry: false,
  });
};
