import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
} from "styled-system";
import { BoxProps } from "../Box";

export const Flex = styled.div<BoxProps>`
  display: flex;
  ${({ gap }) => (gap ? `gap: ${gap}px;` : "")}

  ${space}
    ${color}
    ${space}
    ${typography}
    ${layout}
    ${flexbox}
    ${background}
    ${border}
    ${position}
`;
