import { Form, Formik } from "formik";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import { TextArea } from "../../components/TextArea";
import { useCreateMandateType } from "../../data/useCreateMandateType";
import { notify } from "../../utils/notify";
import { settingsApplicationTypesValidation } from "../../utils/validationSchema";
import { Image } from "../../components/Image";
import { Toggle } from "../../components/Toggle";
import Email from "../../assets/Mail-Email-Letter.svg";
import { ApplicationTypeNewFrame } from "./ApplicationTypeNewFrame";
import { Icon } from "../../components/Icon";
import { Tooltip } from "../../components/Tooltip";

const SettingsApplicationTypeNew = () => {
  const { mutate: createMandateType } = useCreateMandateType();

  const navigate = useNavigate();

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      checkpoint: [],
      is_disabled: true,
    };

    createMandateType(payload, {
      onSuccess: (res) => {
        if (res?.status?.toString().startsWith("2")) {
          notify("Application Type created successfully!", {
            type: "success",
          });
        }
        navigate(`/settings/application-types/${res.data.id}/checks`);
      },
    });
  };

  return (
    <>
      <ApplicationTypeNewFrame step="details">
        <Formik
          initialValues={{
            name: "",
            description: "",
            action_reminder_email: false,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validate={() => null}
          validationSchema={settingsApplicationTypesValidation}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Flex
                bg="white"
                width="100%"
                gap="12"
                justifyContent="space-between"
                flexWrap="wrap"
                borderRadius="16px"
                pb={4}
              >
                <Box pl={4} pr={2} pt={4} flex={4} minWidth={400}>
                  <H2 fontSize="3">Create New Application Type</H2>
                  <Paragraph fontWeight={500}>Application Details</Paragraph>

                  <Box>
                    <Box my={3}>
                      <Input
                        onChange={props.handleChange}
                        value={props.values.name}
                        placeholder="Enter an application type name"
                        label="Application Type Name"
                        name="name"
                        isRequired
                        hasError={Boolean(props.errors.name)}
                        errorMessage={props.errors.name}
                      />
                    </Box>
                    <Box my={3}>
                      <TextArea
                        onChange={props.handleChange}
                        value={props.values.description}
                        placeholder="Enter a description"
                        label="Description"
                        name="description"
                        rows={4}
                        isRequired
                        hasError={Boolean(props.errors.description)}
                        errorMessage={props.errors.description}
                      />
                    </Box>

                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Flex gap="12">
                        <Image
                          src={Email}
                          alt="actions icon"
                          height="20px"
                          width="24px"
                        />
                        <Flex alignItems={"center"} gap="8">
                          <Paragraph m={"0px"} fontWeight={600}>
                            Auto-email reminders
                          </Paragraph>
                          <Tooltip content="The customer will be emailed a reminder to complete the process each day before their invite expires.">
                            <Icon
                              Type={IoMdInformationCircleOutline}
                              aria-hidden="true"
                              height={15}
                            />
                          </Tooltip>
                        </Flex>
                      </Flex>

                      <Toggle
                        mt={1}
                        isChecked={props.values.action_reminder_email}
                        onChange={(e: any) => {
                          props.setFieldValue(
                            "action_reminder_email",
                            e.target.checked
                          );
                        }}
                      />
                    </Flex>

                    <Box mt={4}>
                      <Flex justifyContent={"end"}>
                        <Button
                          size="large"
                          mb="0"
                          type="submit"
                          isDisabled={
                            !props.dirty || (props.dirty && !props.isValid)
                          }
                        >
                          Continue
                        </Button>
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </ApplicationTypeNewFrame>
    </>
  );
};

export default SettingsApplicationTypeNew;
