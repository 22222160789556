import { DateTime } from "luxon";
import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { Paragraph } from "../../components/Paragraph";
import { Image } from "../../components/Image";
import { CheckStatusEnum } from "../../context/MandateStatusContext";
import accept from "../../assets/accept.svg";
import reject from "../../assets/reject.svg";
import theme from "../../theme";
import { ApprovalCommentType } from "../../types/Individual";

type Props = {
  data: ApprovalCommentType;
  status: string;
};

export const ApprovalComment = (props: Props) => {
  const { data, status } = props;

  return (
    <Flex gap="12" p={4} alignItems={"flex-start"}>
      <Image
        src={status === CheckStatusEnum.ManuallyAccepted ? accept : reject}
        alt="icon"
        height={32}
        width={32}
      />

      <Box flex={1}>
        <Flex justifyContent={"space-between"}>
          <Paragraph m={"0px"} fontWeight={"bold"} fontSize={1}>
            {data?.first_name} {data?.last_name}
          </Paragraph>
          <Paragraph m={"0px"} color={theme.colors.gray[50]} fontSize={0}>
            {DateTime.fromISO(data?.date).toFormat("dd LLL, yyyy")}
          </Paragraph>
        </Flex>
        <Paragraph my={1}>{data?.comment}</Paragraph>
      </Box>
    </Flex>
  );
};
