import { Box } from "../Box";
import { Flex } from "../Flex";
import { H2 } from "../Heading";

const Topbar = (props: any) => {
  const { title, header, children, noBorder } = props;

  return (
    <Flex
      borderBottom={noBorder ? null : "1px solid"}
      borderBottomColor="gray.30"
      justifyContent="space-between"
      alignItems="center"
      p="3"
    >
      {title && <H2 m="0px">{title}</H2>}
      {header && header}
      <Box flex={1}>{children}</Box>
    </Flex>
  );
};

export default Topbar;
