import { createContext, useContext } from "react";
import { useMandateStatuses as useMandateStatusesQuery } from "../data/useMandateStatuses";
import low from "../assets/low.svg";
import medium from "../assets/medium.svg";
import high from "../assets/high.svg";

export enum MandateStatus {
  New = "new",
  InProgress = "in progress",
  InReview = "in review",
  Completed = "completed",
  Rejected = "rejected",
}

export enum IndividualStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
}

export enum IndividualCheckStatus {
  Passed = "Passed",
  Review = "Review",
}

export enum ChecksStatusEnum {
  Pending = "pending",
  Completed = "completed",
  Review = "review",
}

export enum CheckStatusEnum {
  Review = "review",
  Pending = "pending",
  Accepted = "accepted",
  ManuallyAccepted = "manually_accepted",
  ManuallyRejected = "manually_rejected",
}

export enum RiskLevelEnum {
  low = "low",
  medium = "medium",
  high = "high",
}

export const RiskLevelIcons: { [key in RiskLevelEnum]: string } = {
  [RiskLevelEnum.low]: low,
  [RiskLevelEnum.medium]: medium,
  [RiskLevelEnum.high]: high,
};

export const RiskLevelColors: { [key in RiskLevelEnum]: string } = {
  [RiskLevelEnum.low]: "green.primary",
  [RiskLevelEnum.medium]: "orange.100",
  [RiskLevelEnum.high]: "red.primary",
};

interface Values {
  statuses: any;
  isLoadingStatuses: boolean;
  getMandateStatusId: any;
}

export const MandateStatusContext = createContext<Values | null>(null);

export const MandateStatusProvider = (props: any) => {
  const { children } = props;

  // call for statuses
  const { data: statuses, isLoading: isLoadingStatuses } =
    useMandateStatusesQuery();

  // status name and output statusid
  const getMandateStatusId = (statusName: MandateStatus) => {
    const status = statuses?.data?.find(
      (status) => status.status === statusName
    );
    return status?.id;
  };

  const value = {
    statuses,
    isLoadingStatuses,
    getMandateStatusId,
  };

  return (
    <MandateStatusContext.Provider value={value}>
      {children}
    </MandateStatusContext.Provider>
  );
};

export const useMandateStatuses = () => {
  return useContext(MandateStatusContext) as Values;
};
