import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { DatePicker } from "../../components/DatePicker";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import Select, { Option } from "../../components/Select";
import { addChecksIndividualValidation } from "../../utils/validationSchema";
import { useCreateIndividual } from "../../data/useCreateIndividual";
import { showError } from "../../utils/error-handling";
import { MIN_AGE_APPLICATION } from "../../utils/constants";
import { useCommonTitles } from "../../data/useCommonTitles";
import { useState } from "react";
import { NewChecksFrame } from "./NewChecksFrame";
import { Modal } from "../../components/Modal";
import { useCreateMandateForChecks } from "../../data/useCreateMandateForChecks";
import { useCreateMandateTypeFromCheckpointIds } from "../../data/useCreateMandateTypeFromCheckpointIds";

const rowProps: any = {
  flexWrap: "wrap",
  gap: "16",
  my: "4",
};

const inputProps = {
  minWidth: 200,
  flex: 1,
};

const genderOptions = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
  { value: "other", label: "Other" },
];

const Header = () => {
  return (
    <>
      <H2>Add Individual</H2>
      <Paragraph>Please add the individual to perform the checks on.</Paragraph>
    </>
  );
};

export const AddCheckIndividual = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const checkpointIds = location.state?.checkpointIds;
  const [isNoDOBModalOpen, setIsNoDOBModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  const { mutate: createIndividual, isLoading } = useCreateIndividual();

  const { mutate: createMandateType } = useCreateMandateTypeFromCheckpointIds();
  const { mutate: createMandate } = useCreateMandateForChecks();

  const { data } = useCommonTitles();

  const titleOptions = data?.data.map(({ name }) => ({
    label: name,
    value: name,
  })) as Option[];

  const handleCreateIndividual = (form: any) => {
    setFormData(form);
    if (form.dateOfBirth) {
      handleSubmit(form);
    } else {
      setIsNoDOBModalOpen(true);
    }
  };

  const handleNext = () => {
    handleSubmit(formData);
  };

  const handleSubmit = (form) => {
    setDoubleClick(true);
    createMandateType(
      { checkpoint: checkpointIds },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            createMandate(
              { mandate_type: res?.data?.id, category: "Checks" },
              {
                onSuccess: ({ data: mandate }) => {
                  const dob = form.dateOfBirth
                    ? DateTime.fromJSDate(form.dateOfBirth as Date).toFormat(
                        "yyyy-MM-dd"
                      )
                    : null;

                  createIndividual(
                    {
                      gender: form.gender,
                      title: form.title,
                      first_name: form.firstName,
                      date_of_birth: dob,
                      last_name: form.lastName,
                      middle_name: form.middleName,
                      mandate: mandate.id,
                      email: form.email,
                      is_check_individual: true,
                    },
                    {
                      onSuccess: ({ data: individual }) => {
                        navigate(
                          `/checks/${mandate.id}/address/${individual.id}`
                        );
                      },
                      onError: (err: any) => {
                        setDoubleClick(false);
                        showError(
                          err,
                          "A problem occurred while creating the individual."
                        );
                      },
                    }
                  );
                },
                onError: (err) => {
                  setDoubleClick(false);
                  showError(
                    err,
                    "A problem occurred while creating the check."
                  );
                },
              }
            );
          }
        },
        onError: (err) => {
          setDoubleClick(false);
          showError(err, "A problem occurred while creating the check.");
        },
      }
    );
  };

  return (
    <NewChecksFrame step="individuals">
      <Formik
        initialValues={{
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
          dateOfBirth: "",
          gender: "",
        }}
        validationSchema={addChecksIndividualValidation}
        onSubmit={handleCreateIndividual}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit} style={{ width: "510px" }}>
            <Header />
            <Flex {...rowProps}>
              <div style={{ width: "48%" }}>
                <Select
                  flex={1}
                  onChange={(option: any) =>
                    props.setFieldValue("title", option.value)
                  }
                  value={titleOptions?.find(
                    ({ value }) => value === props.values.title
                  )}
                  options={titleOptions}
                  placeholder="Select title"
                  mt="2"
                  label="Title"
                  name="title"
                />
              </div>
              <div style={{ width: "48%" }}>
                <Select
                  flex={1}
                  onChange={(option: any) =>
                    props.setFieldValue("gender", option.value)
                  }
                  value={genderOptions?.find(
                    ({ value }) => value === props.values.gender
                  )}
                  options={genderOptions}
                  placeholder="Select gender"
                  mt="2"
                  label="Gender"
                  name="gender"
                />
              </div>
            </Flex>
            <Flex {...rowProps}>
              <Input
                {...inputProps}
                onChange={props.handleChange}
                value={props.values.firstName}
                placeholder="Enter first name"
                mt="2"
                label="First name"
                name="firstName"
                hasError={Boolean(props.errors.firstName)}
                errorMessage={props.errors.firstName as string}
                isRequired
              />
              <Input
                {...inputProps}
                onChange={props.handleChange}
                value={props.values.middleName}
                placeholder="Enter middle(s) name"
                mt="2"
                label="Middle name(s)"
                name="middleName"
                hasError={Boolean(props.errors.middleName)}
                errorMessage={props.errors.middleName as string}
              />
            </Flex>
            <Flex {...rowProps}>
              <Input
                {...inputProps}
                onChange={props.handleChange}
                value={props.values.lastName}
                placeholder="Enter last name"
                mt="2"
                label="Last name"
                name="lastName"
                isRequired
                hasError={Boolean(props.errors.lastName)}
                errorMessage={props.errors.lastName as string}
              />
              <DatePicker
                value={props.values.dateOfBirth as any}
                onChange={(date: any) => {
                  props.setFieldValue("dateOfBirth", date);
                }}
                mt="2"
                maxDate={DateTime.now()
                  .minus({ years: MIN_AGE_APPLICATION })
                  .toJSDate()}
                label="Date of birth"
                name="dateOfBirth"
                hasError={Boolean(props.errors.dateOfBirth)}
                errorMessage={props.errors.dateOfBirth as string}
                {...inputProps}
              />
            </Flex>

            <Flex justifyContent="flex-end">
              {/* <Button
                size="large"
                mt="2"
                mb="0"
                variant="secondary"
                onClick={() =>
                  navigate(`/applications/${mandateId}/individuals`)
                }
              >
                Back
              </Button> */}
              <Button
                size="large"
                mt="2"
                mb="0"
                type="submit"
                isDisabled={
                  (props.dirty && !props.isValid) || isLoading || doubleClick
                }
              >
                Next
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>

      <Modal
        isOpen={isNoDOBModalOpen}
        onClose={() => setIsNoDOBModalOpen(false)}
        title="No date of birth"
        content="Although date of birth is not required, it improves the accuracy of the results. We recommend providing a date of birth before proceeding."
        onModalConfirmClick={handleNext}
        secondaryButtonText="Cancel"
        primaryButtonText="Continue anyway"
        primaryButtonVariant="danger"
        hasSecondaryButton
        width="450px"
      />
    </NewChecksFrame>
  );
};
