import { Box } from "../../components/Box";
import { useAuth } from "../../context/AuthContext";
import { Formik, Form } from "formik";
import { H1 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Navigate } from "react-router-dom";
import { ROUTE } from "../../routes";
import { DPO_validation } from "../../utils/validationSchema";

export const DPOStep = ({ onSubmit, doubleClickMlro, doubleClick }: any) => {
  const { isLoggedIn } = useAuth();

  /** User is not ready for this step of the flow. */
  if (!isLoggedIn) {
    return <Navigate to={ROUTE.SIGN_UP} replace />;
  }

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone_number: "",
        }}
        validationSchema={DPO_validation}
        onSubmit={(values) => {
          doubleClick(true);
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <H1 color="darkBlue">Data Protection Officer</H1>
            <Paragraph color="darkBlue">
              Please provide the details of your data protection officer (DPO)
            </Paragraph>

            <Input
              onChange={props.handleChange}
              value={props.values.name}
              placeholder="Enter your name"
              mt="4"
              label="Name"
              name="name"
              isRequired
              hasError={Boolean(props.errors.name)}
              errorMessage={props.errors.name}
            />

            <Input
              onChange={props.handleChange}
              value={props.values.email}
              placeholder="Enter your email address"
              mt="4"
              label="Email address"
              name="email"
              isRequired
              hasError={Boolean(props.errors.email)}
              errorMessage={props.errors.email}
            />

            <Input
              onChange={props.handleChange}
              value={props.values.phone_number}
              placeholder="Enter your contact number"
              mt="4"
              label="Telephone number"
              name="phone_number"
              isRequired
              hasError={Boolean(props.errors.phone_number)}
              errorMessage={props.errors.phone_number}
            />

            <Box>
              <Button
                size="large"
                width="100%"
                mt="2"
                mb="0"
                type="submit"
                isDisabled={!props.isValid || !props.dirty || doubleClickMlro}
              >
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
