import GoogleMapReact from "google-map-react";
import { Icon } from "../Icon";
import { FaMapMarkerAlt } from "react-icons/fa";
import styled from "styled-components";
import { env } from "../../utils/env";

const Container = styled.div<any>`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
`;

const LocationPin = (props) => (
  <div className="pin">
    <Icon
      Type={FaMapMarkerAlt}
      color="red.primary"
      hoverColor="red"
      size="26px"
    />
  </div>
);

export const Geolocation = (props: { lat: number; long: number }) => {
  const location = {
    lat: Number(props.lat),
    lng: Number(props.long),
  };

  return (
    <Container>
      <GoogleMapReact
        {...props}
        bootstrapURLKeys={{
          key: env.verifyGoogleMapKey,
        }}
        defaultCenter={location}
        center={location}
        defaultZoom={12}
      >
        <LocationPin lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </Container>
  );
};
