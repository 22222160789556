import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { MandateApplicationView } from "../types/Mandate";
import { env } from "../utils/env";

const fetch = (authHeader: string, mandateId: number) => () =>
  axios.get<MandateApplicationView>(
    `${env.verifyServiceUrl}individual/mandate/${mandateId}/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useMandate = (mandateId: number) => {
  const { authHeader } = useAuth();
  return useQuery(["mandate", mandateId], fetch(authHeader, mandateId), { enabled: Number.isInteger(mandateId), retry: false});
};
