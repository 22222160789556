import { Formik, Form } from "formik";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { ImageUpload } from "../../components/ImageUpload";
import { Input, InputProps } from "../../components/Input";
import { Loader } from "../../components/Loader";
import Select, { Option } from "../../components/Select";
import { useAuth } from "../../context/AuthContext";
import { useClientDetails } from "../../data/useClientDetails";
import { useIndustries } from "../../data/useIndustries";
import { useUpdateClient } from "../../data/useUpdateClient";
import { useUpdateClientLogo } from "../../data/useUpdateClientLogo";
import { showError } from "../../utils/error-handling";
import { useAddressForm } from "../../utils/forms/useAddressForm";
import { notify } from "../../utils/notify";
import { queryClient } from "../../queryClient";
import {
  companyAddressValidation,
  settingsCompanyDetailsValidation,
} from "../../utils/validationSchema";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCompanyTypes } from "../../data/useCompanyTypes";
import { CompanyInformation } from "../../types/CompanyInformation";
import { Address } from "../../types/Address";
import { useDirtyForm } from "./DirtyFormContext";

const SettingsCompanyDetails = () => {
  const { authHeader } = useAuth();
  const { userDetails } = useAuth();
  const [doubleClick, setDoubleClick] = useState(false);
  const [isRequestingImage, setIsRequestingImage] = useState(false);
  const { data: industries } = useIndustries();
  const { data: companyTypes } = useCompanyTypes();
  const { data: client, isLoading } = useClientDetails(userDetails?.tenant);
  const { mutate: updateClient, isLoading: isUpdatingClient } =
    useUpdateClient();
  const { mutate: updateClientLogo } = useUpdateClientLogo();
  const { AddressForm, getInitialValues: getAddressInitialValues } =
    useAddressForm(true);
  const [img, setImg] = useState("");
  const { setFormDirty, formRef } = useDirtyForm();

  useEffect(() => {
    if (!isLoading && client?.data?.company_logo) {
      fetchImage();
    }
  }, [client?.data?.company_logo]);

  const fetchImage = () => {
    if (isRequestingImage) {
      return;
    }

    setIsRequestingImage(true);
    axios
      .get(client?.data?.company_logo, {
        headers: {
          Authorization: authHeader,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        const imageObjectURL = window.URL.createObjectURL(blob);
        setImg(imageObjectURL);
      })
      .finally(() => {
        setIsRequestingImage(false);
      });
  };

  const industryOptions = industries?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  })) as Option[];

  const handleAddressSubmit = (data: Address) => {
    setDoubleClick(true);
    setFormDirty(false); // Reset the form dirty state before submitting
    updateClient(
      {
        id: userDetails?.tenant,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        country: data.country,
        postcode: data.postcode,
      },
      {
        onSuccess: (res) => {
          setDoubleClick(false);
          if (res?.status?.toString().startsWith("2")) {
            notify("Company address updated", {
              type: "success",
              position: "bottom-left",
            });
            queryClient.invalidateQueries([
              "clientDetails",
              userDetails?.tenant,
            ]);
          }
        },
        onError: (err: any) => {
          setDoubleClick(false);

          showError(
            err,
            "A problem occurred while updating the company address."
          );
        },
      }
    );
  };
  const handleSubmit = (data: CompanyInformation) => {
    setDoubleClick(true);
    setFormDirty(false); // Reset the form dirty state before submitting
    updateClient(
      {
        id: userDetails?.tenant,
        industry: data.companyIndustry,
        name: data.companyName,
        display_name: data.companyDisplayname,
        registration_number: data.companyReg,
        website: data.companyWebsite,
        company_type: data.companyType,
        support_phone_number: data.supportPhoneNumber,
        support_email_address: data.supportEmailAddress,
      },
      {
        onSuccess: (res) => {
          setDoubleClick(false);
          if (res?.status?.toString().startsWith("2")) {
            notify("Company details updated", {
              type: "success",
            });
            queryClient.invalidateQueries([
              "clientDetails",
              userDetails?.tenant,
            ]);
          }
        },
        onError: (err: any) => {
          setDoubleClick(false);
          showError(
            err,
            "A problem occurred while updating the company details."
          );
        },
      }
    );
  };

  const handleLogoChange = (file: File) => {
    updateClientLogo(
      {
        id: userDetails?.tenant,
        file,
      },
      {
        onError: (err: any) => {
          showError(err, "A problem occurred while updating the company logo.");

          // displayError(
          //   collapseErrors(extractErrors(err)) ||
          //     "A problem occurred while updating the company logo."
          // );
          // logError(err);
        },
      }
    );
  };

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  const rowProps: any = {
    flexWrap: "wrap",
    gap: "16",
    my: "4",
  };

  const companyTypeOptions = companyTypes?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  })) as Option[];

  const handleFormChange = (e, handleChange) => {
    setFormDirty(true);
    handleChange(e);
  };

  return (
    <>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight={328}>
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <H2 mb={4}>Company logo</H2>

            {isRequestingImage ? (
              <>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  minHeight={100}
                >
                  <Loader />
                </Flex>
              </>
            ) : (
              <>
                <ImageUpload logoUrl={img} onChange={handleLogoChange} />
              </>
            )}
          </>
        )}
      </Box>

      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <H2>Company information</H2>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                companyName: client?.data?.name || "",
                companyDisplayname: client?.data?.display_name || "",
                companyReg: client?.data?.registration_number || "",
                companyIndustry:
                  typeof client?.data?.industry === "object"
                    ? client?.data?.industry?.id
                    : client?.data?.industry || null,
                companyType: client?.data?.company_type || "",
                companyWebsite: client?.data?.website || "",
                supportPhoneNumber: client?.data?.support_phone_number || "",
                supportEmailAddress: client?.data?.support_email_address || "",
              }}
              enableReinitialize
              validate={() => null}
              validationSchema={settingsCompanyDetailsValidation}
              onSubmit={(values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false); // Make sure to set submitting to false
              }}
              innerRef={formRef} // Assign formRef to the Formik form
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Box>
                    <Flex {...rowProps}>
                      <Input
                        onChange={props.handleChange}
                        value={props.values.companyName}
                        placeholder="Enter company name"
                        label="Company name"
                        name="companyName"
                        {...inputProps}
                        isRequired
                        hasError={Boolean(props.errors.companyName)}
                        errorMessage={props.errors.companyName}
                        isDisabled={true}
                      />

                      <Select
                        value={companyTypeOptions?.find(({ value }) => {
                          return value == props.values.companyType;
                        })}
                        options={companyTypeOptions}
                        onChange={(option: Option) => {
                          props.setFieldValue("companyType", option.value);
                          setFormDirty(true);
                        }}
                        minWidth={200}
                        flex={1}
                        label="Company type"
                        name="companyType"
                        isRequired
                        hasError={Boolean(props.errors.companyType)}
                        errorMessage={props.errors.companyType}
                      />
                    </Flex>
                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) =>
                          handleFormChange(e, props.handleChange)
                        }
                        value={props.values.companyWebsite}
                        placeholder="Enter your company's website"
                        label="Company website"
                        name="companyWebsite"
                        {...inputProps}
                        isRequired
                        hasError={Boolean(props.errors.companyWebsite)}
                        errorMessage={props.errors.companyWebsite}
                      />
                      <Select
                        value={industryOptions?.find(
                          ({ value }) => value === props.values.companyIndustry
                        )}
                        options={industryOptions}
                        onChange={(option: Option) => {
                          props.setFieldValue("companyIndustry", option.value);
                          setFormDirty(true);
                        }}
                        label="Industry"
                        name="companyIndustry"
                        minWidth={200}
                        flex={1}
                        isRequired
                        hasError={Boolean(props.errors.companyIndustry)}
                        errorMessage={props.errors.companyIndustry}
                      />
                    </Flex>

                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) =>
                          handleFormChange(e, props.handleChange)
                        }
                        value={props.values.supportEmailAddress}
                        placeholder="Enter company's support email address"
                        label="Support email address"
                        name="supportEmailAddress"
                        {...inputProps}
                        hasError={Boolean(props.errors.supportEmailAddress)}
                        errorMessage={props.errors.supportEmailAddress}
                      />
                      <Input
                        onChange={(e) =>
                          handleFormChange(e, props.handleChange)
                        }
                        value={props.values.supportPhoneNumber}
                        placeholder="Enter company's support phone number"
                        label="Support phone number"
                        name="supportPhoneNumber"
                        {...inputProps}
                        hasError={Boolean(props.errors.supportPhoneNumber)}
                        errorMessage={props.errors.supportPhoneNumber}
                      />
                    </Flex>

                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) =>
                          handleFormChange(e, props.handleChange)
                        }
                        value={props.values.companyReg}
                        placeholder="Enter your company registration number"
                        label="Company registration number"
                        name="companyReg"
                        {...inputProps}
                        hasError={Boolean(props.errors.companyReg)}
                        errorMessage={props.errors.companyReg}
                      />

                      <Input
                        onChange={(e) =>
                          handleFormChange(e, props.handleChange)
                        }
                        value={props.values.companyDisplayname}
                        placeholder="Enter company display name"
                        label="Company display name"
                        name="companyDisplayname"
                        {...inputProps}
                        hasError={Boolean(props.errors.companyDisplayname)}
                        errorMessage={props.errors.companyDisplayname}
                      />
                    </Flex>
                  </Box>

                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          isUpdatingClient ||
                          doubleClick
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>

      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <H2>Address</H2>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                ...getAddressInitialValues({
                  address_line_1: client.data.address_line_1,
                  address_line_2: client.data.address_line_2,
                  city: client.data.city,
                  country: client.data.country,
                  postcode: client.data.postcode,
                }),
              }}
              enableReinitialize
              validate={() => null}
              validationSchema={companyAddressValidation}
              onSubmit={(values, actions) => {
                handleAddressSubmit(values);
                actions.setSubmitting(false); // Make sure to set submitting to false
              }}
              innerRef={formRef}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <AddressForm {...props} />
                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          isUpdatingClient ||
                          doubleClick
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </>

    // </Flex>
  );
};

export default SettingsCompanyDetails;
