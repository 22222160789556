import { collapseErrors, extractErrors } from "./api";
import { notify } from "./notify";

/** Replace with an actual error logged in the future if needed, e.g. Sentry */
export const logError = (err: string) => {
  console.error(err);
};

/** A generic function to inform the user that there has been an error. */
export const displayError = (err: string) => {
  notify(err, {
    position: "bottom-left",
    type: "error",
  });
};

/** Wrapper function for the above functions. To be used with react-query. */
export const handleQueryError =
  (defaultError: string, includeErrorKeys = true) =>
    (err: any) => {
      displayError(
        collapseErrors(extractErrors(err), includeErrorKeys) || defaultError
      );
      logError(err);
    };

export const showError = (error: any, defaultError: string) => {
  const errorResponseData = error.response.data;

  // const res = Object.keys(errorResponseData).map(
  //   (k) => errorResponseData[k]
  // )[0];

  const exception = errorResponseData?.exception;

  const handleArrayException = () => {
    if (typeof exception[0] === "string") {
      return exception[0];
    }
  };

  const handleObjectException = () => {
    let strss = [];
    for (let value of Object.values(errorResponseData?.exception)) {
      if (typeof value === "string" || value instanceof String) {
        strss.push(value);
      }
      else if (typeof value[0] === "string" || value[0] instanceof String) {
        strss.push(value[0]);
      } 
      else {
        for (let obj of Object.values(value[0])) {
          strss.push(obj[0]);
        }
      }
    }
    return strss[0];
  };

  const errorString =
    exception && (typeof exception === "string" || exception instanceof String)
      ? exception
      : Array.isArray(exception)
        ? handleArrayException()
        : typeof exception === "object"
          ? handleObjectException()
          : defaultError;

  // const errorStr = error.response.data.error
  //   ? error.response.data.error
  //   : typeof res === "string" || res instanceof String
  //   ? res
  //   : typeof res[0] === "string" || res[0] instanceof String
  //   ? res[0]
  //   : defaultError;

  displayError(errorString);
  logError(error);
};
