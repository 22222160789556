import { Box } from "../Box";
import "./tooltip.css";

export const Tooltip = ({ children, content, position = "top" }) => {
  return (
    <Box className={`tooltip ${position}`}>
      {children}
      <span className="tooltip-text">{content}</span>
    </Box>
  );
};
