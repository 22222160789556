import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  name: string;
  address: string; // TODO will probably be split into separate fields
  registration_number: string;
  industry: number;
  website: string;
}

export const useCreateClient = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(`${env.verifyServiceUrl}common/tenant/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
