import { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { ApplicationsList } from "../components/ApplicationsList";
import { Button } from "../components/Button";
import { Flex } from "../components/Flex";
import { Input } from "../components/Input";
import Topbar from "../components/Topbar";
import { FaPlus } from "react-icons/fa";
import { MdTune } from "react-icons/md";
import { Filters } from "../components/Filters";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";
import { useMandateStatuses } from "../context/MandateStatusContext";
import { capitalise } from "../utils/string";
import { Loader } from "../components/Loader";
import { UserFilter } from "../components/UserFilter";
import { useUsers } from "../data/useUsers";
import { env } from "../utils/env";
import { Box } from "../components/Box";
import { H3 } from "../components/Heading";
import { ApplicationsTable } from "../components/ApplicationsTable";
import { Link } from "../components/Link";
import kanban from "../assets/kanban-view.svg";
import grid from "../assets/grid-view.svg";
import { Image } from "../components/Image";
import { useBanner } from "../data/useBanner";
import BannerComponent from "../components/Banner";

export const Columns = styled.div<any>`
  ${({ theme: { space }, submittedSearchTerm }) => `
    display: flex;
    gap: ${space[2]};
    padding: ${space[4]};
    height: calc(
      100vh - var(--banner-height, 0px) - ${
        submittedSearchTerm ? "(320px )" : "(245px )"
      } 
    );
    overflow: auto;
  `}
`;

const StyledTab = styled(Box)<any>`
  ${({ theme: { colors, space } }) => `
    font-family: "Inter", sans-serif;
    font-weight: bold;
  `}
`;

const Nav = styled.ul`
  ${({ theme: { colors, space } }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;   
  `}
`;

const NavLink = styled(Link)<any>`
  ${({ theme: { colors, space }, ishighlighted }) => `
    padding: 1rem 0;
    list-style: none;
    color: ${colors.gray[50]};
    margin-right: ${space[3]};
  ${
    ishighlighted
      ? `
      border-bottom: 4px solid ${colors.blue.primary};
      color: ${colors.gray[80]};
  `
      : ""
  }
  `}
`;

const initialState = {
  status: "all",
  consultant: null,
  dateStarted: [null, null],
  applicationType: null,
  noOfIndividuals: null,
  includeArchived: false,
};

const Applications = () => {
  const navigate = useNavigate();
  const { statuses, isLoadingStatuses } = useMandateStatuses();
  const { data: bannerData, isLoading: isBannerLoading } = useBanner(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState("");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState(initialState);
  const [showTableView, setShowTableView] = useState(false);
  const { data: users } = useUsers();
  const isLoading = isLoadingStatuses;

  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    const dismissed = sessionStorage.getItem("bannerDismissed");
    if (dismissed || bannerData?.length === 0) {
      setIsBannerVisible(false);
    }
  }, [bannerData]);

  const [bannerHeight, setBannerHeight] = useState(0);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bannerRef.current) {
      setBannerHeight(bannerRef.current.offsetHeight);
    }
  }, [bannerRef.current, bannerData, isBannerVisible]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--banner-height",
      `${isBannerVisible ? bannerHeight : 0}px`
    );
  }, [bannerHeight, isBannerVisible]);

  const handleDismiss = () => {
    sessionStorage.setItem("bannerDismissed", "true");
    setIsBannerVisible(false);
  };

  const FilteredBanner =
    bannerData &&
    bannerData.length > 0 &&
    bannerData.reduceRight((acc, b) => {
      if (!acc && b.status === true) {
        return b;
      }
      return acc;
    }, null);

  const userOptions = users?.data?.results.map(
    ({ first_name, last_name, id, colour }) => ({
      value: id,
      label: `${first_name} ${last_name}`,
      firstName: first_name,
      lastName: last_name,
      colour,
    })
  );

  const filterUserIds = useMemo(
    () => [selectedUser?.value],
    [selectedUser?.value]
  );

  const handleFilterSubmit = (values) => {
    setFilters(values);
    setIsFiltersOpen(false);
  };

  const handleSearchSubmit = () => {
    setSubmittedSearchTerm(searchTerm);
  };

  const handleResetSearch = () => {
    setSearchTerm("");
    setSubmittedSearchTerm("");
  };

  return (
    <div>
      <Topbar title="Applications">
        <Flex justifyContent="space-between">
          {env.isSearchApplicationsFeatureOn ? (
            <Flex ml={4}>
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                type="search"
                size="small"
                placeholder="Search"
                maxWidth={250}
                mr={1}
              />
              <Button
                isDisabled={submittedSearchTerm === searchTerm}
                onClick={handleSearchSubmit}
              >
                Search
              </Button>

              {submittedSearchTerm && (
                <Button
                  variant="text"
                  pl="0px"
                  ml={1}
                  onClick={handleResetSearch}
                >
                  Reset search
                </Button>
              )}
            </Flex>
          ) : (
            <Box />
          )}
          <Flex>
            <UserFilter
              size="regular"
              options={userOptions}
              value={selectedUser}
              onChange={setSelectedUser as any}
              mr={1}
            />
            {env.isFilterApplicationsFeatureOn && (
              <Button
                variant="secondary"
                mr={1}
                Icon={MdTune}
                iconPosition="right"
                onClick={() => setIsFiltersOpen((isOpen) => !isOpen)}
              >
                Filter
              </Button>
            )}
            <Button
              Icon={FaPlus}
              iconSize="16px"
              onClick={() => navigate(ROUTE.APPLICATION_NEW)}
            >
              New Application
            </Button>
          </Flex>
        </Flex>
      </Topbar>

      <div>
        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="25vh">
            <Loader />
          </Flex>
        )}
        {submittedSearchTerm && (
          <H3 m={4} mb="0px" color="gray.60">
            Showing applications for search term: "{submittedSearchTerm}"
          </H3>
        )}

        {!isBannerLoading &&
          bannerData &&
          bannerData.length > 0 &&
          isBannerVisible &&
          FilteredBanner && (
            <BannerComponent
              ref={bannerRef}
              banner={FilteredBanner}
              onDismiss={handleDismiss}
            />
          )}

        <StyledTab className="Tabs">
          <Nav>
            <NavLink
              ishighlighted={!showTableView}
              className={showTableView ? "active" : ""}
              onClick={() => setShowTableView(false)}
            >
              <Image src={kanban} alt="kanban" mr={1} />
              Card View
            </NavLink>
            <NavLink
              ishighlighted={showTableView}
              className={!showTableView ? "active" : ""}
              onClick={() => setShowTableView(true)}
            >
              <Image src={grid} alt="grid" mr={1} color="red" />
              Table View
            </NavLink>
          </Nav>
        </StyledTab>

        {!isLoading && statuses && !showTableView && (
          <Columns submittedSearchTerm={submittedSearchTerm}>
            {statuses?.data.map((status) => (
              <ApplicationsList
                key={status.id}
                statusId={status.id}
                name={capitalise(status.status)}
                statusName={status.status}
                searchTerm={submittedSearchTerm}
                filterUserIds={filterUserIds}
                filters={filters}
              />
            ))}
          </Columns>
        )}

        {showTableView && (
          <div className="p-2">
            <ApplicationsTable
              searchTerm={submittedSearchTerm}
              filterUserIds={filterUserIds}
              filters={filters}
              bannerHeight={bannerHeight}
              isBannerVisible={isBannerVisible}
            ></ApplicationsTable>
          </div>
        )}
        <Filters
          isOpen={isFiltersOpen}
          onRequestClose={() => setIsFiltersOpen(false)}
          onSubmit={handleFilterSubmit}
          filters={filters}
          isTableView={showTableView}
        />
      </div>
      <Filters
        isOpen={isFiltersOpen}
        onRequestClose={() => setIsFiltersOpen(false)}
        onSubmit={handleFilterSubmit}
        filters={filters}
        isTableView={showTableView}
      />
    </div>
  );
};

export default Applications;
