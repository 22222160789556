import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { DocumentCaptureType } from "../types/AdditionalDocumentsType";

const fetch = (authHeader: string) => () =>
  axios.get<DocumentCaptureType[]>(
    `${env.verifyServiceUrl}common/document_capture_type_list/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useDocumentCaptureTypeList = () => {
  const { authHeader } = useAuth();

  return useQuery(["document_capture_type_list"], fetch(authHeader), {
    retry: false,
  });
};
