import { useConsultants } from "../../data/useConsultants";
import { useMandateTypes } from "../../data/useMandateTypes";
import { useStatusReport } from "../../data/useStatusReport";

export const useFilterOptions = () => {
  const { data: mandateTypeQuery } = useMandateTypes();
  const { data: consultantQuery } = useConsultants();
  const { data: statusReport } = useStatusReport();

  const consultants = consultantQuery?.data?.results?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const mandateTypes = mandateTypeQuery?.data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const statusList =
    statusReport?.data &&
    Object.entries(statusReport?.data).map(
      (report: [string, { id; individual_count; mandate_count }]) => ({
        label: report[0],
        value: report[1].id,
        individual_count: report[1].individual_count,
        mandate_count: report[1].mandate_count,
      })
    );

  return {
    mandateTypes,
    consultants,
    statusList,
  };
};
