import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { axios } from "./axios";

export const useCreateTenantUser = () => {
  const { authHeader } = useAuth();

  return useMutation(({ first_name, last_name, email, job_title, groups }: any) => {
    return axios.post(
      `${env.verifyServiceUrl}user/create_tenant_user/`,
      {
        first_name,
        last_name,
        email,
        job_title,
        groups,
      },
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
