import { CompanyType } from "../../types/CompanyType";

export const companyTypesFallback = [
  {
    id: 1,
    name: "Sole Trader",
  },
  {
    id: 2,
    name: "{Partnership}",
  },
  {
    id: 3,
    name: "Limited Liability Partnership",
  },
  {
    id: 4,
    name: "Limited Company",
  },
  {
    id: 5,
    name: "Other",
  },
] as CompanyType[];
