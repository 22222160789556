import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  ColorProps,
  borders,
  BorderProps,
} from "styled-system";

type TableCellProps = SpaceProps &
  TypographyProps &
  ColorProps &
  LayoutProps &
  BorderProps & {
    isHeading: boolean;
    isCentered: boolean;
  };

const StyledTd = styled.td<TableCellProps>`
  ${({ theme: { colors, space }, isHeading, isCentered }) => `
        color: ${isHeading ? colors.gray[50] : colors.blue[100]};
        border-bottom: 1px solid ${colors.gray[10]};
        padding: ${space[2]} ${space[1]};
        ${isCentered ? "text-align: center;" : ""}
    `}
  ${typography}
  ${color}
  ${space}
  ${layout}
  ${borders}
`;

export const TableCell = (props: any) => {
  const { children, isHeading, isCentered, ...styleProps } = props;

  return (
    <StyledTd {...styleProps} isHeading={isHeading} isCentered={isCentered}>
      {children}
    </StyledTd>
  );
};
