import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  _form: number;
  title: string;
  description: string;
  questions: {
    title: string;
    description: string;
    field_type: number;
    meta_data: null | string[];
    required: boolean;
    order: number;
    id?: number;
  }[];
}

export const useCreateSection = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(`${env.verifyServiceUrl}individual/section/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
