import { FormikProps } from "formik";
import { Box } from "../../components/Box";
import { Input, InputProps } from "../../components/Input";
import Select, { Option } from "../../components/Select";
import { useCountries } from "../../data/useCountries";
import { Address } from "../../types/Address";
import { Flex } from "../../components/Flex";
import { useDirtyForm } from "../../pages/Settings/DirtyFormContext";

interface AddressFormProps extends FormikProps<Address> {
  editClicked?: boolean;
}

interface RowProps {}

export const useAddressForm = (enableDirtyForm = false) => {
  const getInitialValues = (address?: Address) => ({
    address_line_1: address?.address_line_1 || "",
    address_line_2: address?.address_line_2 || "",
    city: address?.city || "",
    country: address?.country || "",
    postcode: address?.postcode || "",
    same_as_company_address: address?.same_as_company_address || false,
  });

  const { data } = useCountries();
  // const { setFormDirty } = useDirtyForm();

  const dirtyFormContext = useDirtyForm();
  const setFormDirty = dirtyFormContext
    ? dirtyFormContext.setFormDirty
    : () => {};

  const countryOptions = data?.data.map(({ isoalpha2, name }) => ({
    label: name,
    value: isoalpha2,
  })) as Option[];

  let rowProps: Partial<RowProps> = {
    flexWrap: "wrap",
    gap: "16",
    my: "4",
  };

  let inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  if (!enableDirtyForm) {
    rowProps = {
      ...rowProps,
      flexDirection: "column",
      my: "2",
      // Additional row props if needed
    };
  }

  const handleFormChange = (e, handleChange) => {
    if (enableDirtyForm) {
      setFormDirty(true);
    }
    handleChange(e);
  };

  const AddressForm = ({ editClicked = true, ...props }: AddressFormProps) => (
    <Box>
      <Flex {...rowProps}>
        <Input
          {...inputProps}
          onChange={(e) => handleFormChange(e, props.handleChange)}
          value={props.values.address_line_1}
          placeholder="Enter line 1 of your address"
          // mt="4"
          label="Address Line 1"
          name="address_line_1"
          isRequired
          hasError={Boolean(props.errors.address_line_1)}
          errorMessage={props.errors.address_line_1}
          isDisabled={props.values.same_as_company_address || !editClicked}
        />
        <Input
          {...inputProps}
          onChange={(e) => handleFormChange(e, props.handleChange)}
          value={props.values.address_line_2}
          placeholder="Enter line 2 of your address"
          // mt="2"
          label="Address Line 2 (Optional)"
          name="address_line_2"
          isDisabled={props.values.same_as_company_address || !editClicked}
          hasError={Boolean(props.errors.address_line_2)}
          errorMessage={props.errors.address_line_2}
        />
      </Flex>
      <Flex {...rowProps}>
        <Input
          onChange={(e) => handleFormChange(e, props.handleChange)}
          value={props.values.city}
          placeholder="Enter your city"
          label="City"
          isRequired
          name="city"
          {...inputProps}
          hasError={Boolean(props.errors.city)}
          errorMessage={props.errors.city}
          isDisabled={props.values.same_as_company_address || !editClicked}
        />
        <Select
          value={countryOptions?.find(
            ({ value }) => value === props.values.country
          )}
          options={countryOptions}
          onChange={(option: Option) => {
            props.setFieldValue("country", option.value);
            setFormDirty(true);
          }}
          minWidth={200}
          flex={1}
          label="Country"
          name="country"
          isRequired
          isDisabled={props.values.same_as_company_address || !editClicked}
          hasError={Boolean(props.errors.country)}
          errorMessage={props.errors.country}
        />
      </Flex>
      <Flex {...rowProps}>
        <Input
          {...inputProps}
          onChange={(e) => handleFormChange(e, props.handleChange)}
          value={props.values.postcode}
          placeholder="Enter your post or zip code"
          label="Post / Zip code"
          name="postcode"
          isRequired
          hasError={Boolean(props.errors.postcode)}
          errorMessage={props.errors.postcode}
          isDisabled={props.values.same_as_company_address || !editClicked}
        />
      </Flex>
    </Box>
  );

  return {
    getInitialValues,
    AddressForm,
  };
};
