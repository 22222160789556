import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { HelpSupportOption } from "../types/HelpSupportOption";

const fetchOptions = (authHeader: string) => () =>
  axios.get<HelpSupportOption[]>(
    `${env.verifyServiceUrl}common/support_option_list/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useHelpSupportOptions = () => {
  const { authHeader, isLoggedIn } = useAuth();
  return useQuery(["helpOptions"], fetchOptions(authHeader), {
    enabled: isLoggedIn,
    retry: false,
  });
};
