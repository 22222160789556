import { useState, useMemo, useCallback } from "react";
import { Slider } from "./../Slider/index";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Loader } from "../Loader";
import { H2, H3 } from "../Heading";
import { Paragraph } from "../Paragraph";
import { DateTime } from "luxon";
import styled from "styled-components";
import downloadIcon from "../../assets/download-blue.svg";
import noDocuments from "../../assets/no-documents.svg";
import { Button } from "../Button";
import { Image } from "../Image";
import { useAuth } from "../../context/AuthContext";
import { CheckContainer } from "../../pages/IndividualView/CheckStyledComponents";
import { notify } from "../../utils/notify";
import axios from "axios";
import Select from "../Select";
import { ExpansionPanel } from "../ExpansionPanel";

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[1]};
    word-break: break-all;
  `}
`;

const StyledParagraph = styled(Paragraph)`
  ${({ theme: { fontSizes, colors, space } }) => `
    font-size: ${fontSizes[1]};
    word-break: break-all;
    color: ${colors.gray[60]};
    margin-top: ${space[0]};
    margin-bottom: ${space[0]};
  `}
`;

const filterOptions = [
  { value: "all", label: "All" },
  { value: "proof_of_address", label: "Proof Of Address" },
  { value: "source_of_funds", label: "Source Of Funds" },
  { value: "extra_documents", label: "Extra Documents" },
  { value: "additional_documents", label: "Additional Documents" },
];

// Reusable Download Button Component
const DownloadButton = ({ onClick }) => (
  <Button onClick={onClick} variant="text">
    <Image
      src={downloadIcon}
      alt="Download Icon"
      height="auto"
      width="22px"
      mr={1}
    />
    <span>Download</span>
  </Button>
);

export const AdditionalDocuments = ({
  isOpen,
  onRequestClose,
  documents,
  documentsLoading,
}) => {
  const { authHeader } = useAuth();

  const [filter, setFilter] = useState("all");
  const [isRequestingDownloadAll, setIsRequestingDownloadAll] = useState(false);

  const { extraDocuments, proofOfAddress, sourceOfFunds, additionalDocs } =
    useMemo(
      () => ({
        extraDocuments: Array.isArray(documents?.data?.extra_documents)
          ? documents.data.extra_documents
          : [],
        proofOfAddress: Array.isArray(documents?.data?.proof_of_address)
          ? documents.data.proof_of_address
          : [],
        sourceOfFunds: Array.isArray(documents?.data?.source_of_funds)
          ? documents.data.source_of_funds
          : [],
        additionalDocs: Array.isArray(documents?.data?.additional_documents)
          ? documents.data.additional_documents
          : [],
      }),
      [documents]
    );

  const allDocuments = useMemo(
    () => [
      ...extraDocuments,
      ...proofOfAddress,
      ...sourceOfFunds,
      ...additionalDocs,
    ],
    [extraDocuments, proofOfAddress, sourceOfFunds, additionalDocs]
  );

  const getFilenameFromUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const segments = urlObj.pathname.split("/");
      return segments.pop() || "download";
    } catch (error) {
      return "download";
    }
  };

  const handleDownload = useCallback(
    async (documentLink) => {
      try {
        const response = await axios.get(documentLink, {
          headers: { Authorization: authHeader },
          responseType: "arraybuffer",
        });

        const docName = getFilenameFromUrl(documentLink);
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = docName;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Revoke to prevent memory leaks
      } catch (error) {
        console.error("Error downloading document:", error);
        notify("There was a problem while downloading the document", {
          position: "bottom-left",
          type: "error",
        });
      }
    },
    [authHeader]
  );

  const handleDownloadAll = async (documentsToDownload) => {
    if (isRequestingDownloadAll) return;

    setIsRequestingDownloadAll(true);
    notify("Downloading, this could take up to 30 seconds", {
      position: "bottom-left",
      type: "info",
    });

    const downloadPromises = documentsToDownload.map((doc) =>
      handleDownload(doc.media)
    );

    try {
      await Promise.all(downloadPromises);
      notify("All documents downloaded successfully!", {
        position: "bottom-left",
        type: "success",
      });
    } catch (error) {
      console.error("Error downloading all documents:", error);
      notify("There was a problem while downloading some documents", {
        position: "bottom-left",
        type: "error",
      });
    } finally {
      setIsRequestingDownloadAll(false);
    }
  };

  const renderDocuments = (documentCategory, categoryTitle) => {
    if (documentCategory.length > 0) {
      return (
        <ExpansionPanel title={categoryTitle} defaultExpanded>
          <Box key={categoryTitle} my={4}>
            {documentCategory.map((document) => (
              <Box
                borderBottom="1px solid"
                borderBottomColor="gray.100"
                key={document.id || document.media || document.name}
                my={2}
              >
                <Flex mb={4} justifyContent="space-between" alignItems="center">
                  <H3 fontSize={2} mb="0px">
                    {document.name}
                  </H3>
                  {document?.media && (
                    <DownloadButton
                      onClick={() => handleDownload(document.media)}
                    />
                  )}
                </Flex>
                <Flex justifyContent="space-between" alignItems="flex-start">
                  <Box flex={1} marginBottom={3}>
                    <Heading>Uploaded</Heading>
                    <StyledParagraph>
                      {DateTime.fromSQL(document.created_on).isValid
                        ? DateTime.fromSQL(document.created_on).toFormat(
                            "MMMM dd 'at' h:mma"
                          )
                        : "Invalid DateTime"}
                    </StyledParagraph>
                  </Box>
                  <Box flex={1} marginBottom={3}>
                    <Heading>File name</Heading>
                    <StyledParagraph>{document.name}</StyledParagraph>
                  </Box>
                  {document.description && (
                    <Box flex={1} marginBottom={3}>
                      <Heading>Description</Heading>
                      <StyledParagraph>{document.description}</StyledParagraph>
                    </Box>
                  )}
                </Flex>
              </Box>
            ))}
          </Box>
        </ExpansionPanel>
      );
    }
    return null;
  };

  // Memoize filtered documents based on the selected filter
  const filteredDocuments = useMemo(() => {
    if (filter === "all") {
      return allDocuments;
    }
    return documents?.data[filter] || [];
  }, [filter, allDocuments, documents]);

  // Calculate counts for each filter option
  const getCount = useCallback(
    (option) => {
      if (option.value === "all") {
        return allDocuments.length;
      }
      return documents?.data?.[option.value]?.length || 0;
    },
    [allDocuments, documents]
  );

  // Format strings to be more readable
  const formatString = (str) =>
    str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  return (
    <Slider isOpen={isOpen} onRequestClose={onRequestClose} width="70%">
      {documentsLoading ? (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      ) : (
        <CheckContainer>
          <Box px={4} py={2}>
            <H2 mb={3}>Uploaded Documents</H2>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              mr={5}
              width="100%"
              pb={4}
              borderBottom="1px solid"
              borderBottomColor="gray.10"
            >
              <Box>
                <Select
                  fontWeight="bold"
                  width={300}
                  onChange={(selectedOption: any) =>
                    setFilter(selectedOption.value)
                  }
                  options={filterOptions}
                  value={filterOptions.find(({ value }) => value === filter)}
                  getOptionLabel={({ label, value }) => (
                    <Flex gap="12" alignItems="center">
                      <Box
                        backgroundColor="gray.30"
                        borderRadius="6px"
                        px={1}
                        color="blue.100"
                        textAlign="center"
                      >
                        {getCount({ value })}
                      </Box>
                      <Box>{label}</Box>
                    </Flex>
                  )}
                />
              </Box>

              {filter === "all" && allDocuments.length > 0 && (
                <Button
                  onClick={() => handleDownloadAll(allDocuments)}
                  variant="secondary"
                >
                  <Image
                    src={downloadIcon}
                    alt="Download All Documents"
                    height="auto"
                    width="22px"
                    mr={1}
                  />
                  <Paragraph fontWeight="500" fontSize={1} color="blue.primary">
                    Download All
                  </Paragraph>
                </Button>
              )}
            </Flex>

            {filter === "all" ? (
              <>
                {renderDocuments(proofOfAddress, "Proof Of Address")}
                {renderDocuments(sourceOfFunds, "Source Of Funds")}
                {renderDocuments(extraDocuments, "Extra Documents")}
                {renderDocuments(additionalDocs, "Additional Documents")}
              </>
            ) : filteredDocuments.length > 0 ? (
              <ExpansionPanel title={formatString(filter)} defaultExpanded>
                <Flex justifyContent="flex-end" mb={3} mt="-55px">
                  <Button
                    onClick={() => handleDownloadAll(filteredDocuments)}
                    variant="secondary"
                  >
                    <Image
                      src={downloadIcon}
                      alt="Download All Filtered Documents"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    <Paragraph
                      fontWeight="500"
                      fontSize={1}
                      color="blue.primary"
                    >
                      Download All
                    </Paragraph>
                  </Button>
                </Flex>

                {filteredDocuments.map((document) => (
                  <Box
                    borderBottom="1px solid"
                    borderBottomColor="gray.100"
                    key={document.id || document.media || document.name}
                    my={2}
                  >
                    <Flex
                      mb={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <H3 fontSize={2} mb="0px">
                        {document.name}
                      </H3>
                      {document?.media && (
                        <DownloadButton
                          onClick={() => handleDownload(document.media)}
                        />
                      )}
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Box flex={1} marginBottom={3}>
                        <Heading>Uploaded</Heading>
                        <StyledParagraph>
                          {DateTime.fromSQL(document.created_on).isValid
                            ? DateTime.fromSQL(document.created_on).toFormat(
                                "MMMM dd 'at' h:mma"
                              )
                            : "Invalid DateTime"}
                        </StyledParagraph>
                      </Box>
                      <Box flex={1} marginBottom={3}>
                        <Heading>File name</Heading>
                        <StyledParagraph>{document.name}</StyledParagraph>
                      </Box>
                      {document.description && (
                        <Box flex={1} marginBottom={3}>
                          <Heading>Description</Heading>
                          <StyledParagraph>
                            {document.description}
                          </StyledParagraph>
                        </Box>
                      )}
                    </Flex>
                  </Box>
                ))}
              </ExpansionPanel>
            ) : (
              <Flex
                flexDirection="column"
                alignItems="center"
                margin="12% auto"
                justifyContent="center"
              >
                <Image
                  src={noDocuments}
                  alt="No documents available"
                  height="auto"
                />
                <H2>No documents available</H2>
              </Flex>
            )}
          </Box>
        </CheckContainer>
      )}
    </Slider>
  );
};
