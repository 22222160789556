import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { TenantFilter } from "../types/TenantFilter";

const fetch = (authHeader: string) => () =>
  axios.get<TenantFilter>(`${env.verifyServiceUrl}common/tenant/filter`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useTenantFilter = () => {
  const { authHeader } = useAuth();
  return useQuery(["tenantFilter"], fetch(authHeader), {
    retry: false,
  });
};
