import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const BaseCheckbox = styled.input<any>`
  ${({ theme: { space } }) => `
       height: 16px;
       width: 16px;
       margin-right: ${space[1]};
       min-width: 16px;
    `}
`;

const Label = styled.label<{ alignValue?: string }>`
  ${space}
  ${({ theme: { colors, fontSizes } }) => `
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[1]};
        margin-bottom: none;
        display: block;
        color: ${colors.blue[100]};
        display: flex;
        
    `}
    align-items: ${(props) => (props.alignValue ? "start" : "center")};
`;

interface CheckboxProps extends SpaceProps {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: any;
  name?: string;
  alignValue?: string;
}

export const Checkbox = (props: CheckboxProps) => {
  const { onChange, label, name, ...styleProps } = props;

  const handleChange = (event: any) => {
    onChange(event);
  };

  return (
    <Label {...styleProps}>
      <BaseCheckbox onChange={handleChange} type="checkbox" name={name} />
      {label}
    </Label>
  );
};
