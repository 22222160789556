import { GrClose } from "react-icons/gr";
import { ProgressBar } from "../../components/ProgressBar";
import { Box } from "../../components/Box";
import {
  Breadcrumb,
  StaticBreadcrumb,
  Crumb,
  BreadcrumbDivider,
} from "../../components/Breadcrumbs";
import { Flex } from "../../components/Flex";
import { IconButton } from "../../components/IconButton";
import Topbar from "../../components/Topbar";
import { ROUTE } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../components/Modal";
import { useState } from "react";

export const NewMonitoringRecordFrame = ({
  children,
  step,
}: {
  children: any;
  step: "individual" | "monitoring";
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();

  const progress = {
    individual: 50,
    monitoring: 80,
  }[step];

  const breadcrumbs = [
    {
      isActive: step === "individual",
      title: "01 Individual",
      key: 1,
    },

    {
      isActive: step === "monitoring",
      title: "02 Monitoring",
      key: 3,
    },
  ];

  const handleDeleteCheck = () => {
    navigate(ROUTE.MONITORING);
  };

  return (
    <div>
      <Topbar
        header={
          <Flex justifyContent="space-between" width="100%">
            <>
              <Breadcrumb>
                <Breadcrumb.Section linkTo={ROUTE.MONITORING}>
                  Monitoring
                </Breadcrumb.Section>
                <Breadcrumb.Section>
                  Create a new monitoring record
                </Breadcrumb.Section>
              </Breadcrumb>
            </>
            <Flex>
              <IconButton
                Type={GrClose}
                onClick={() => setIsDeleteModalOpen(true)}
                color="gray.40"
                ml={4}
                mr={3}
              />
            </Flex>
          </Flex>
        }
      ></Topbar>
      <Box height="calc(100vh - 83px)" overflowY="auto">
        <Box>
          <ProgressBar progress={progress} />
          <Box padding={2} marginLeft={2}>
            <StaticBreadcrumb>
              {breadcrumbs.map(({ isActive, title, key }, index) => (
                <Crumb
                  key={key}
                  isActive={isActive}
                  iconDivider={
                    index < breadcrumbs.length - 1 && <BreadcrumbDivider />
                  }
                >
                  {title}
                </Crumb>
              ))}
            </StaticBreadcrumb>
          </Box>
        </Box>
        <Flex justifyContent="center" alignItems="center">
          <Box>{children}</Box>
        </Flex>
      </Box>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Cancel?"
        content="Do you want to cancel this?"
        onModalConfirmClick={handleDeleteCheck}
        secondaryButtonText="Close"
        primaryButtonText="Cancel"
        primaryButtonVariant="danger"
        hasSecondaryButton
        width="450px"
      />
    </div>
  );
};
