import React, { useEffect, useState, useCallback } from "react";
import SlidingPane from "react-sliding-pane";
import { IconButton } from "../IconButton";
import { IoMdClose } from "react-icons/io";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./styles.css";

type SliderProps = {
  isOpen?: boolean;
  children: React.ReactNode;
  onRequestClose: () => void;
  width?: string;
};

export const Slider = (props: SliderProps) => {
  const { isOpen, onRequestClose, children, width = "325px" } = props;
  const [paneClass, setPaneClass] = useState("slide-pane");

  const handleClose = useCallback(() => {
    setPaneClass("slide-pane close");
    if (width === "325px") {
      onRequestClose();
    } else {
      setTimeout(onRequestClose, 300); // Ensure the onRequestClose is called after the animation
    }
  }, [onRequestClose, width]);

  useEffect(() => {
    let closeTimeout: NodeJS.Timeout;

    if (isOpen) {
      setPaneClass("slide-pane open");
    } else {
      setPaneClass("slide-pane close");
      closeTimeout = setTimeout(() => {
        if (!isOpen) {
          onRequestClose();
        }
      }, 300);
    }

    return () => clearTimeout(closeTimeout); // Clear the timeout when component unmounts or isOpen changes
  }, [isOpen, onRequestClose]);

  return (
    <SlidingPane
      isOpen={isOpen}
      onRequestClose={handleClose}
      width={width}
      hideHeader
      overlayClassName="slider-overlay"
      className={paneClass}
    >
      <IconButton
        onClick={handleClose}
        Type={IoMdClose}
        position="absolute"
        right={3}
        size="23px"
        top={2}
      />
      {children}
    </SlidingPane>
  );
};
