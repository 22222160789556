import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  grid,
  SpaceProps,
  BackgroundProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  BorderProps,
  PositionProps,
  GridProps,
} from "styled-system";

export type BoxProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  GridProps &
  PositionProps & {
    gap?: string;
    color?: string;
  };

export const Card = styled.div<BoxProps>`
  ${({ gap }) => (gap ? `gap: ${gap}px;` : "")}
  ${space}
  ${color}
  ${grid}
  ${space}
  ${typography}
  ${layout}
  ${flexbox}
  ${background}
  ${border}
  ${position}
  border-radius: 15px;
  background-color: white;
  padding: 24px;
`;

export const GrayCard = styled.div<BoxProps>`
  ${({ gap }) => (gap ? `gap: ${gap}px;` : "")}
  ${({ theme: { colors, space } }) => `    
      background-color: ${colors.gray[10]};
      margin-left:${space[4]};
      margin-right:${space[4]};
      margin-bottom:${space[4]};
    `}
  ${space}
  ${color}
  ${grid}
  ${space}
  ${typography}
  ${layout}
  ${flexbox}
  ${background}
  ${border}
  ${position}
  border-radius: 15px;
  padding: 24px;

  


`;
