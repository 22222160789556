import { Box } from "../Box";
import { DateRangePicker } from "../DateRangePicker/index";
import { Paragraph } from "../Paragraph";
import { Slider } from "../Slider";
import { Toggle } from "../Toggle";
import { Form, Formik } from "formik";
import Select from "../Select";
import { useFilterOptions } from "./useFilterOptions";
import { Label } from "../Label";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Image } from "../Image";
import person from "../../assets/card-person-gray.svg";
import styled from "styled-components";
import { MandateStatus } from "../../context/MandateStatusContext";
import { capitalise } from "../../utils/string";

export const MandateCountDiv = styled.div<{ status: string }>`
  ${({ theme: { colors, fontSizes, space, radii }, status }) => `
  display: inline-block;
  padding: ${space[0]};
  border-radius: ${radii[0]};
  font-size:  ${fontSizes[0]};
  margin-right:${space[1]};
  color: ${colors.white};
  font-family: 'Inter';
  font-weight: 500;
  min-width: 18px;
  text-align: center;
  background: 
    ${
      status === MandateStatus.Completed
        ? colors.green.primary
        : status === MandateStatus.InProgress
        ? colors.blue.primaryEmphasis
        : status === MandateStatus.InReview
        ? colors.orange[50]
        : status === MandateStatus.Rejected
        ? colors.red.primary
        : colors.gray[60]
    };       
    `}
`;

export const IndividualCountDiv = styled.span<any>`
  ${({ theme: { colors, fontSizes, space } }) => `
      font-size: ${fontSizes[0]};
      color: ${colors.gray[40]};
      margin-right: ${space[1]};
      font-family: 'Inter';
    `}
`;

type FiltersProps = {
  isOpen?: boolean;
  onRequestClose: () => void;
  filters: any;
  onSubmit: (values: any) => void;
  isTableView: boolean;
};

const initialState = {
  status: 'all', //{ label: "All", value: "all" },
  consultant: null,
  dateStarted: [null, null],
  applicationType: null,
  noOfIndividuals: null,
  includeArchived: false,
};

export const Filters = (props: FiltersProps) => {
  const { isOpen, onRequestClose, onSubmit, filters, isTableView } = props;
  const options = useFilterOptions();

  return (
    <Slider isOpen={isOpen} onRequestClose={onRequestClose}>
      <Box p={3} overflowY="auto" height={"calc(100vh - 138px)"}>
        <Formik
          initialValues={filters}
          onSubmit={(values, helpers) => {
            onSubmit(values);
            helpers.resetForm({ values });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Paragraph as="h2" fontSize={3} mt="0px" display="flex">
                Filters
                <Button
                  type="reset"
                  px={2}
                  variant="text"
                  size="small"
                  position="absolute"
                  top="26px"
                  right="75px"
                  onClick={() => {
                    props.resetForm({ values: initialState });
                    props.submitForm();
                  }}
                >
                  Clear All
                </Button>
              </Paragraph>

              {isTableView && (
                <Select
                  onChange={(option: any) => {
                    props.setFieldValue("status", option.value);
                  }}
                  getOptionLabel={(options) => {
                    return (
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        position="relative"
                        width={"100%"}
                      >
                        <span>
                          <MandateCountDiv status={options.label}>
                            {options.mandate_count}
                          </MandateCountDiv>
                          {capitalise(options.label)}
                        </span>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          position="relative"
                        >
                          <IndividualCountDiv>
                            {options.individual_count}
                          </IndividualCountDiv>
                          <Image
                            src={person}
                            alt="indicator icon"
                            height="20px"
                            width="24px"
                            marginRight={"12px"}
                          />
                        </Flex>
                      </Flex>
                    );
                  }}
                  value={
                    options.statusList?.find(
                      ({ value }) => value === props.values.status
                    ) || { label: "All", value: "all" }
                  }
                  options={options.statusList}
                  placeholder="Select status"
                  label="Status"
                  name="status"
                />
              )}

              <Select
                onChange={(option: any) =>
                  props.setFieldValue("consultant", option.value)
                }
                value={
                  options.consultants?.find(
                    ({ value }) => value === props.values.consultant
                  ) || ""
                }
                options={options.consultants}
                mt={3}
                label="Consultant"
                name="consultant"
              />
              <DateRangePicker
                value={props.values.dateStarted}
                onChange={(option: any) =>
                  props.setFieldValue("dateStarted", option)
                }
                mt={3}
                label="Date Started"
                name="dateStarted"
              />
              <Select
                onChange={(option: any) =>
                  props.setFieldValue("applicationType", option.value)
                }
                value={
                  options.mandateTypes?.find(
                    ({ value }) => value === props.values.applicationType
                  ) || ""
                }
                options={options.mandateTypes}
                mt={3}
                label="Application Type"
                name="applicationType"
              />
              {/* <Input
                onChange={props.handleChange}
                value={props.values.noOfIndividuals}
                type="number"
                mt={3}
                placeholder="Select..."
                label="No. of Individuals"
                name="noOfIndividuals"
                min={0}
                max={10}
              /> */}
              <Label mt={3}>Is archived</Label>
              <Toggle
                isChecked={props.values.includeArchived}
                onChange={(e: any) => {
                  props.setFieldValue("includeArchived", e.target.checked);
                }}
                mt={2}
              />
              {props.dirty && (
                <Button type="submit" mt="2" size="large" width="100%">
                  Apply
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Slider>
  );
};
