import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { PagedResponse } from "../types/Common";
import { env } from "../utils/env";
import { Status } from "../types/status";


const fetchMonitoringStatus = (authHeader: string) => () =>
  axios.get<Status[]>(`${env.verifyServiceUrl}common/monitoring_status_list/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useMonitoringStatus = () => {
  const { authHeader } = useAuth();
  const query = useQuery(["Status"], fetchMonitoringStatus(authHeader), {retry: false});

  return query;
};
