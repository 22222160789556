import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { PagedResponse } from "../types/Common";
import { BillingInfo } from "../types/billingInfo";

const fetch = (authHeader: string) => () =>
  axios.get<PagedResponse<BillingInfo[]>>(
    `${env.verifyServiceUrl}user/billing_information/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useBillingInfo = () => {
  const { authHeader } = useAuth();
  return useQuery(["billingInfoData"], fetch(authHeader), {
    enabled: true,
    retry: false,
  });
};
