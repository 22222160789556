import { Image } from "../Image";
import { Box } from "../Box";
import success from "../../assets/card-success-icon.svg";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Paragraph } from "../Paragraph";
import { H3 } from "../Heading";
import styled from "styled-components";
import { DateTime } from "luxon";

export const StyledCard = styled(Box)`
  background-color: white;
  height: 168px;
  border-radius: 15px;
  padding: 24px;
`;

export const IndividualCard = ({
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  email,
  onRemoveClick,
  onEditClick,
  hasRemoveButton,
  hasEditButton
}: any) => {
  return (
    <>
      <StyledCard>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <Image
            src={success}
            alt="indicator icon"
            height="20px"
            width="24px"
          />
          {hasRemoveButton && (
            <Button
              onClick={onRemoveClick}
              variant="text"
              position="absolute"
              right="0"
              top="0"
            >
              Remove
            </Button>
          )}
          {hasEditButton && (
            <Button
              onClick={onEditClick}
              variant="text"
              position="absolute"
              right="0"
              top="0"
            >
              Edit
            </Button>
          )}
        </Flex>
        <H3 mt="2">
          {firstName ? firstName : ""}
          {middleName ? ` ${middleName}` : ""}
          {lastName ? ` ${lastName}` : ""}
        </H3>
        {dateOfBirth && (
          <Paragraph color="gray.50">
            {dateOfBirth
              ? DateTime.fromISO(dateOfBirth).toFormat("dd/MM/yyyy")
              : ""}
          </Paragraph>
        )}
        <Paragraph color="gray.50" pt={1}>
          {email}
        </Paragraph>
      </StyledCard>
    </>
  );
};
