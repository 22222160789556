import { DateTime } from "luxon";
import { useState } from "react";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { CheckStatus } from "../../types/Individual";
import { AddressVerificationCheck } from "./AddressVerificationCheck";
import { GeoLocationCheck } from "./GeoLcationCheck";
import { IdentityLivenessCheck } from "./IdentityLivenessCheck";
import { PepAndSanctionsCheck } from "./PepAndSanctionsCheck";
import { BankDetailsCheck } from "./BankDetailsCheck";
import { PersonalDetailsCheck } from "./PersonalDetailsCheck";
import { ProofOfAddressCheck } from "./ProofOfAddressCheck";
import { Image } from "../../components/Image";
import {
  IndividualChecksMap,
  checksStatusIconsMap,
  checksStatusName,
} from "./helper";
import { queryClient } from "../../queryClient";
import { useParams } from "react-router-dom";
import { AdverseMediaCheck } from "./AdverseMediaCheck";
import { SourceOfFundsCheck } from "./SourceOfFundsCheck";

type Props = {
  onShowContent?: any;
  title: string;
  status: CheckStatus;
  id: number;
  name: string;
  date: string;
  useSlider: boolean;
};

export const CheckSection = (props: Props) => {
  const params = useParams();
  const [isIdAndLivenessCheck, setIsIdAndLivenessCheck] = useState(false);
  const [isPersonalDetailsCheck, setIsPersonalDetailsCheck] = useState(false);
  const [isPepAndSanctionsCheck, setIsPepAndSanctionsCheck] = useState(false);
  const [isBankDetailsCheck, setIsBankDetailsCheck] = useState(false);
  const [isProofOfAddressCheck, setIsProofOfAddressCheck] = useState(false);
  const [isAddressVerificationCheck, setIsAddressVerificationCheck] =
    useState(false);
  const [isGeoLocationCheck, setIsGeoLocationCheck] = useState(false);
  const [isAdverseMediaCheck, setIsAdverseMediaCheck] = useState(false);
  const [isSourceOfFundsCheck, setIsSourceOfFundsCheck] = useState(false);

  const [checkId, setCheckId] = useState(null);

  const handleViewClick = (name: string, id: number) => {
    setCheckId(id);

    if (name === IndividualChecksMap.address_verification_check.name) {
      if (props.useSlider) {
        setIsAddressVerificationCheck(!isAddressVerificationCheck);
      } else {
        const content = (
          <AddressVerificationCheck
            isOpen={true}
            onRequestClose={() => setIsAddressVerificationCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
    if (name === IndividualChecksMap.proof_of_address_check.name) {
      if (props.useSlider) {
        setIsProofOfAddressCheck(!isProofOfAddressCheck);
      } else {
        const content = (
          <ProofOfAddressCheck
            isOpen={true}
            onRequestClose={() => setIsProofOfAddressCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
    if (name === IndividualChecksMap["pep_&_sanctions_check"].name) {
      if (props.useSlider) {
        setIsPepAndSanctionsCheck(!isPepAndSanctionsCheck);
      } else {
        const content = (
          <PepAndSanctionsCheck
            isOpen={true}
            onRequestClose={() => setIsPepAndSanctionsCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
    if (name === IndividualChecksMap.bank_account_details_check.name) {
      if (props.useSlider) {
        setIsBankDetailsCheck(!isBankDetailsCheck);
      } else {
        const content = (
          <BankDetailsCheck
            isOpen={true}
            onRequestClose={() => setIsBankDetailsCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
    if (name === IndividualChecksMap.personal_details_match_check.name) {
      if (props.useSlider) {
        setIsPersonalDetailsCheck(!isPersonalDetailsCheck);
      } else {
        const content = (
          <PersonalDetailsCheck
            isOpen={true}
            onRequestClose={() => setIsPersonalDetailsCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
    if (name === IndividualChecksMap["id_&_liveliness_check"].name) {
      if (props.useSlider) {
        setIsIdAndLivenessCheck(!isIdAndLivenessCheck);
      } else {
        const content = (
          <IdentityLivenessCheck
            isOpen={true}
            onRequestClose={() => setIsIdAndLivenessCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
    if (name === IndividualChecksMap.geo_location_check.name) {
      if (props.useSlider) {
        setIsGeoLocationCheck(!isGeoLocationCheck);
      } else {
        const content = (
          <GeoLocationCheck
            isOpen={true}
            onRequestClose={() => setIsGeoLocationCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
    if (name === IndividualChecksMap.adverse_media_check.name) {
      if (props.useSlider) {
        setIsAdverseMediaCheck(!isAdverseMediaCheck);
      } else {
        const content = (
          <AdverseMediaCheck
            isOpen={true}
            onRequestClose={() => setIsAdverseMediaCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
    if (name === IndividualChecksMap.source_of_funds_check.name) {
      if (props.useSlider) {
        setIsSourceOfFundsCheck(!isSourceOfFundsCheck);
      } else {
        const content = (
          <SourceOfFundsCheck
            isOpen={true}
            onRequestClose={() => setIsSourceOfFundsCheck(false)}
            id={id}
            useSlider={false}
          />
        );
        props.onShowContent(content);
      }
    }
  };

  function isDate(date) {
    return isNaN(date) && !isNaN(Date.parse(date));
  }

  const refetchIndividual = () => {
    queryClient.invalidateQueries(["individual", params.id]);
  };

  return (
    <>
      <Card mb={2} minHeight="97px">
        <Flex alignItems="center" mb={1}>
          <Image
            src={checksStatusIconsMap[props.status.status]}
            alt="status"
            height="21px"
            width="21px"
            mr={1}
          />
          <Paragraph fontSize={1} mt="0px" mb="0px">
            {checksStatusName[props.status.status]}
          </Paragraph>
        </Flex>

        <H3>{props.title}</H3>

        <Flex justifyContent="space-between" alignItems="center">
          <Paragraph fontSize={1} mt={1} mb={0} color="gray.40">
            {isDate(props.date)
              ? DateTime.fromISO(props.date).toFormat("dd/MM/yyyy")
              : props.date}
          </Paragraph>

          {props.status.status !== "pending" && (
            <Button
              size="regular"
              variant="text"
              fontWeight="bold"
              onClick={() => handleViewClick(props.name, props.id)}
            >
              View
            </Button>
          )}
        </Flex>
      </Card>

      {isPersonalDetailsCheck && (
        <PersonalDetailsCheck
          isOpen={isPersonalDetailsCheck}
          onRequestClose={() => {
            setIsPersonalDetailsCheck(false);
            refetchIndividual();
          }}
          id={checkId}
        />
      )}

      {isAddressVerificationCheck && (
        <AddressVerificationCheck
          isOpen={isAddressVerificationCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsAddressVerificationCheck(false);
          }}
          id={checkId}
        />
      )}

      {isProofOfAddressCheck && (
        <ProofOfAddressCheck
          isOpen={isProofOfAddressCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsProofOfAddressCheck(false);
          }}
          id={checkId}
        />
      )}

      {isPepAndSanctionsCheck && (
        <PepAndSanctionsCheck
          isOpen={isPepAndSanctionsCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsPepAndSanctionsCheck(false);
          }}
          id={checkId}
        />
      )}

      {isBankDetailsCheck && (
        <BankDetailsCheck
          isOpen={isBankDetailsCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsBankDetailsCheck(false);
          }}
          id={checkId}
        />
      )}

      {isIdAndLivenessCheck && (
        <IdentityLivenessCheck
          isOpen={isIdAndLivenessCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsIdAndLivenessCheck(false);
          }}
          id={checkId}
        />
      )}

      {isGeoLocationCheck && (
        <GeoLocationCheck
          isOpen={isGeoLocationCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsGeoLocationCheck(false);
          }}
          id={checkId}
        />
      )}

      {isAdverseMediaCheck && (
        <AdverseMediaCheck
          isOpen={isAdverseMediaCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsAdverseMediaCheck(false);
          }}
          id={checkId}
        />
      )}

      {isSourceOfFundsCheck && (
        <SourceOfFundsCheck
          isOpen={isSourceOfFundsCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsSourceOfFundsCheck(false);
          }}
          id={checkId}
        />
      )}
    </>
  );
};
