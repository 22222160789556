export function capitalise(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const buildName = (names: string[]) => {
  return names.reduce((fullName, name) => {
    if (name) {
      return `${fullName} ${name}`
    }
    return fullName;
  });
};