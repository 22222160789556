import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { H1 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import { useAuth } from "../../context/AuthContext";
import { useSignUpUser } from "../../data/useSignUpUser";
import { ROUTE } from "../../routes";
import { logError, showError } from "../../utils/error-handling";
import { userSignUpValidation } from "../../utils/validationSchema";

interface callSignUp {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const UserDetailsStep = () => {
  const signUp = useSignUpUser();
  const navigate = useNavigate();
  const { login } = useAuth();

  const callSignUp = ({ firstName, lastName, email, password }: callSignUp) => {
    signUp.mutate(
      { firstName, lastName, email, password },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2"))
            login.call(email, password, ROUTE.VERIFY);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while signing you up.");
          // displayError(
          //   extractErrors(err)?.email?.[0] ||
          //     "A problem occurred while signing you up."
          // );
          logError(err);
        },
      }
    );
  };

  const isLoading = signUp.isLoading || login.isLoading;

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={userSignUpValidation}
        onSubmit={(values) => {
          callSignUp(values);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Box overflowY="auto">
              <H1 color="darkBlue">Get Started</H1>
              <Paragraph color="darkBlue">
                Create company profile and set up users
              </Paragraph>
              <Input
                onChange={props.handleChange}
                value={props.values.firstName}
                hasError={Boolean(props.errors.firstName)}
                errorMessage={props.errors.firstName}
                placeholder="Enter your first name"
                mt="4"
                label="First name"
                name="firstName"
              />
              <Input
                onChange={props.handleChange}
                value={props.values.lastName}
                hasError={Boolean(props.errors.lastName)}
                errorMessage={props.errors.lastName}
                placeholder="Enter your last name"
                mt="2"
                label="Last name"
                name="lastName"
              />
              <Input
                onChange={props.handleChange}
                value={props.values.email}
                hasError={Boolean(props.errors.email)}
                errorMessage={props.errors.email}
                placeholder="Enter your email address"
                mt="2"
                label="Email Address"
                name="email"
              />
              <Input
                onChange={props.handleChange}
                value={props.values.password}
                type="password"
                placeholder="Enter your password"
                hasError={Boolean(props.errors.password)}
                errorMessage={props.errors.password}
                mt="5"
                label="Password"
                name="password"
              />
              <Paragraph color="gray.50" fontWeight="400" fontSize="0">
                The password should be at least twelve characters long. To make
                it stronger, use upper and lower case letters, numbers, and
                symbols like !@#%&)
              </Paragraph>
              <Input
                onChange={props.handleChange}
                value={props.values.confirmPassword}
                hasError={Boolean(props.errors.confirmPassword)}
                errorMessage={props.errors.confirmPassword}
                type="password"
                placeholder="Enter your password"
                mt="2"
                label="Confirm password"
                name="confirmPassword"
              />
            </Box>
            <Box>
              <Button
                type="submit"
                size="large"
                width="100%"
                mt="4"
                mb="0"
                isDisabled={isLoading || !props.isValid || !props.dirty}
              >
                Get Started
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Button
        position="absolute"
        top={2}
        right={2}
        variant="secondary"
        onClick={() => navigate(ROUTE.LOGIN)}
      >
        Sign In
      </Button>
      <Box />
    </>
  );
};
