import { Formik, Form } from "formik";
import { Navigate } from "react-router-dom";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { H1 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { useAuth } from "../../context/AuthContext";
import { ROUTE } from "../../routes";
import { useAddressForm } from "../../utils/forms/useAddressForm";
import { companyAddressValidation } from "../../utils/validationSchema";

export const CompanyAddressStep = ({
  onSubmit,
  doubleClickCompany,
  doubleClick,
}: any) => {
  const { AddressForm, getInitialValues } = useAddressForm();
  const { isLoggedIn } = useAuth();

  /** User is not ready for this step of the flow. */
  if (!isLoggedIn) {
    return <Navigate to={ROUTE.SIGN_UP} replace />;
  }
  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={companyAddressValidation}
        onSubmit={(values) => {
          doubleClick(true);
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <H1 color="darkBlue">Business address</H1>
            <Paragraph color="darkBlue">Add business address</Paragraph>
            <AddressForm {...props} />
            <Box>
              <Button
                size="large"
                width="100%"
                mt="2"
                mb="0"
                type="submit"
                isDisabled={
                  !props.isValid || !props.dirty || doubleClickCompany
                }
              >
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Box />
    </>
  );
};
