import { useState, forwardRef } from "react";
import styled from "styled-components";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Paragraph } from "../Paragraph";
import { H2, H3 } from "../Heading";
import { Button } from "../Button";
import { Image } from "../Image";
import { Modal } from "../Modal";
import { IconButton } from "../IconButton";
import { IoMdClose } from "react-icons/io";
import bigBanner from "../../assets/banner.svg";
import newfeature from "../../assets/new-feature.svg";
import { Banner as BannerType } from "../../types/Banner";

const BannerContainer = styled(Box)`
  ${({ theme: { fontSizes, colors, space, radii } }) => `
    background-color: ${colors.gray[30]};
    font-family: "Inter", sans-serif;
    font-size: ${fontSizes[0]};
    margin: ${space[4]};
    border-radius: ${radii[1]};
    padding: ${space[3]};
  `}
`;

const SmallBannerContainer = styled(Box)`
  ${({ theme: { fontSizes, colors, space, radii } }) => `
    background-color: ${colors.blue[100]};
    font-family: "Inter", sans-serif;
    font-size: ${fontSizes[0]};
    margin: ${space[4]};
    border-radius: ${radii[0]};
    padding: ${space[3]};
    color: ${colors.white};
  `}
`;

const NewFeature = styled(Flex)`
  ${({ theme: { fontSizes, colors, space, radii } }) => `
    border: 1px solid ${colors.gray[40]};
    gap: ${space[1]};
    font-size: ${fontSizes[1]};
    padding: ${space[1]};
    border-radius: ${radii[0]};
    align-items: center;
    font-weight: bold;
    color: ${colors.blue.primary};
    width: fit-content;
  `}
`;

interface BannerProps {
  banner: BannerType;
  onDismiss: () => void;
}

const BannerComponent = forwardRef<HTMLDivElement, BannerProps>(
  ({ banner, onDismiss }, ref) => {
    const [isFindOutMoreOpen, setIsFindOutMoreOpen] = useState(false);

    let formattedShortDesc = {
      __html: banner.short_description.replace(/\r\n/g, "<br>"),
    };

    const handleSeeMore = () => {
      window.open(banner.url, "_blank", "noreferrer");
    };

    const renderBannerContent = () => {
      if (banner.banner_type === "large") {
        return (
          <>
            <IconButton
              onClick={onDismiss}
              Type={IoMdClose}
              position="absolute"
              right={2}
              size="23px"
              top={0}
            />

            <Box flex={1.5}>
              <NewFeature>
                <Image src={newfeature} alt="feature" />
                New Notification
              </NewFeature>

              <H2 my={2}>{banner.title}</H2>

              <Paragraph
                minHeight={35}
                fontSize={1}
                color={"gray.60"}
                dangerouslySetInnerHTML={formattedShortDesc}
              />

              <Flex>
                <Button
                  mr={2}
                  onClick={() => setIsFindOutMoreOpen(true)}
                  width={"145px"}
                >
                  Find out more
                </Button>
                <Button variant="blueText" onClick={onDismiss}>
                  Dismiss
                </Button>
              </Flex>
            </Box>
            <Box marginRight={"8%"} flex={0.5}>
              <Box position={"absolute"} bottom={"-26px"}>
                <Image
                  src={bigBanner}
                  alt="feature banner"
                  height="100%"
                  width="100%"
                />
              </Box>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <Flex gap="20">
              <Image width={"30px"} src={newfeature} alt="feature" />
              <Box>
                <H3 mb={"0px"} color={"white"}>
                  {banner.title}
                </H3>
                <Paragraph my={0} fontSize={1} color={"white"} fontWeight={100}>
                  {banner.short_description}
                </Paragraph>
              </Box>
            </Flex>
            <Flex gap="20">
              <Button variant="blueText" color={"white"} onClick={onDismiss}>
                Dismiss
              </Button>
              <Button
                mr={2}
                onClick={() => setIsFindOutMoreOpen(true)}
                minWidth={145}
              >
                Find out more
              </Button>
            </Flex>
          </>
        );
      }
    };

    const Container =
      banner.banner_type === "large" ? BannerContainer : SmallBannerContainer;

    return (
      <>
        <Container ref={ref}>
          <Flex
            justifyContent="space-between"
            alignItems={"center"}
            position={"relative"}
            gap="50"
          >
            {renderBannerContent()}
          </Flex>
        </Container>
        <Modal
          isOpen={isFindOutMoreOpen}
          onClose={() => setIsFindOutMoreOpen(false)}
          title={banner.title}
          content={banner.long_description}
          onModalConfirmClick={handleSeeMore}
          secondaryButtonText="Close"
          primaryButtonText="See More"
          primaryButtonVariant="primary"
          hasSecondaryButton
          width="500px"
          hasModalBannerImage={true}
        />
      </>
    );
  }
);

export default BannerComponent;