import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { HelpContactForm } from "../types/HelpSupportOption";

const fetch = (authHeader: string) => () =>
  axios.get<HelpContactForm>(`${env.verifyServiceUrl}common/support_form/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useSupportContactForm = () => {
  const { authHeader, isLoggedIn } = useAuth();
  return useQuery(["helpContactForm"], fetch(authHeader), {
    enabled: isLoggedIn,
    retry: false,
  });
};
