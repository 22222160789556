import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";

export const useResendVerificationCode = () => {
  return useMutation(({ email }: { email: string }) => {
    return axios.post(`${env.verifyServiceUrl}user/resend_verify_code/`, {
      email,
    });
  });
};
