import { FilterOption } from "../types/TenantFilter";

export const getSelectedValue = (accessor: any[], matcher: any) => {
  accessor?.find(({ value }: any, matcher: any) => value === matcher);
};


// Function to group by subcategory
export const groupBySubcategory = (options: FilterOption[]) => {
  return options.reduce((acc, option) => {
    const { subcategory } = option;
    if (!acc[subcategory]) {
      acc[subcategory] = [];
    }
    acc[subcategory].push(option);
    return acc;
  }, {} as Record<string, FilterOption[]>);
};