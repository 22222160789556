import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { TenantFilter } from "../types/TenantFilter";

export const useFilterReset = () => {
  const { authHeader } = useAuth();

  return useMutation(() => {
    return axios.get<TenantFilter>(
      `${env.verifyServiceUrl}common/tenant/filter/reset`,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
