import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Title {
    name: string;    
};

  
const fetchTitles = () => () =>
  axios.get<Title[]>(
    `${env.verifyServiceUrl}common/titles/`,
  );
export const useCommonTitles = () => {
  return useQuery(["common_titles"], fetchTitles(), {retry: false});
};
