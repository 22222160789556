import styled from "styled-components";
import {
  space,
  color,
  layout,
  position,
  SpaceProps,
  ColorProps,
  LayoutProps,
  PositionProps,
} from "styled-system";

export const Label = styled.label<
  SpaceProps & ColorProps & LayoutProps & PositionProps
>`
  ${space}
  ${color}
  ${layout}
  ${position}
  ${({ theme: { colors, space, fontSizes } }) => `
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[1]};
        margin-bottom: ${space[1]};
        display: block;
        color: ${colors.blue[100]};
    `}
`;
