import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { AdditionalDocumentsList } from "../types/AdditionalDocumentsType";

const fetch = (authHeader: string) => () =>
  axios.get<AdditionalDocumentsList[]>(
    `${env.verifyServiceUrl}individual/additional_document/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useAllAdditionalDocuments = () => {
  const { authHeader } = useAuth();
  return useQuery(["addAdditionalDocuments"], fetch(authHeader), {
    retry: false,
    select: (response) => response.data,
  });
};
