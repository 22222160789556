import { useState } from "react";
import { Flex } from "../Flex";
import styled from "styled-components";
import { Paragraph } from "../Paragraph";
import { Box } from "../Box";

const StyledSlider = styled.input.attrs({
  type: "range",
})`
  ${({ theme: { colors } }: any) => `
  background: ${colors.gray[30]};
  border-radius: 12px;
  height: 18px;
  cursor: pointer;
  width:100%;
  &::-webkit-slider-thumb {
    background: ${colors.blue.primary};
    border-radius: 50%;
    height: 50px;
    cursor: pointer;
  }
  `}
`;

const RangeLabelContainer = styled.span`
  display: inline-block;
  margin-left: 6px;
  width: 28%;

  &:last-child {
    width: auto;
  }
  &:first-child {
    margin-left: 0;
  }
`;

const RangeLabel = styled(Paragraph)`
  margin: 10px;
  ${({ theme: { fontSizes, colors, space } }) => `
  font-size: ${fontSizes[1]};
  word-break: break-all;
  color: ${colors.gray[60]};  
`}
`;

const CustomRangeSlider = ({ onChange, toleranceValue = 0 }) => {
  const [value, setValue] = useState(toleranceValue);

  const handleChange = (event) => {
    const newValue = Number(event.target.value);
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Flex
      alignItems={"center"}
      flexDirection={"column"}
      width={"100%"}
      className="range-slider"
    >
      <StyledSlider min={0} max={3} value={value} onChange={handleChange} />
      <Box width={"100%"}>
        <RangeLabelContainer>
          <RangeLabel>{0}</RangeLabel>
        </RangeLabelContainer>
        <RangeLabelContainer>
          <RangeLabel>{1}</RangeLabel>
        </RangeLabelContainer>
        <RangeLabelContainer>
          <RangeLabel>{2}</RangeLabel>
        </RangeLabelContainer>
        <RangeLabelContainer>
          <RangeLabel>{3}</RangeLabel>
        </RangeLabelContainer>
      </Box>
    </Flex>
  );
};

export default CustomRangeSlider;
