import { Flex } from "../Flex";
import { Image } from "../Image";
import { FaCog } from "react-icons/fa";
import { HiClipboardList } from "react-icons/hi";
import logo from "../../assets/verify-tiller-icon.svg";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ROUTE } from "../../routes";
import { space } from "styled-system";
import { BiLogOutCircle, BiSolidBinoculars } from "react-icons/bi";
import { BiHelpCircle } from "react-icons/bi";
import { LogoutConfirmModal } from "../Modals/LogoutConfirmModal";
import { useState } from "react";
import { ProfileOrb } from "../ProfileOrb";
import { useAuth } from "../../context/AuthContext";
import { Icon } from "../Icon";
import { AiFillCheckSquare } from "react-icons/ai";
import { useBillingInfo } from "../../data/useBillingInfo";

const styles = css`
  ${space}

  ${({ theme: { radii }, isDisabled }: any) => `
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58px;
        height: 48px;
        border-radius: ${radii[0]};
        text-decoration: none;

        ${
          !isDisabled
            ? `
          cursor: pointer;
          :hover {
              background: rgba(241, 244, 248, 0.1);
  
          }`
            : ""
        }
    `}
`;

const StyledButton = styled.div<any>`
  ${styles}
`;

const StyledLink = styled(Link)<any>`
  ${styles}
`;

const SidebarItem = (props: any) => {
  const { tooltip, IconType, to, onClick, children } = props;

  const icon = <Icon Type={IconType} color="white" size="26px" />;

  if (onClick) {
    return (
      <StyledButton onClick={onClick} title={tooltip}>
        {children || icon}
      </StyledButton>
    );
  }

  return (
    <StyledLink to={to} title={tooltip}>
      {children || icon}
    </StyledLink>
  );
};

const Sidebar = () => {
  const { userDetails, isAdmin } = useAuth();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const { data: billingData } = useBillingInfo();

  return (
    <Flex
      height="100vh"
      bg="blue.100"
      width="72px"
      flexDirection="column"
      alignItems="center"
      position="fixed"
    >
      <StyledButton isDisabled mt="3">
        <Image src={logo} alt="Tiller Verify Icon" />
      </StyledButton>
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
        mt="5"
        pb="3"
      >
        <div>
          <SidebarItem
            tooltip="Applications"
            IconType={HiClipboardList}
            to={ROUTE.APPLICATIONS}
          />
          <SidebarItem
            tooltip="Screening"
            IconType={AiFillCheckSquare}
            to={ROUTE.CHECKS}
          />
          {billingData && billingData?.data?.results[0]?.monitoring_enabled && (
            <SidebarItem
              tooltip="Monitoring"
              IconType={BiSolidBinoculars}
              to={ROUTE.MONITORING}
            />
          )}
        </div>

        <div>
          <SidebarItem
            tooltip="Help/Support"
            IconType={BiHelpCircle}
            to={ROUTE.HELP_AND_SUPPORT}
          />
          {isAdmin && (
            <SidebarItem
              tooltip="Settings"
              IconType={FaCog}
              to={ROUTE.SETTINGS}
            />
          )}
          <SidebarItem tooltip="Profile" to={ROUTE.PROFILE}>
            <ProfileOrb
              firstName={userDetails?.first_name}
              lastName={userDetails?.last_name}
              colour={userDetails?.colour}
            />
          </SidebarItem>
          <SidebarItem
            tooltip="Log-out"
            IconType={BiLogOutCircle}
            onClick={() => setIsLogoutModalOpen(true)}
          />
        </div>
      </Flex>
      <LogoutConfirmModal
        isOpen={isLogoutModalOpen}
        onRequestClose={() => setIsLogoutModalOpen(false)}
      />
    </Flex>
  );
};

export default Sidebar;
