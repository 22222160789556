import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { form } from "../types/Forms";

interface Params {
  name: string;
  description: string;
}

export const useCreateForm = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post<form>(`${env.verifyServiceUrl}individual/form/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
