import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { IndividualCheckResponse } from "../types/Individual";

const fetch = (authHeader: string, individualCheckId: number) => () =>
  axios.get<IndividualCheckResponse>(
    `${env.verifyServiceUrl}individual/check/${individualCheckId}/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useIndividualCheck = (id: number) => {
  const { authHeader } = useAuth();
  return useQuery(["individualCheckDetails", id], fetch(authHeader, id), {
    retry: false,
    enabled: !!id,
  });
};
