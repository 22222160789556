import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  ColorProps,
  borders,
  BorderProps,
} from "styled-system";

type ParagraphProps = SpaceProps &
  TypographyProps &
  ColorProps &
  LayoutProps &
  BorderProps & {
    ellipsisOverflow?: boolean;
  };

export const Paragraph = styled.p<ParagraphProps>`
  ${({ theme: { fontSizes, colors }, ellipsisOverflow }) => `
    font-family: "Inter", sans-serif;
    font-size: ${fontSizes[2]};
    color: ${colors.blue[100]};
    word-wrap: break-word;
    word-break: break-word;

    ${
      ellipsisOverflow
        ? `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; 
    `
        : ""
    }
  `}

  ${typography}
  ${color}
  ${space}
  ${layout}
  ${borders}
`;
