import { PagedResponse } from "./../types/Common";
import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { DPO } from "../types/DPO";

const fetch = (authHeader: string) => () =>
  axios.get<PagedResponse<DPO[]>>(`${env.verifyServiceUrl}user/dpo/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useDPODetails = () => {
  const { authHeader } = useAuth();
  return useQuery(["dpoDetails"], fetch(authHeader), {
    retry: false,
  });
};
