import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { Individual } from "../types/Individual";
import { DateTime } from "luxon";
import { PagedResponse } from "../types/Common";

const fetch = async (
  authHeader: string,
  users?: number[],
  searchTerm?: string,
  filters?: any,
  page?: number,
  page_size?: number,
  sort_by?: string,
  order_by?: string
) => {
  const url = new URL(
    `${env.verifyServiceUrl}individual/individual/?mandate__assigned_user=${users}&mandate__category=Checks`
  );

  const checkTypes =
    filters.checkType ||
    "address_verification_check,adverse_media_check,pep_&_sanctions_check";

  // Manually encode the string, ensuring no double encoding
  url.searchParams.set("checks__in", checkTypes);

  if (filters.dateStarted?.[0]) {
    url.searchParams.set(
      "created_on_after",
      DateTime.fromJSDate(filters.dateStarted[0]).toFormat("yyyy-MM-dd")
    );
  }

  if (filters.dateStarted?.[1]) {
    url.searchParams.set(
      "created_on_before",
      DateTime.fromJSDate(filters.dateStarted[1]).toFormat("yyyy-MM-dd")
    );
  }

  if (searchTerm?.length > 0) {
    url.searchParams.set("search", searchTerm);
  }

  if (page) {
    url.searchParams.set("page", page.toString());
  }

  if (Number.isInteger(page_size)) {
    url.searchParams.set("page_size", page_size.toString());
  }

  if (sort_by && order_by) {
    const prefix = sort_by === "dsc" ? "-" : "";
    url.searchParams.set("ordering", `${prefix}${order_by}`);
  }

  return axios.get<PagedResponse<Individual[]>>(url.href, {
    headers: {
      Authorization: authHeader,
    },
  });
};

export const useIndividualsList = (
  users?: number[],
  searchTerm?: string,
  filters?: any,
  page?: number,
  page_size?: number,
  sort_by?: string,
  order_by?: string
) => {
  const { authHeader } = useAuth();
  return useQuery(
    [
      "individualList",
      users,
      searchTerm,
      filters,
      page,
      page_size,
      sort_by,
      order_by,
    ],
    () =>
      fetch(
        authHeader,
        users,
        searchTerm,
        filters,
        page,
        page_size,
        sort_by,
        order_by
      ),
    {
      keepPreviousData: true,
      retry: true,
    }
  );
};
