import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useMandates } from "../../data/useMandates";
import { ApplicationCard } from "../ApplicationCard";
import { Badge } from "../Badge";
import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { H2 } from "../Heading";
import { MandateStatusColours } from "../layoutConfig";
import { Loader } from "../Loader";
import { Paragraph } from "../Paragraph";

type ApplicationsListProps = {
  name: string;
  searchTerm: string;
  statusId: number;
  filterUserIds?: number[];
  statusName: string;
  filters: any;
};

export const ApplicationsList = (props: ApplicationsListProps) => {
  const { name, searchTerm, statusId, filterUserIds, statusName, filters } = props;
  const { ref, inView } = useInView();

  const badgeColour = MandateStatusColours[statusName];

  const {
    data: mandates,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useMandates(statusId, filterUserIds, searchTerm, filters);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <Box minWidth={288}>
      <H2 fontSize="3" my="0" display="flex" alignItems="center">
        <Badge bg={badgeColour || "gray.60"} mr={1}>
          {mandates?.pages?.[0]?.data?.count}
        </Badge>
        <div>{name}</div>
      </H2>
      <Paragraph my="0" color="gray.80" mb="3">
        Individuals - {mandates?.pages?.[0]?.data?.individual_count}
      </Paragraph>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {mandates?.pages?.[0]?.data?.count === 0 && (
        <Paragraph mt={5} color="gray.40" textAlign="center">
          No applications
        </Paragraph>
      )}

      {mandates?.pages?.map((page) => (
        <React.Fragment key={page.data.next}>
          {page.data.results.map((mandate) => (
            <ApplicationCard {...mandate} key={mandate.id} />
          ))}
        </React.Fragment>
      ))}
      <Flex justifyContent="center">
        {hasNextPage && (
          <Button
            ref={ref}
            variant="secondary"
            color="blue.primary"
            onClick={() => fetchNextPage()}
            isDisabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? "Loading more..." : "Load More"}
          </Button>
        )}
      </Flex>
    </Box>
  );
};
