import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { PagedResponse } from "../types/Common";
import { env } from "../utils/env";
import { Mandate } from "../types/Mandate";
import { DateTime } from "luxon";

const fetch =
  (
    authHeader: string,
    status: number,
    users?: number[],
    searchTerm?: string,
    filters?: any
  ) =>
  ({ pageParam = 1 }) => {
    const url = new URL(
      `${env.verifyServiceUrl}individual/mandate/?page=${pageParam}&status=${status}&assigned_user=${users}&category=Application`
    );

    if (Number.isInteger(filters.consultant)) {
      url.searchParams.set("consultant", filters.consultant);
    }

    if (Number.isInteger(filters.applicationType)) {
      url.searchParams.set("mandate_type", filters.applicationType);
    }

    if (filters.dateStarted?.[0]) {
      url.searchParams.set(
        "from_date",
        DateTime.fromJSDate(filters.dateStarted[0]).toFormat("yyyy-MM-dd")
      );
    }

    if (filters.dateStarted?.[1]) {
      url.searchParams.set(
        "to_date",
        DateTime.fromJSDate(filters.dateStarted[1]).toFormat("yyyy-MM-dd")
      );
    }

    url.searchParams.set("archived", filters.includeArchived);

    if (searchTerm?.length > 0) {
      url.searchParams.set("search", searchTerm);
    }

    return axios.get<PagedResponse<Mandate[]>>(url.href, {
      headers: {
        Authorization: authHeader,
      },
    });
  };

export const useMandates = (
  status: number,
  users?: number[],
  searchTerm?: string,
  filters?: any
) => {
  const { authHeader } = useAuth();
  return useInfiniteQuery(
    ["mandates", status, users, searchTerm, filters],
    fetch(authHeader, status, users, searchTerm, filters),
    {
      getPreviousPageParam: (firstPage) =>
        new URLSearchParams(firstPage.data.previous).get("page") ?? undefined,
      getNextPageParam: (lastPage) =>
        new URLSearchParams(lastPage.data.next).get("page") ?? undefined,
    }
  );
};

const fetchTableData = async (
  authHeader: string,
  users?: number[],
  searchTerm?: string,
  filters?: any,
  page?: number,
  page_size?: number,
  sort_by?: string,
  order_by?: string
) => {
  const url = new URL(
    `${env.verifyServiceUrl}individual/mandate/?status=${filters.status}&assigned_user=${users}&category=Application`
  );

  if (Number.isInteger(filters.consultant)) {
    url.searchParams.set("consultant", filters.consultant);
  }

  if (Number.isInteger(filters.applicationType)) {
    url.searchParams.set("mandate_type", filters.applicationType);
  }

  if (filters.dateStarted?.[0]) {
    url.searchParams.set(
      "from_date",
      DateTime.fromJSDate(filters.dateStarted[0]).toFormat("yyyy-MM-dd")
    );
  }

  if (filters.dateStarted?.[1]) {
    url.searchParams.set(
      "to_date",
      DateTime.fromJSDate(filters.dateStarted[1]).toFormat("yyyy-MM-dd")
    );
  }

  url.searchParams.set("archived", filters.includeArchived);

  if (searchTerm?.length > 0) {
    url.searchParams.set("search", searchTerm);
  }

  if (page) {
    url.searchParams.set("page", page.toString());
  }

  if (Number.isInteger(page_size)) {
    url.searchParams.set("page_size", page_size.toString());
  }

  if (sort_by) {
    url.searchParams.set("sort_by", sort_by);
  }

  if (order_by) {
    url.searchParams.set("order_by", order_by);
  }

  return axios.get<PagedResponse<Mandate[]>>(url.href, {
    headers: {
      Authorization: authHeader,
    },
  });
};

export const useTableMandates = (
  users?: number[],
  searchTerm?: string,
  filters?: any,
  page?: number,
  page_size?: number,
  sort_by?: string,
  order_by?: string
) => {
  const { authHeader } = useAuth();
  return useQuery(
    [
      "mandates",
      users,
      searchTerm,
      filters,
      page,
      page_size,
      sort_by,
      order_by,
    ],
    () =>
      fetchTableData(
        authHeader,
        users,
        searchTerm,
        filters,
        page,
        page_size,
        sort_by,
        order_by
      ),
    {
      keepPreviousData: true,
      retry: true,
    }
  );
};
