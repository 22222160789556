import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { CompanyType } from "../types/CompanyType";
import { env } from "../utils/env";
import { companyTypesFallback } from "./fallbackPayloads/companyTypes";
import { asAxiosResponse } from "./fallbackPayloads/utilities";

const fetchCompanyTypes = (authHeader: string) => () =>
  axios.get<CompanyType[]>(`${env.verifyServiceUrl}common/company_type_list/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useCompanyTypes = () => {
  const { authHeader } = useAuth();
  return useQuery(["companyTypes"], fetchCompanyTypes(authHeader), {
    initialData: () => asAxiosResponse(companyTypesFallback),
    retry: false
  });
};
