import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { axios } from "./axios";

export const useDeleteUser = () => {
  const { authHeader } = useAuth();

  return useMutation(({ id }: { id: number }) => {
    return axios.delete(`${env.verifyServiceUrl}user/user/${id}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
