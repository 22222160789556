import { H2 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Flex } from "../../components/Flex";
import { Button } from "../../components/Button";
import { IndividualCard } from "../../components/IndividualCard";
import { FaPlus } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { useIndividuals } from "../../data/useIndividuals";
import { useDeleteIndividual } from "../../data/useDeleteIndividual";
import { Individual } from "../../types/Individual";
import { queryClient } from "../../queryClient";
import { NewApplicationFrame } from "./NewApplicationFrame";
import { RouteLink } from "../../components/RouteLink";

const Header = () => {
  return (
    <>
      <H2>Add Individuals</H2>
      <Paragraph>
        Please add the individuals you would like to complete verifications.
      </Paragraph>
    </>
  );
};

export const IndividualManager = ({
  mandateId,
  onClickNext,
}: {
  mandateId?: number;
  onClickNext: () => void;
}) => {
  const navigate = useNavigate();
  let location = useLocation();

  const { data: individuals } = useIndividuals(
    location.state && !location?.state?.checks
      ? (location.state as number)
      : mandateId
  );

  const { mutate: deleteIndividual } = useDeleteIndividual();

  const handleOnRemoveClick = (id: number) => {
    deleteIndividual(
      { id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["individuals", mandateId]);
        },
      }
    );
  };
  const handleOnEditClick = (id: number) => {
    navigate(`/applications/${mandateId}/individuals/new`, {
      state: { checks: true },
    });
  };

  return (
    <NewApplicationFrame step="individuals" mandateId={mandateId}>
      <Header />
      <Flex overflowY="auto" flexDirection="column" maxHeight="60vh" gap="15">
        {individuals?.data?.results?.length === 0 && (
          <Flex minHeight="30vh" justifyContent="center" alignItems="center">
            <Paragraph my={5} color="gray.50">
              No individuals yet, <RouteLink to="new">add one now.</RouteLink>
            </Paragraph>
          </Flex>
        )}
        {individuals?.data?.results?.map(
          (
            {
              first_name,
              middle_name,
              last_name,
              date_of_birth,
              email,
              id,
              is_check_individual,
            }: Individual,
            index
          ) => (
            <IndividualCard
              key={id}
              firstName={first_name}
              middleName={middle_name}
              lastName={last_name}
              dateOfBirth={date_of_birth}
              email={email}
              onRemoveClick={() => handleOnRemoveClick(id)}
              onEditClick={() => handleOnEditClick(id)}
              hasRemoveButton={!is_check_individual}
              hasEditButton={is_check_individual}
            />
          )
        )}
      </Flex>
      <Flex justifyContent="space-between">
        <Button
          size="large"
          mt="3"
          mb="0"
          variant="secondary"
          Icon={FaPlus}
          iconPosition="left"
          onClick={() => navigate("new")}
        >
          Add individual
        </Button>
        <Button
          size="large"
          mt="3"
          mb="0"
          onClick={() => onClickNext()}
          isDisabled={individuals?.data?.count === 0}
        >
          Next
        </Button>
      </Flex>
    </NewApplicationFrame>
  );
};
