import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { Individual } from "../types/Individual";
import { DateTime } from "luxon";
import { PagedResponse } from "../types/Common";
import { Monitoring } from "../types/Monitoring";

const fetch = async (
  authHeader: string,
  status?: string,
  searchTerm?: string,
  filters?: any,
  page?: number,
  page_size?: number,
  sort_by?: string,
  order_by?: string
) => {
  const url = new URL(
    `${env.verifyServiceUrl}individual/monitoring/`
  );

  const statusTypes =
  status;

  // Manually encode the string, ensuring no double encoding
  if( filters.status != null || filters.status != undefined)  {
    url.searchParams.set("status", filters.status);
  }
  if (searchTerm?.length > 0) {
    url.searchParams.set("search", searchTerm);
  }
  if (filters.created_on_date?.[0]) {
    url.searchParams.set(
      "created_on_after",
      DateTime.fromJSDate(filters.created_on_date[0]).toFormat("yyyy-MM-dd")
    );
  }

  if (filters.created_on_date?.[1]) {
    url.searchParams.set(
      "created_on_before",
      DateTime.fromJSDate(filters.created_on_date[1]).toFormat("yyyy-MM-dd")
    );
  }

  if (filters.last_activity_date?.[0]) {
    url.searchParams.set(
      "last_activity_after",
      DateTime.fromJSDate(filters.last_activity_date[0]).toFormat("yyyy-MM-dd")
    );
  }
  if (filters.last_activity_date?.[1]) {
    url.searchParams.set(
      "last_activity_before",
      DateTime.fromJSDate(filters.last_activity_date[1]).toFormat("yyyy-MM-dd")
    );
  }

  if (page) {
    url.searchParams.set("page", page.toString());
  }

  if (Number.isInteger(page_size)) {
    url.searchParams.set("page_size", page_size.toString());
  }

  if (sort_by && order_by) {
    const prefix = sort_by === "dsc" ? "-" : "";
    url.searchParams.set("ordering", `${prefix}${order_by}`);
  }

  return axios.get<PagedResponse<Monitoring[]>>(url.href, {
    headers: {
      Authorization: authHeader,
    },
  });
};

export const useMonitoringList = (
  status?: string,
  searchTerm?: string,
  filters?: any,
  page?: number,
  page_size?: number,
  sort_by?: string,
  order_by?: string
) => {
  const { authHeader } = useAuth();
  return useQuery(
    [
      "monitoringList",
      status,
      searchTerm,
      filters,
      page,
      page_size,
      sort_by,
      order_by,
    ],
    () =>
      fetch(
        authHeader,
        status,
        searchTerm,
        filters,
        page,
        page_size,
        sort_by,
        order_by
      ),
    {
      keepPreviousData: true,
      retry: true,
    }
  );
};
