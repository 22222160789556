import styled from "styled-components";
import {
  space,
  color,
  layout,
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  position,
  PositionProps,
} from "styled-system";

type InitialProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  PositionProps & {
    firstName: string;
    lastName: string;
    onClick?: () => void;
    colour?: string;
    cursor?: string;
  };

const Wrapper = styled.div<any>`
  ${({ theme: { colors, fontSizes }, colour, size }) => `
        background: ${colour || colors.gray[10]};
        color: ${colors.blue[100]};
        font-family: "Inter", sans-serif;
        font-weight: bold;
        min-width: ${size}px;
        height: ${size}px;
        font-size: ${fontSizes[0]};
        position: relative;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    `}

  ${({ cursor }) => `
        ${cursor ? `cursor: ${cursor};` : ""}
    `}

  ${space}
    ${color}
    ${space}
    ${layout}
    ${position}
`;

export const ProfileOrb = (props: InitialProps) => {
  const {
    firstName,
    lastName,
    onClick,
    colour,
    size = 26,
    ...styleProps
  } = props;

  return (
    <Wrapper {...styleProps} onClick={onClick} colour={colour} size={size}>
      {firstName?.charAt(0)?.toUpperCase()}
      {lastName?.charAt(0)?.toUpperCase()}
    </Wrapper>
  );
};
