import { css } from "styled-components";

export const fadeIn = css`
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const fadeOut = css`
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const spin = css`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
