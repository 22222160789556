import { useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { IconButton } from "../../components/IconButton";
import { AddEditUserModal } from "../../components/Modals/AddEditUserModal";
import { ConfirmModal } from "../../components/Modals/ConfirmModal";
import { Paragraph } from "../../components/Paragraph";
import { Table } from "../../components/Table";
import { TableCell } from "../../components/Table/TableCell";
import { TableRow } from "../../components/Table/TableRow";
import { Toggle } from "../../components/Toggle";
import { useAuth } from "../../context/AuthContext";
import { useDeleteUser } from "../../data/useDeleteUser";
import { useUpdateUserDetails } from "../../data/useUpdateUserDetails";
import { useUsers } from "../../data/useUsers";
import { queryClient } from "../../queryClient";
import { User } from "../../types/User";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import deleteIcon from "../../assets/settings/delete-red.svg";
import editIcon from "../../assets/settings/edit.svg";
import { Image } from "../../components/Image";

const SettingsUsers = () => {
  // TODO might need to paginate, but out of scope for now.
  const { userDetails } = useAuth();
  const { data: users, isSuccess } = useUsers();
  const hasData = isSuccess && users?.data?.count > 0;

  // If we want to re-add being able to toggle permissions from the table uncomment this and re-add select dropdown
  // const { data: permissions } = usePermissionGroups();

  // const permissionGroupOptions = permissions?.data?.results?.map(({ id, name }) => ({
  //   label: name,
  //   value: id,
  // })) as Option[];

  const { mutate: updateUser } = useUpdateUserDetails();
  const { mutate: deleteUser } = useDeleteUser();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleAddUser = () => {
    setIsEditModalOpen(true);
    setSelectedUserId(null);
  };

  const handleEditUser = (id) => () => {
    setIsEditModalOpen(true);
    setSelectedUserId(id);
  };

  const handleDeleteUser = (id) => () => {
    setIsConfirmDeleteOpen(true);
    setSelectedUserId(id);
  };

  const optimisticUpdate = (
    userId: number,
    fieldName: keyof User,
    value: any
  ) => {
    queryClient.setQueryData(["users"], (old: any) => {
      const newUsers = old.data.results.map((user) => {
        if (user.id === userId) {
          return { ...user, [fieldName]: value };
        }
        return user;
      });

      return {
        ...old,
        data: {
          ...old.data,
          results: newUsers,
        },
      };
    });
  };

  // const handleUpdateUserGroup = (userId: number, group: PermissionGroup) => {
  //   optimisticUpdate(userId, "groups", [group]);

  //   updateUser(
  //     {
  //       id: userId,
  //       groups: [group.id],
  //     },
  //     {
  //       onSuccess: () => {
  //         queryClient.invalidateQueries(["users"]);
  //         queryClient.invalidateQueries(["userDetails", userId]);
  //       },
  //       onError: (err: any) => {
  //         displayError(
  //           collapseErrors(extractErrors(err)) ||
  //           "A problem occurred while updating the user."
  //         );
  //         logError(err);
  //       },
  //     }
  //   );
  // };

  const handleUpdateUserActive = (userId: number, isActive: boolean) => {
    optimisticUpdate(userId, "is_active", isActive);

    updateUser(
      {
        id: userId,
        is_active: isActive,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["users"]);
          queryClient.invalidateQueries(["userDetails", userId]);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while updating the user.");
          // displayError(
          //   collapseErrors(extractErrors(err)) ||
          //     "A problem occurred while updating the user."
          // );
          // logError(err);
        },
      }
    );
  };

  const confirmDeleteUser = () => {
    setIsConfirmDeleteOpen(false);
    deleteUser(
      { id: selectedUserId },
      {
        onSuccess: () => {
          notify("User Deleted", {
            type: "success",
          });
          queryClient.invalidateQueries(["users"]);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while updating the user.");
          // displayError(
          //   collapseErrors(extractErrors(err)) ||
          //     "A problem occurred while updating the user."
          // );
          // logError(err);
        },
        onSettled: () => {
          setSelectedUserId(null);
        },
      }
    );
  };

  return (
    <Box bg="white" borderRadius={0} p={3} mb={3}>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>Users</H2>
        <Button onClick={handleAddUser} Icon={FaPlus} iconSize="16px">
          Invite User
        </Button>
      </Flex>

      {!hasData && <Paragraph textAlign="center">No users</Paragraph>}

      {hasData && (
        <Box mt={5}>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell isHeading>User name</TableCell>
                <TableCell isHeading>Email Address</TableCell>
                <TableCell isHeading>Job title</TableCell>
                <TableCell isHeading>Permission</TableCell>
                <TableCell isHeading>Status</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
              {users?.data.results.map(
                ({
                  first_name,
                  last_name,
                  email,
                  id,
                  job_title,
                  groups,
                  is_active,
                }) => (
                  <TableRow key={id}>
                    <TableCell>
                      {first_name} {last_name}
                    </TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{job_title}</TableCell>
                    <TableCell>{groups[0]?.name ?? "-"}</TableCell>
                    <TableCell >
                      {id !== userDetails?.id && (
                        <Toggle
                          isChecked={is_active}
                          onChange={(e) =>
                            handleUpdateUserActive(id, e.target.checked)
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell >
                    <Image
                        src={editIcon}
                        alt="edit icon"
                        height="24px"
                        width="24px"
                        marginRight={"4px"}
                        onClick={handleEditUser(id)}
                        style={{cursor: "pointer"}}
                      />
                      {/* <IconButton
                        onClick={handleEditUser(id)}
                        Type={FaEdit}
                        size="23px"
                      /> */}
                    </TableCell>
                    <TableCell >
                      {id !== userDetails?.id && (
                        <Image
                        src={deleteIcon}
                        alt="delete icon"
                        height="24px"
                        width="24px"
                        marginRight={"4px"}
                        onClick={handleDeleteUser(id)}
                        style={{cursor: "pointer"}}
                      />
                        // <IconButton
                        //   onClick={handleDeleteUser(id)}
                        //   Type={FaTrash}
                        //   size="23px"
                        // />
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Box>
      )}
      <AddEditUserModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        userId={selectedUserId}
      />
      <ConfirmModal
        isOpen={isConfirmDeleteOpen}
        onRequestClose={() => {
          setSelectedUserId(null);
          setIsConfirmDeleteOpen(false);
        }}
        handleConfirm={confirmDeleteUser}
        content="This action cannot be reversed"
      />
    </Box>
  );
};

export default SettingsUsers;
