import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  id: number;
}

export const useRunChecks = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(
      `${env.verifyServiceUrl}individual/individual_v2/${params.id}/run_checks/`,
      {},
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
