import axios from "axios";
import { env } from "../utils/env";
import { SplashScreenList } from "../types/SplashScreenList";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";

interface SplashScreenResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: SplashScreenList[];
}

const fetch = (authHeader: string) => () =>
  axios.get<SplashScreenResponse>(
    `${env.verifyServiceUrl}/individual/splash_screen/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useSplashScreen = () => {
  const { authHeader } = useAuth();
  return useQuery(["splashScreenList"], fetch(authHeader), {
    enabled: true,
    retry: false,
    select: (response) => response.data.results[0], // Select the results array
  });
};
