import { capitalise } from "./string";

type ServerErrorList = {
  [K in string]: string[];
};

export const extractErrors = (errorRes: any): { [key: string]: string[] } => {
  return errorRes?.response?.data;
};

export const collapseErrors = (errors: ServerErrorList, includeErrorKeys?: boolean): string => {
  let str = "";

  Object.keys(errors).forEach((key) => {
    str = str + `${includeErrorKeys ? `${capitalise(key)}: ` : ''}${errors[key].join(",")}`;
  });

  return str;
};
