import { BoxProps } from "../Box"
import { Flex } from "../Flex"

export const FormRow = ({ children, ...props }: BoxProps & { children: any }) => {
  const rowProps: any = {
    flexWrap: "wrap",
    gap: "16",
  }

  return (
    <Flex {...rowProps} {...props}>
      {children}
    </Flex>
  )
}
