import styled from "styled-components";
import { cloneElement, Children } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon";
import { Paragraph } from "../Paragraph";
import { CgChevronRight } from "react-icons/cg";
import { Box } from "../Box";
import { H1 } from "../Heading";

type BreadcrumbSectionProps = {
  children: any;
  active?: boolean;
  disabled?: boolean;
  linkTo?: string;
  isHeading?: boolean;
};

type BreadcrumbProps = {
  children: any;
};

type CrumbProps = {
  isHeading?: boolean;
  iconDivider?: any;
  children?: any;
  linkTo?: string;
  isActive?: boolean;
};

type LinkProps = React.ComponentProps<typeof Link>;

type CustomLinkProps = LinkProps & { isHeading?: boolean };

type StyledContainerProps = { isHeading?: boolean };

const StyledNav = styled.nav`
  margin: 0px;
  font-weight: 400;
`;

const StyledOl = styled.ol`
  ${({ theme: { fontSizes } }) => `
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[1]};
    `}
`;

export const StyledLi = styled.li`
  user-select: none;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomLink: React.FC<CustomLinkProps> = ({ isHeading, ...props }) => (
  <Link {...props} />
);

export const StyledLink = styled(CustomLink)<{ isHeading?: boolean }>`
  ${({ theme: { colors, fontSizes }, isHeading }) => `
        text-decoration: none;
        color: ${isHeading ? colors.blue[100] : colors.gray[60]};
        transition: color .1s ease;
        ${isHeading && `font-size: ${fontSizes[4]};`}

        &:hover {
          color: ${colors.blue.primary};
        }
    `}
`;

const StyledContainer = styled.div<StyledContainerProps>`
  ${({ theme: { colors, fontSizes }, isHeading }) => `
        text-decoration: none;
        color: ${isHeading ? colors.blue[100] : colors.gray[60]};
        ${isHeading && `font-size: ${fontSizes[4]}`}
    `}
`;

export const BreadcrumbDivider = () => (
  <Icon
    Type={CgChevronRight}
    aria-hidden="true"
    display="flex"
    justifyContent="center"
    alignItems="center"
  />
);

export const Crumb = ({
  linkTo,
  isHeading = false,
  iconDivider,
  children,
  isActive,
}: CrumbProps) => {
  return (
    <>
      {isActive ? (
        <StyledLi>
          <Paragraph fontSize={1} color="blue.primary">
            {children}
          </Paragraph>
          {iconDivider}
        </StyledLi>
      ) : (
        <StyledLi>
          <StyledContainer isHeading={isHeading}>{children}</StyledContainer>
          {iconDivider}
        </StyledLi>
      )}
    </>
  );
};

export const BreadcrumbSection = ({
  children,
  active,
  disabled,
  linkTo,
  isHeading = true,
}: BreadcrumbSectionProps) => {
  const iconDivider = active ? null : isHeading ? (
    <Box color="blue.100" fontSize="4" ml="1">
      /
    </Box>
  ) : (
    <Icon
      Type={CgChevronRight}
      aria-hidden="true"
      display="flex"
      justifyContent="center"
      alignItems="center"
    />
  );

  let crumb = (
    <StyledLi>
      <StyledLink to={linkTo} isHeading={isHeading}>
        {children}
      </StyledLink>
      {iconDivider}
    </StyledLi>
  );

  if (active || disabled) {
    crumb = (
      <StyledLi>
        {isHeading ? (
          <H1 color="blue.primary" my="0px" fontSize="4">
            {children}
          </H1>
        ) : (
          <Paragraph fontSize={1} color="blue.primary">
            {children}
          </Paragraph>
        )}
        {iconDivider}
      </StyledLi>
    );
  }

  return crumb;
};

export const Breadcrumb = ({ children }: BreadcrumbProps) => {
  let crumbs = Children.toArray(children);

  crumbs = crumbs.map((crumb, index) => {
    const active = index === crumbs.length - 1;

    return cloneElement(crumb as any, { active });
  });

  return (
    <>
      <StyledNav>
        <StyledOl>{crumbs}</StyledOl>
      </StyledNav>
    </>
  );
};

export const StaticBreadcrumb = ({ children }: BreadcrumbProps) => {
  return (
    <>
      <StyledNav>
        <StyledOl>{children}</StyledOl>
      </StyledNav>
    </>
  );
};

Breadcrumb.Section = BreadcrumbSection;
