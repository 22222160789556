import styled from "styled-components";

const StyledTable = styled.table`
  ${({ theme: {fontFamily} }) => `
        width: 100%;
        font-family: ${fontFamily};
    `}
`;

export const Table = (props: any) => {
  const { children } = props;

  return <StyledTable>{children}</StyledTable>;
};
