import { Formik, Form } from "formik";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import { emailVerificationValidation } from "../../utils/validationSchema";

interface VerificationStepProps {
  onResendCodeClick: () => void;
  onVerifyButtonClick: (code: string) => void;
  hasCodeBeenResent: boolean;
  isLoading: boolean;
}

export const EmailVerificationStep = ({
  isLoading,
  onResendCodeClick,
  hasCodeBeenResent,
  onVerifyButtonClick,
}: VerificationStepProps) => {
  return (
    <>
      <Formik
        initialValues={{
          code: "",
        }}
        validationSchema={emailVerificationValidation}
        onSubmit={({ code }) => {
          onVerifyButtonClick(code);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Box>
              <H1 color="darkBlue">Enter email verification code</H1>
              <Paragraph color="darkBlue">
                We need to make sure the email address belongs to you. You
                should receive an email with a six digit verification code.
              </Paragraph>
              <Input
                onChange={props.handleChange}
                value={props.values.code}
                hasError={Boolean(props.errors.code)}
                errorMessage={props.errors.code}
                placeholder="Enter code"
                mt="4"
                label="Code"
                name="code"
              />
              <Flex justifyContent="flex-end">
                <Button
                  isDisabled={hasCodeBeenResent}
                  variant="text"
                  onClick={onResendCodeClick}
                  mt="1"
                >
                  {hasCodeBeenResent ? "Code Sent" : "Resend Code"}
                </Button>
              </Flex>
            </Box>
            <Box />
            <Box>
              <Button
                type="submit"
                size="large"
                width="100%"
                mt="2"
                mb="0"
                isDisabled={isLoading}
              >
                Get Started
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Box />
    </>
  );
};
