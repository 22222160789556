import { Flex } from "../components/Flex";
import { Box } from "../components/Box";
import { Image } from "../components/Image";
import { Input } from "../components/Input";
import { useState } from "react";
import { H1 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Button } from "../components/Button";
import forgotPasswordSvg from "../assets/folder.svg";
import tillerVerifySvg from "../assets/verify-tiller.svg";
import lock from "../assets/lock.svg";
import { useResetPassword } from "../data/useResetPassword";
import {
  showError,
} from "../utils/error-handling";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";
import { Form, Formik } from "formik";
import { addEmailValidation } from "../utils/validationSchema";


const ResetPassword = () => {
  // const [emailAddress, setEmailAddress] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const navigate = useNavigate();

  const { mutate: resetPassword, isLoading } = useResetPassword();

  const handleReset = (emailAddress) => {
    resetPassword(
      {
        email: emailAddress,
      },
      {
        onError: (err: any) => {
          showError(err, "A problem occurred while resetting your password.");
        },
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) setHasSubmitted(true);
        },
      }
    );
  };

  const step1 = (
    <Formik
      initialValues={{
        emailAddress: "",
      }}
      validationSchema={addEmailValidation}
      onSubmit={({ emailAddress }) => {
        handleReset(emailAddress);
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Box>
            <H1 color="darkBlue">Reset password</H1>
            <Paragraph color="darkBlue">
              Enter your email to reset your password
            </Paragraph>
            <Input
              onChange={props.handleChange}
              value={props.values.emailAddress}
              placeholder="Enter your email address"
              mt="4"
              label="Email Address"
              name="emailAddress"
              hasError={Boolean(props.errors?.emailAddress)}
              errorMessage={props.errors?.emailAddress}
              isRequired
            />
            <Button
              isDisabled={
                !props.dirty || (props.dirty && !props.isValid) || isLoading
              }
              type="submit"
              size="large"
              width="100%"
              mt="3"
            >
              Reset
            </Button>
            <Button
              isDisabled={isLoading}
              onClick={() => navigate(ROUTE.ROOT)}
              size="large"
              variant="text"
              width="100%"
              mt="3"
            >
              Back
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );

  const step2 = (
    <Box>
      <H1 color="darkBlue">Check your mail!</H1>
      <Paragraph color="darkBlue">Instructions sent! Check your mail</Paragraph>
      <Link to={ROUTE.LOGIN}>
        <Button size="large" width="100%" mt="3">
          Go back to website
        </Button>
      </Link>
    </Box>
  );

  return (
    <Flex height="100vh">
      <Flex
        width="900px"
        justifyContent="space-between"
        flexDirection="column"
        p="60px"
      >
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="80px"
          width="100px"
        />
        {hasSubmitted ? step2 : step1}
        <Box />
      </Flex>
      <Flex
        display={{ _: "none", lg: "flex" }}
        bg={"background"}
        height="100vh"
        width="100%"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
      >
        <Box width="55%">
          <Image src={lock} height="80px" width="100px" mb="60px" />
          <H1 color="darkBlue">
            Forgot your password? No problem, we got you!
          </H1>
          <Paragraph color="gray.50" fontSize="3">
            Type in your email and we will send you instructions for resetting
            your password.
          </Paragraph>
        </Box>
        <Image
          src={forgotPasswordSvg}
          alt="folder"
          display="block"
          maxWidth={["200px", "250px", "300px", "350px"]}
        />
      </Flex>
    </Flex>
  );
};

export default ResetPassword;
