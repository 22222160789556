import styled, { useTheme } from "styled-components";
import {
  space,
  color,
  layout,
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  position,
  PositionProps,
  flexbox,
} from "styled-system";

import themeGet from "@styled-system/theme-get";
import { useEffect, useState, useCallback } from "react";

type IconProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  PositionProps & {
    /** Must be a react-icon component instance. */
    Type: any;
    /** Must be a styled-system color key. */
    color?: string;
    hoverColor?: string;
    size?: string;
    cursor?: string;
    onClick?: () => void;
  };

const Wrapper = styled.div<any>`
  ${space}
  ${color}
    ${space}
    ${layout}
    ${position}
    ${flexbox}

    ${({ cursor }) => `
        ${cursor ? `cursor: ${cursor};` : ""}
    `}
`;

export const Icon = (props: IconProps) => {
  const { Type, color, hoverColor, size, ...styleProps } = props;

  const theme = useTheme() as any;
  const getColor = useCallback(
    (key?: string) => themeGet(`colors.${key}`, "grey")({ theme }),
    [theme]
  );

  const [iconColor, setIconColor] = useState(() => getColor(color));

  useEffect(() => {
    setIconColor(getColor(color));
  }, [color, getColor]);

  const handleMouseEnter = () => {
    if (hoverColor) {
      setIconColor(getColor(hoverColor));
    }
  };

  const handleMouseLeave = () => {
    if (hoverColor) {
      setIconColor(getColor(color));
    }
  };

  return (
    <Wrapper
      {...styleProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Type size={size} color={iconColor} />
    </Wrapper>
  );
};
