import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  name: string;
  reference: string;
  mandate_type: number;
  consultant: number;
  category: string;
}
export const useCreateMandate = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(`${env.verifyServiceUrl}individual/mandate/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
