import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Modal } from "../Modal";

export const LogoutConfirmModal = (props: any) => {
  const [doubleClick, setDoubleClick] = useState(false);
  const { logout } = useAuth();

  const handleLogout = () => {
    setDoubleClick(true);
    logout();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onRequestClose}
      title="Logout?"
      content="You will be brought to the sign-in screen."
      primaryButtonText="Logout"
      primaryButtonVariant="danger"
      secondaryButtonText="Cancel"
      hasSecondaryButton
      onModalConfirmClick={handleLogout}
      doubleClick={doubleClick}
    />
  );
};
