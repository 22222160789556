import styled from "styled-components";
import { Icon } from "../Icon";

const Base = styled.div<any>`
  ${({ theme: { colors, radii, space }, isDestructive, isDisabled }) => `
        padding: ${space[2]} ${space[4]};
        border-radius: ${radii[0]};
        ${isDestructive ? `color: ${colors.red.primary};` : `${colors.gray[60]}`};
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: ${colors.background};
        }

        ${isDisabled ? `
          cursor: not-allowed;
          opacity: 0.5;
        ` : ''};
    `}
`;

interface Props {
  isDestructive?: boolean;
  isDisabled?: boolean;
  children?: any;
  IconType?: any;
  onClick?: () => void;
}

export const IconButtonDropdownOption = (props: Props) => {
  const {
    children,
    IconType,
    isDestructive,
    isDisabled,
    onClick
  } = props;

  return (
    <Base onClick={onClick} isDestructive={isDestructive} isDisabled={isDisabled}>
      <Icon
        size="24px"
        mr={2}
        Type={IconType}
        color={isDestructive ? "red.primary" : "gray.60"}
      />
      {children}
    </Base>
  );
};
