import styled from "styled-components";
import {
  space,
  layout,
  flexbox,
  background,
  border,
  position,
  SpaceProps,
  BackgroundProps,
  LayoutProps,
  FlexboxProps,
  BorderProps,
  PositionProps,
} from "styled-system";

export type ImageProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps;

export const Image = styled.img<ImageProps>`
  ${space}
  ${layout}
    ${flexbox}
    ${background}
    ${border}
    ${position}
`;
