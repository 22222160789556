import styled from "styled-components";
import { Box, BoxProps } from "../Box";

const LoaderContainer = styled(Box)`
  height: 5px;
  width: 100%;
  background-color: #e3e7ee;
`;

const LoaderIndicator = styled.div<{
  progress: number;
  variant: "green" | "blue";
}>`
  ${({ theme: { colors }, variant, progress }) => `
    height: 100%;
    width: ${progress ? `${progress}%` : `0%`};
    background-color: ${
      variant === "green" ? colors.green.primary : colors.blue.primary
    };
  `}
`;

export const ProgressBar = ({
  progress,
  variant = "blue",
  ...styleProps
}: { progress: number; variant?: "green" | "blue" } & BoxProps) => {
  return (
    <LoaderContainer {...styleProps}>
      <LoaderIndicator variant={variant} progress={progress}></LoaderIndicator>
    </LoaderContainer>
  );
};
