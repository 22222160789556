import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { CheckAddress } from "../types/CheckAddress";

interface Params {
  id: number;
  address: CheckAddress
}

export const useAddAddress = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(
      `${env.verifyServiceUrl}individual/individual_v2/${params.id}/add_address/`,
      params.address,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
