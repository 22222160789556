import styled from "styled-components";
import { space } from "styled-system";
import { Box, BoxProps } from "../Box";
import { Icon } from "../Icon";
import { Paragraph } from "../Paragraph";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { useState } from "react";
import { Label } from "../Label";

const noop = (e) => {
  e.preventDefault();
  return false;
};

const InputBase = styled.input`
  ${({
    hasError,
    buttonSize,
    disabled,
    theme: { radii, colors, space, fontSizes },
  }: any) => `
        height: ${buttonSize === "small" ? "40px" : "60px"};
        width: 100%;
        box-sizing: border-box;
        border-radius: ${radii[0]};
        border: 2px solid ${hasError ? colors.red.primary : colors.gray[30]};
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[2]};
        padding-left: ${space[2]};
        transition: all .1s ease;

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }

        &[name~="password"]{
          -webkit-user-select: auto;
          user-select: auto;
          -webkit-user-drag: none;
        }

        ${
          disabled
            ? `
          cursor: not-allowed;
        `
            : ""
        }

        ::placeholder {
            color: ${colors.gray[50]};
        }

        :hover {
            ${
              hasError
                ? ""
                : `
                border: 2px solid ${colors.gray[40]};
            `
            }
        }
    `}

  ${space}
`;

export interface InputProps extends BoxProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "password" | "search" | "number";
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  label?: string;
  subLabel?: string;
  name?: string;
  size?: "small" | "regular";
  color?: string;
  min?: number;
  max?: number;
  isDisabled?: boolean;
  isRequired?: boolean;
  onCut?: boolean;
  onDrag?: boolean;
  id?: string;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const Input = (props: InputProps) => {
  const {
    onChange,
    type = "text",
    id,
    onKeyDown,
    onBlur,
    label,
    value,
    errorMessage,
    hasError,
    size = "regular",
    placeholder,
    name,
    pl,
    min,
    max,
    isDisabled,
    isRequired,
    onCut,
    onDrag,
    subLabel,
    ...styleProps
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [internalType, setInternalType] = useState(type);

  const inputProps = {
    errorMessage,
    hasError,
    buttonSize: size,
    type: internalType,
    placeholder,
    name,
    value,
    pl,
    min,
    max,
    disabled: isDisabled,
    onCut,
    onDrag,
    id,
    onBlur,
    onKeyDown, // Ensure onKeyDown is passed here
  } as any;

  if (type === "search") {
    inputProps.pl = 5;
  }

  if (type === "password") {
    // inputProps.onPaste = noop;
    inputProps.onCopy = noop;
    inputProps.onCut = noop;
    inputProps.onDrag = noop;
    inputProps.onDragStart = noop;
  }

  const handleChange = (event: any) => {
    onChange(event);
  };

  const handleEyeClick = () => {
    if (type === "password") {
      setIsPasswordVisible(!isPasswordVisible);
      setInternalType(isPasswordVisible ? "password" : "text");
    }
  };

  return (
    <Box position="relative" {...styleProps}>
      {label && (
        <Label>
          {label}
          {isRequired ? " *" : ""}
        </Label>
      )}
      {subLabel && (
        <Paragraph color={"gray.60"} fontSize={1}>{subLabel}</Paragraph>
      )}
      {type === "search" && (
        <Icon
          Type={BiSearch}
          size="23px"
          position="absolute"
          left="1"
          top="22%"
          color="gray.40"
        />
      )}
      <InputBase onChange={handleChange} {...inputProps} />
      {type === "password" && (
        <Icon
          Type={isPasswordVisible ? AiOutlineEye : AiOutlineEyeInvisible}
          size="26px"
          position="absolute"
          right="2"
          top="50%"
          cursor="pointer"
          onClick={handleEyeClick}
          color="gray.40"
          hoverColor="gray.50"
        />
      )}
      {errorMessage && (
        <Paragraph color="red.primary" fontSize="1" mt="1" textAlign="right">
          {errorMessage}
        </Paragraph>
      )}
    </Box>
  );
};
