import { FaPlus } from "react-icons/fa";
import { Box } from "../components/Box";
import { Button } from "../components/Button";
import { Flex } from "../components/Flex";
import { Input } from "../components/Input";
import Topbar from "../components/Topbar";
import { ROUTE } from "../routes";
import { env } from "../utils/env";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MonitoringTable } from "../components/MonitoringTable";
import styled from "styled-components";
import { StatusFilter } from "../components/StatusFilter";
import { useMonitoringStatus } from "../data/useMonitoringStatus";
import { MonitoringDateFilters } from "../components/monitoringDateFilters";
import { MdTune } from "react-icons/md";

const initialState = {
  created_on_date: [null, null],
  last_activity_date:[null, null],
};
const StyledTab = styled(Box)<any>`
${({ theme: { colors, space } }) => `
  font-family: "Inter", sans-serif;
  font-weight: bold;
  border-bottom: 1px solid ${colors.gray[30]};
  padding: 1%;
`}
`;
const Monitoring = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState("");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filters, setFilters] = useState(initialState);

  const handleSearchSubmit = () => {
    setSubmittedSearchTerm(searchTerm);
  };

  const handleResetSearch = () => {
    setSearchTerm("");
    setSubmittedSearchTerm("");
  };

  const filterStatusIds = useMemo(
    () => [selectedStatus?.value],
    [selectedStatus?.value]
  );

  const handleFilterSubmit = (values) => {
    setFilters(values);
    setIsFiltersOpen(false);
  };

  return (
    <>
      <div>
        <Topbar title="Monitoring">
          <Flex justifyContent="space-between">
            {env.isSearchMonitoringFeatureOn ? (
              <Flex ml={4}>
                <Input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.currentTarget.value)}
                  type="search"
                  size="small"
                  placeholder="Search"
                  maxWidth={250}
                  mr={1}
                />
                <Button
                  isDisabled={submittedSearchTerm === searchTerm}
                  onClick={handleSearchSubmit}
                >
                  Search
                </Button>

                {submittedSearchTerm && (
                  <Button
                    variant="text"
                    pl="0px"
                    ml={1}
                    onClick={handleResetSearch}
                  >
                    Reset search
                  </Button>
                )}
              </Flex>
            ) : (
              <Box />
            )}
            <Flex>
              <Button
                Icon={FaPlus}
                iconSize="16px"
                onClick={() => navigate(ROUTE.MONITORING_NEW)}
              >
                New Monitoring Record
              </Button>
            </Flex>
          </Flex>
        </Topbar>
      </div>
      <StyledTab className="Tabs">
        <Flex justifyContent="space-between">
           <Flex ml={4}>
              </Flex>
              
            <Flex>
               <Button
                  variant="secondary"
                  mr={1}
                  Icon={MdTune}
                  iconPosition="right"
                  onClick={() => setIsFiltersOpen((isOpen) => !isOpen)}
                >
                  Filter by
                </Button>
            </Flex> 
             
            </Flex>
        </StyledTab>

        <MonitoringTable
          searchTerm={submittedSearchTerm}
          filterStatusIds={filterStatusIds}
          filters={filters}
        ></MonitoringTable>
        
          <MonitoringDateFilters
            isOpen={isFiltersOpen}
            onRequestClose={() => setIsFiltersOpen(false)}
            onSubmit={handleFilterSubmit}
            filters={filters}
        /> 
    </>
  );
};

export default Monitoring;
