import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import { fadeIn } from "../../components/animation";
import { Box } from "../../components/Box";
import Topbar from "../../components/Topbar";
import { useAuth } from "../../context/AuthContext";
import { ROUTE } from "../../routes";
import { Flex } from "../../components/Flex";
import LeftMenu from "./LeftMenu";
import { DirtyFormProvider } from "./DirtyFormContext";
import { useRef } from "react";

const Wrapper = styled.div`
  animation: fadein 0.5s;
  ${fadeIn}
`;

const Settings = () => {
  const { isAdmin } = useAuth();
  const formRef = useRef(null); // Create a form ref

  if (!isAdmin) {
    return <Navigate to={ROUTE.ROOT} replace />;
  }

  return (
    <div>
      <Topbar title="Settings" noBorder />

      <DirtyFormProvider formRef={formRef}>
        {/* Wrap your components with the provider */}
        <Flex p={3} gap="24" height="calc(100vh - 160px)" overflowY="auto">
          <Box
            flex={0.5}
            bg="white"
            borderRadius={0}
            p={4}
            maxWidth={288}
            minWidth={200}
            maxHeight="100%"
            overflowY="auto"
            height="fit-content"
            position={"sticky"}
            top={"0px"}
          >
            <LeftMenu />
          </Box>
          <Box
            className="content-area"
            flex={1.5}
            borderRadius={0}
            maxHeight="100%"
          >
            <Wrapper>
              <Outlet />
            </Wrapper>
          </Box>
        </Flex>
      </DirtyFormProvider>

      {/* <Tabs>
        <Tab to={ROUTE.SETTINGS} label="Company Information" />
        {isEnabled(FeatureFlagKey.BILLING_DETAILS) && (
          <Tab to={ROUTE.SETTINGS_BILLING_DETAILS} label="Billing details" />
        )}
        <Tab to={ROUTE.SETTINGS_USERS} label="Users" />
        <Tab to={ROUTE.SETTINGS_APPLICATION_TYPES} label="Application Types" />
      </Tabs> */}
      {/* <Box overflowY="auto" height="calc(100vh - 217px);" pb={4}>
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Box> */}
    </div>
  );
};

export default Settings;
