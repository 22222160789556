import { useState } from "react";
import open from "../../assets/settings/expansion-open.svg";
import close from "../../assets/settings/expansion-close.svg";
import { Image } from "../Image";
import { Flex } from "../Flex";
import { Paragraph } from "../Paragraph";
import { Box } from "../Box";
import styled, { keyframes } from "styled-components";

type ExpansionPanelProps = {
  title?: string;
  children: React.ReactNode; // More flexible type for children;
  defaultExpanded?: boolean;
};

const expansionAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ExpansionPanelContainer = styled.div<ExpansionPanelProps>`
  overflow: hidden; /* Prevent content overflow when closed */
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out; // Combined transition

  &.expanded {
    animation: ${expansionAnimation} 0.3s ease-in-out; /* Apply animation on toggle */
  }
`;

export const ExpansionPanel = (props: ExpansionPanelProps) => {
  const { title, children, defaultExpanded = false } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <ExpansionPanelContainer className={expanded ? "expanded" : ""}>
      <Flex gap="12" onClick={toggleExpansion} style={{ cursor: "pointer" }}>
        <Paragraph color="gray.60" fontWeight={500}>
          {title}
        </Paragraph>
        <Paragraph>
          {expanded ? (
            <Image src={close} alt="close" height="26px" width="26px" />
          ) : (
            <Image src={open} alt="open" height="26px" width="26px" />
          )}
        </Paragraph>
      </Flex>
      {expanded && <Box>{children}</Box>}
    </ExpansionPanelContainer>
  );
};
