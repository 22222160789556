import { AxiosResponse } from "axios";

/**
 * Returns an object that mimics an Axios HTTP call response. Can be used as
 * a wrapper for react-query placeholder data.
 */
export const asAxiosResponse = <T>(data: T): AxiosResponse<T> => ({
  data: data,
  status: 200,
  statusText: "OK",
  headers: {},
  config: {},
  request: {},
});
