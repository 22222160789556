import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  _form: number;
  title: string;
  description: string;
  questions: {
    title: string;
    description: string;
    field_type: number;
    meta_data: null | string[];
    required: boolean;
    order: number;
    id?: number;
  }[];
}

export const useUpdateSection = () => {
  const { authHeader } = useAuth();

  return useMutation(
    ({ params, sectionId }: { params: Params; sectionId: number }) => {
      return axios.put(
        `${env.verifyServiceUrl}individual/section/${sectionId}/`,
        params,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
    }
  );
};
