import ReactToggle from "react-toggle";
import { Box, BoxProps } from "../Box";
import "./styles.css";

type ToggleProps = {
  isChecked: boolean;
  isDisabled?: boolean;

  onChange: React.ChangeEventHandler<HTMLInputElement>;
  color?: string;
} & BoxProps;

export const Toggle = (props: ToggleProps) => {
  const { isChecked, onChange, isDisabled, ...styleProps } = props;

  return (
    <Box {...styleProps}>
      <ReactToggle
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
      />
    </Box>
  );
};
