import { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import { useFormikContext } from "formik";
import AsyncSelect from "react-select/async";
import { useTheme } from "styled-components";
import { Box } from "../Box";

const AddressAutocomplete = ({ setSelectedAddress, country, setFormOpen }) => {
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const [localSelectedAddress, setLocalSelectedAddress] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { setFieldValue } = useFormikContext();
  const { colors, radii, space } = useTheme() as any;
  const containerRef = useRef(null);

  const key = "PC24-AE82-ER51-JA99";

  const clearSearch = () => {
    setSearchText("");
    setResults([]);
    setDropdownOpen(false);
  };

  const showError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 10000);
  };

  const fetchAddressOptions = async (params) => {
    try {
      const response = await axios.post(
        "https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws",
        params
      );
      const data = response.data;

      if (data.Items.length === 1 && data.Items[0].Error) {
        showError(data.Items[0].Description);
        return [];
      } else if (data.Items.length === 0) {
        showError("Sorry, there were no results");
        return [];
      } else {
        const processedResults = data.Items.map((item) => ({
          value: item.Id,
          label: `${item.Text} ${item.Description}`,
          type: item.Type,
        }));
        setResults(processedResults);
        setDropdownOpen(true); // Open dropdown when results are available
        return processedResults;
      }
    } catch (error) {
      showError("An error occurred while fetching addresses");
      return [];
    }
  };

  const loadOptions = useCallback(
    async (inputValue) => {
      if (!inputValue) return [];
      const params = new URLSearchParams({
        Key: key,
        Text: inputValue,
        IsMiddleware: "false",
        Origin: "",
        Countries: country,
        Limit: "10",
        Language: "en-gb",
      });

      const options = await fetchAddressOptions(params);
      return options;
    },
    [country] // Only recreate the function when `country` changes
  );

  const findAddressInContainer = async (containerId) => {
    const params = new URLSearchParams({
      Key: key,
      Text: searchText,
      IsMiddleware: "false",
      Container: containerId,
      Origin: "",
      Countries: country,
      Limit: "10",
      Language: "en-gb",
    });

    setLoading(true);
    const options = await fetchAddressOptions(params);
    setResults(options); // Update results with new options
    setDropdownOpen(true); // Re-open dropdown to show new options
    setLoading(false);
  };

  const retrieveAddress = async (Id) => {
    const params = new URLSearchParams({
      Key: key,
      Id,
      Field1Format: "",
    });

    try {
      const response = await axios.post(
        "https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws",
        params
      );
      const data = response.data;

      if (data.Items.length === 1 && data.Items[0].Error) {
        showError(data.Items[0].Description);
      } else if (data.Items.length === 0) {
        showError("Sorry, there were no results");
      } else {
        const address = data.Items[0];
        const newObj = {
          building_number: address?.BuildingNumber || "",
          flat_appartment_subbuilding: address?.SubBuilding || "",
          building_name: address?.BuildingName || "",
          road_street: address?.Street || "",
          town_city: address?.City || "",
          district: address?.District || "",
          state_province_name: address?.ProvinceName || "",
          state_province_code: address?.ProvinceCode || "",
          post_zip_code: address?.PostalCode || "",
          country_code: address?.CountryIso2 || country,
        };
        setLocalSelectedAddress(newObj);
        setSelectedAddress(newObj); // Update parent state
        setFieldValue("building_number", newObj.building_number);
        setFieldValue(
          "flat_appartment_subbuilding",
          newObj.flat_appartment_subbuilding
        );
        setFieldValue("building_name", newObj.building_name);
        setFieldValue("road_street", newObj.road_street);
        setFieldValue("town_city", newObj.town_city);
        setFieldValue("district", newObj.district);
        setFieldValue("state_province_name", newObj.state_province_name);
        setFieldValue("state_province_code", newObj.state_province_code);
        setFieldValue("post_zip_code", newObj.post_zip_code);
        setFieldValue("country_code", newObj.country_code);
      }
    } catch (error) {
      showError("An error occurred while retrieving the address");
    }
  };

  const selectAddress = async (selectedOption) => {
    const selectedId = selectedOption ? selectedOption.value : "";
    if (selectedId === "") return;

    const selectedOptionData = results.find(
      (item) => item.value === selectedId
    );

    if (selectedOptionData && selectedOptionData.type === "Address") {
      await retrieveAddress(selectedId);
      setDropdownOpen(false); // Close dropdown when final address is selected
      setFormOpen(true); // Open the form when final address is selected
       document.addEventListener("focusout",handleBlur);
    } else {
      await findAddressInContainer(selectedId);
      setDropdownOpen(true); // Re-open dropdown with the new container results
    }
  };

  const handleBlur = () => {
    if (!localSelectedAddress) {
      clearSearch();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        handleBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [localSelectedAddress]);

  const onFocus = () => {
  setSearchText(" "); 
}

  return (
    <Box ref={containerRef}>
      <AsyncSelect
        cacheOptions
        inputValue={searchText}
        onInputChange={setSearchText}
        loadOptions={loadOptions}
        defaultOptions={results} // Use `results` to update UI
        onChange={selectAddress}
        placeholder="Type address or postcode/zip code"
        isLoading={loading}
        noOptionsMessage={() => "No results found"}
        menuIsOpen={dropdownOpen}
        onFocus={onFocus}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            border: `2px solid ${colors.gray[30]};`,
            borderRadius: radii[0],
            height: 60,
            paddingLeft: space[1],
            fontFamily: "Inter",
            boxShadow: "none",
            maxHeight: 60,
            "::placeholder": {
              color: colors.gray[50],
            },
            ":hover": {
              border: `2px solid ${colors.gray[40]}`,
            },
            ...space,
          }),
          option: (base, { isSelected, isFocused }) => ({
            ...base,
            fontFamily: "Inter",
            height: 60,
            display: "flex",
            alignItems: "center",
            fontWeight: isSelected ? "bold" : "regular",
            backgroundColor:
              isFocused || isSelected ? colors.background : colors.white,
            color: "#aaaaaaa",
            ":hover": {
              backgroundColor: colors.background,
            },
            ...space,
          }),
        }}
        menuPortalTarget={document.body} // Ensure dropdown renders in the portal
      />
      {errorMessage && (
        <div id="errorMessage" style={{ color: "red" }}>
          {errorMessage}
        </div>
      )}
    </Box>
  );
};

export default AddressAutocomplete;
