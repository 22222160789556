import { IconButton, IconButtonProps } from "../IconButton";
import React, { useState, useRef } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useOnClickOutside } from "../../utils/useOnClickOutside";

type IconButtonDropdownProps = Omit<IconButtonProps, "Type"> & {
  children: any;
  placement?: "bottom-start" | "bottom-end";
};

export function IconButtonDropdown(props: IconButtonDropdownProps) {
  const { children, placement = "bottom-start", ...styleProps } = props;
  const [visible, setVisibility] = useState(false);

  const referenceRef = useRef(null);
  const popperRef = useRef(null);

  useOnClickOutside(popperRef, () => setVisibility(false));

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement,
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  );

  function handleDropdownClick() {
    setVisibility(!visible);
  }

  return (
    <React.Fragment>
      <IconButton
        Type={HiOutlineDotsVertical}
        ref={referenceRef}
        onClick={handleDropdownClick}
        size="23px"
        {...styleProps}
      />
      <div
        ref={popperRef}
        style={{ ...styles.popper, zIndex: 1 }}
        {...attributes.popper}
      >
        <DropdownContainer style={styles.offset} visible={visible}>
          {children}
        </DropdownContainer>
      </div>
    </React.Fragment>
  );
}

const DropdownContainer = styled.div<any>`
  ${({ theme: { colors, radii } }) => `
    border-radius: ${radii[0]};
    font-family: "Inter", sans-serif;
    color: ${colors.gray[50]};
    background-color: white;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.12);
  `}
  display: ${(props) => (props.visible ? "block" : "none")};
`;
