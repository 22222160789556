import styled from "styled-components";
import { Box } from "../Box";
import { fadeIn } from "../animation";
import { Paragraph } from "../Paragraph";
import { ProfileOrb } from "../ProfileOrb";
import { Comment as CommentType } from "../../types/Comment";
import { Flex } from "../Flex";
import { DateTime } from "luxon";

const Wrapper = styled.div`
  animation: fadein 0.5s;
  ${fadeIn}

  ${({ theme: { space } }) => `
      display: flex;
      margin-bottom: ${space[4]};
  `}
`;

export const Comment = (props: CommentType) => {
  const { comment, user, created_on } = props;

  return (
    <Wrapper>
      <ProfileOrb
        firstName={user?.first_name}
        lastName={user?.last_name}
        colour={user?.colour}
        size={40}
        mr={2}
      />
      <Box flex={1}>
        <Flex alignItems="center" justifyContent="space-between">
          <Paragraph fontSize={1} m="0px" fontWeight="bold" mr={1}>
            {user?.first_name} {user?.last_name}
          </Paragraph>
          <Paragraph color="gray.40" m="0px" fontSize={0}>
            {DateTime.fromISO(created_on).toLocal().toFormat('FF')}
          </Paragraph>
        </Flex>
        <Paragraph fontSize={1} m="0px" mt={1}>
          {comment}
        </Paragraph>
      </Box>
    </Wrapper>
  );
};
