import { useInfiniteQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { PagedResponse } from "../types/Common";
import { env } from "../utils/env";
import { Comment } from "../types/Comment";

type ForType = 'Mandate' | 'Individual';

const fetch =
  (authHeader: string, referenceId: number, type: ForType) =>
    ({ pageParam = 1 }) =>
      axios.get<PagedResponse<Comment[]>>(
        `${env.verifyServiceUrl}individual/comments/?comment_for=${type}&reference=${referenceId}&page=${pageParam}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

export const useMandateComments = (referenceId: number, type: ForType) => {
  const { authHeader } = useAuth();
  return useInfiniteQuery(
    ["mandateComments", referenceId, type],
    fetch(authHeader, referenceId, type),
    {
      getPreviousPageParam: (firstPage) =>
        new URLSearchParams(firstPage.data.previous).get("page") ?? undefined,
      getNextPageParam: (lastPage) =>
        new URLSearchParams(lastPage.data.next).get("page") ?? undefined,
    }
  );
};
