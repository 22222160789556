import styled from "styled-components";
import { Box } from "../Box";
import { Button } from "../Button";
import { H2 } from "../Heading";
import { Link } from "../Link";
import { Image } from "../Image";
import { Paragraph } from "../Paragraph";

import { Flex } from "../Flex";

export const PreviewContainer = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray[10]};
  width: 100%;
  height: 70%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
`;

const SplashScreen = ({
  companyName,
  message,
  buttonColor,
  backgroundColor,
  termsLink,
  privacyLink,
  fontColor,
  buttonFontColor,
  linkFontColor,
  logo,
}) => {
  return (
    <PreviewContainer>
      <Flex
        backgroundColor={backgroundColor}
        border={"2px solid"}
        borderColor={"blue.100"}
        width={200}
        // borderRadius={16}
        flexDirection={"column"}
        alignItems={"center"}
        p={4}
      >
        {logo && (
          <Flex
            height={75}
            width={80}
            mb={2}
            // border={"1px solid"}
            // borderColor={"gray.20"}
            // borderRadius={"50%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image
              src={logo}
              alt="logo"
              width="100%"
              height="100%"
              // style={{ borderRadius: "50%" }}
            />
          </Flex>
        )}

        <H2 color={fontColor}>{companyName}</H2>
        <Box textAlign={"left"}>
          <Paragraph fontSize={0} color={fontColor}>
            {message}
          </Paragraph>
          <Box mt={"100px"}>
            <Paragraph fontSize={0} color={fontColor}>
              Please read the <strong>{companyName}</strong>{" "}
              <Link fontSize={0} href={termsLink} color={linkFontColor}>
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link fontSize={0} href={privacyLink} color={linkFontColor}>
                Privacy Policy
              </Link>
            </Paragraph>

            <Paragraph fontSize={0} color={fontColor}>
              Delivered by Tiller Technologies
            </Paragraph>

            <Button
              width={"100%"}
              backgroundColor={buttonColor}
              color={buttonFontColor}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Flex>
    </PreviewContainer>
  );
};

export default SplashScreen;
