import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { FieldType } from "../types/Forms";
import { env } from "../utils/env";




const fetchOptions = (authHeader: string) => () =>
  axios.get<FieldType[]>(
    `${env.verifyServiceUrl}common/question_field_type_list/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useQuestionsFiledTypeList = () => {
  const { authHeader, isLoggedIn } = useAuth();
  return useQuery(["questionsFieldTypeList"], fetchOptions(authHeader), {
    enabled: isLoggedIn,
    retry: false,
  });
};
