import { useState } from "react";
import { useParams } from "react-router-dom";
import { IndividualManager } from "./IndividualManager";
import { SendInvites } from "./SendInvites";

export const ApplicationIndividuals = () => {
  const params = useParams();
  const mandateId = parseInt(params.id);

  const [step, setStep] = useState("individualManager");

  const handleClickNext = () => {
    setStep("sendInvites");
  };

  const handleClickBack = () => {
    setStep("individualManager");
  };

  return (
    <>
      {step === "individualManager" && (
        <IndividualManager
          mandateId={mandateId}
          onClickNext={handleClickNext}
        />
      )}

      {step === "sendInvites" && (
        <SendInvites mandateId={mandateId} onClickBack={handleClickBack} />
      )}
    </>
  );
};
