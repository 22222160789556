import { Input } from "../../components/Input";
import { H2 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Form, Formik } from "formik";
import Select from "../../components/Select";
import { Flex } from "../../components/Flex";
import { Button } from "../../components/Button";
import { addMandateValidation } from "../../utils/validationSchema";
import { NewApplicationFrame } from "../ApplicationIndividuals/NewApplicationFrame";
import { useConsultants } from "../../data/useConsultants";
import { useMandateTypes } from "../../data/useMandateTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateMandate } from "../../data/useCreateMandate";
import { useCreateConsultant } from "../../data/useCreateConsultant";
import { logError, showError } from "../../utils/error-handling";
import { Box } from "../../components/Box";
import { useState } from "react";
import { useUpdateMandate } from "../../data/useUpdateMandate";
import styled from "styled-components";

const MandateTypeBubble = ({ title, content, children }: any) => (
  <Box borderRadius={0} bg="#e3e7ee" p={3} mb={2}>
    <Paragraph mt="0px" fontWeight="bold">
      {title}
    </Paragraph>
    {content ? <Paragraph mb="0px">{content}</Paragraph> : children}
  </Box>
);

const StyledUl = styled.ul`
  ${({ theme: { fontSizes, fontFamily } }) => `     
      font-family: ${fontFamily};
      font-size: ${fontSizes[2]};   
      padding-left: 12px; 
      margin-bottom: 0px;
    `}
`;

export const StyledLi = styled.li`
  ${({ theme: { space, colors } }) => `
        margin-left: ${space[2]};

        ::marker{
        color: ${colors.gray[40]};
        }
  `}
`;

export const NewApplication = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMandateType, setSelectedMandateType] = useState(null);

  const { mutate: createMandate } = useCreateMandate();
  const { mutate: updateMandate } = useUpdateMandate();
  const { mutate: createConsultant } = useCreateConsultant();
  const { data: consultants } = useConsultants();
  const consultantOptionsRaw = consultants?.data?.results?.map(
    ({ name, id }) => ({
      label: name,
      value: id,
    })
  );
  const consultantOptions = [
    { value: "create_consultant", label: "Add a new consultant" },
    ...((consultantOptionsRaw as any[]) ?? []),
  ];

  const { data: mandateTypes } = useMandateTypes();

  const applicationTypeOptions = mandateTypes?.data
    ?.filter(({ is_disabled }) => !is_disabled)
    .map(({ name, id }) => ({
      label: name,
      value: id,
    }));

  const onApplicationSubmit = (form: any) => {
    setDoubleClick(true);
    if (location.state && location.state.checks) {
      if (form.consultant === "create_consultant") {
        createConsultant(
          {
            name: form.consultantName,
            email: form.consultantEmail,
          },
          {
            onSuccess: ({ data }) => {
              const payload: any = {
                name: form.applicationReference.trim(),
                reference: form.applicationReference.trim(),
                mandate_type: form.applicationType,
                consultant: data.id,
                category: "Application",
              };
              updateMandate(
                {
                  id: location.state.id,
                  ...payload,
                },

                {
                  onSuccess: ({ data }) => {
                    navigate(`/applications/${data.id}/individuals/new`, {
                      state: { checks: true },
                    });
                  },
                  onError: (err: any) => {
                    setDoubleClick(false);
                    showError(
                      err,
                      "A problem occurred while creating the applcation."
                    );
                  },
                }
              );
            },
            onError: (err: any) => {
              setDoubleClick(false);
              if (
                err.response &&
                err.response.data &&
                err.response.data.email
              ) {
                // Display the specific error message for an existing email
                showError(err, err.response.data.email[0]);
              } else {
                showError(
                  err,
                  "A problem occurred while creating the consultant."
                );
              }
              logError(err);
            },
          }
        );
      } else {
        const payload: any = {
          name: form.applicationReference.trim(),
          reference: form.applicationReference.trim(),
          mandate_type: form.applicationType,
          consultant: form.consultant,
          category: "Application",
        };
        updateMandate(
          {
            id: location.state.id,
            ...payload,
          },
          {
            onSuccess: ({ data }) => {
              navigate(`/applications/${data.id}/individuals/new`, {
                state: { checks: true },
              });
            },
            onError: (err: any) => {
              setDoubleClick(false);
              showError(
                err,
                "A problem occurred while creating the applcation."
              );
            },
          }
        );
      }
    } else {
      const onSuccess = ({ data }) => {
        navigate(`/applications/${data.id}/individuals`);
      };

      if (form.consultant === "create_consultant") {
        createConsultant(
          {
            name: form.consultantName,
            email: form.consultantEmail,
          },
          {
            onSuccess: ({ data }) => {
              createMandate(
                {
                  name: form.applicationReference.trim(),
                  reference: form.applicationReference.trim(),
                  mandate_type: form.applicationType,
                  consultant: data.id,
                  category: "Application",
                },
                {
                  onSuccess,
                  onError: (err: any) => {
                    setDoubleClick(false);
                    showError(
                      err,
                      "A problem occurred while creating the applcation."
                    );
                  },
                }
              );
            },
            onError: (err: any) => {
              setDoubleClick(false);
              if (
                err.response &&
                err.response.data &&
                err.response.data.email
              ) {
                // Display the specific error message for an existing email
                showError(err, err.response.data.email[0]);
              } else {
                showError(
                  err,
                  "A problem occurred while creating the consultant."
                );
              }
              logError(err);
            },
          }
        );
      } else {
        createMandate(
          {
            name: form.applicationReference.trim(),
            reference: form.applicationReference.trim(),
            mandate_type: form.applicationType,
            consultant: form.consultant,
            category: "Application",
          },
          {
            onSuccess,
            onError: (err: any) => {
              setDoubleClick(false);
              showError(
                err,
                "A problem occurred while creating the applcation."
              );
            },
          }
        );
      }
    }
  };

  const mandateTypeDetails = mandateTypes?.data?.find(
    ({ id }) => id === selectedMandateType
  );

  return (
    <NewApplicationFrame step="mandate">
      <Formik
        initialValues={{
          applicationReference: "",
          applicationType: "",
          consultant: "",
          consultantName: "",
          consultantEmail: "",
        }}
        validationSchema={addMandateValidation}
        onSubmit={(values) => {
          onApplicationSubmit(values);
        }}
      >
        {(props) => (
          <Form style={{ width: "500px" }} onSubmit={props.handleSubmit}>
            <H2>Application type</H2>
            <Paragraph>
              Please select the type of application you would like to create
            </Paragraph>
            <Input
              onChange={props.handleChange}
              value={props.values.applicationReference}
              placeholder="Enter application reference"
              mt="3"
              label="Application reference"
              name="applicationReference"
              isRequired
              hasError={Boolean(props.errors.applicationReference)}
              errorMessage={props.errors.applicationReference}
            />
            <Select
              onChange={(option: any) => {
                props.setFieldValue("applicationType", option.value);
                setSelectedMandateType(option.value);
              }}
              value={applicationTypeOptions?.find(
                ({ value }: any) => value === props.values.applicationType
              )}
              options={applicationTypeOptions}
              placeholder="Select application type"
              mt="3"
              label="Application type"
              name="applicationType"
              isRequired
              hasError={Boolean(props.errors.applicationType)}
              errorMessage={props.errors.applicationType}
            />
            <Select
              onChange={(option: any) =>
                props.setFieldValue("consultant", option.value)
              }
              value={consultantOptions?.find(
                ({ value }: any) => value === props.values.consultant
              )}
              options={consultantOptions}
              placeholder="Select consultant"
              mt="3"
              label="Select consultant"
              name="consultant"
              // isRequired
              // hasError={Boolean(props.errors.consultant)}
              // errorMessage={props.errors.consultant}
            />
            {props.values.consultant === "create_consultant" && (
              <>
                <Input
                  onChange={props.handleChange}
                  value={props.values.consultantName}
                  placeholder="Enter consultant name"
                  mt="3"
                  label="Consultant Name"
                  name="consultantName"
                  isRequired
                  hasError={Boolean(props.errors.consultantName)}
                  errorMessage={props.errors.consultantName as string}
                />
                <Input
                  onChange={props.handleChange}
                  value={props.values.consultantEmail}
                  placeholder="Enter consultant email"
                  mt="3"
                  label="Consultant Email"
                  name="consultantEmail"
                  isRequired
                  hasError={Boolean(props.errors.consultantEmail)}
                  errorMessage={props.errors.consultantEmail as string}
                />
              </>
            )}
            <Flex justifyContent="flex-end">
              <Button
                size="large"
                mt="3"
                mb="0"
                type="submit"
                isDisabled={!(props.isValid && props.dirty) || doubleClick}
              >
                Next
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>

      {selectedMandateType && (
        <>
          <Box
            display={{
              _: "none",
              xl: "block",
            }}
            position="absolute"
            right={4}
            top="205px"
            width="325px"
          >
            <Paragraph fontWeight="bold">
              {mandateTypeDetails?.name} includes:
            </Paragraph>
            <MandateTypeBubble
              title="Description"
              content={mandateTypeDetails?.description}
            />
            <MandateTypeBubble title="Checks">
              <StyledUl>
                {mandateTypeDetails?.checkpoint.map(
                  ({ actual_name }, index) => (
                    <StyledLi key={index}>{actual_name}</StyledLi>
                  )
                )}
              </StyledUl>
            </MandateTypeBubble>

            {mandateTypeDetails && (
              <MandateTypeBubble title="Additional Documents">
                {mandateTypeDetails.additional_document &&
                mandateTypeDetails.additional_document.length > 0 ? (
                  <StyledUl>
                    {mandateTypeDetails?.additional_document.map(
                      (doc, index) => (
                        <StyledLi key={index}>{doc.name}</StyledLi>
                      )
                    )}
                  </StyledUl>
                ) : (
                  <StyledUl>
                    <StyledLi>None</StyledLi>
                  </StyledUl>
                )}
              </MandateTypeBubble>
            )}
          </Box>
        </>
      )}
    </NewApplicationFrame>
  );
};
