import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { axios } from "./axios";

interface Params {
  id: number;
  name?: string;
  description?: string;
  is_disabled?: boolean;
  action_reminder_email?: boolean;
  checkpoint?: number[];
  additional_document?: number[];
}

export const useUpdateMandateType = () => {
  const { authHeader } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (params: Params) => {
      return axios.patch<Params>(
        `${env.verifyServiceUrl}individual/mandate_type/${params.id}/`,
        params,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
    },
    {
      onSuccess: (data, params) => {
        queryClient.setQueryData(["mandateTypeQuery", params.id], data);
      },
    }
  );
};
