import { Image } from "../../components/Image";
import success from "../../assets/card-success-icon-light.svg";
import inReview from "../../assets/card-indeterminate-icon-gray.svg";
import { Paragraph } from "../../components/Paragraph";
import { Flex } from "../../components/Flex";

export const ActionItem = (props) => {
  const { isComplete, label } = props;

  return (
    <Flex alignItems="center" mb={2}>
      <Image
        src={isComplete ? success : inReview}
        alt="progress indicator"
        height="20px"
        width="24px"
        mr={2}
      />
      <Paragraph fontSize={1} fontWeight="bold" mt="0px" mb="0px">
        {label}
      </Paragraph>
    </Flex>
  );
};
