import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { Client } from "../types/Client";
import { env } from "../utils/env";

const fetchClient = (authHeader: string, id: number) => () =>
  axios.get<Client>(`${env.verifyServiceUrl}common/tenant/${id}`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useClientDetails = (id: number) => {
  const { authHeader } = useAuth();
  return useQuery(["clientDetails", id], fetchClient(authHeader, id), {
    enabled: typeof id === "number",
    retry: false
  });
};
