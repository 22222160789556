import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

export const useDeleteForm = () => {
  const { authHeader } = useAuth();

  return useMutation((formId: number) => {
    return axios.delete(`${env.verifyServiceUrl}individual/form/${formId}/`, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
