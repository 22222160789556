import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";


const fetch = (link: string) => () =>
  axios.get(`${env.verifyServiceUrl}user/forgot_password/?link=${link}`);

export const useVerifyPasswordResetLink = (link: string) => {
  return useQuery(["passwordResetLink"], fetch(link), {
    retry: false
  });
};
