import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H2, H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { useAllAdditionalDocuments } from "../../data/useAllAdditionalDocuments";
import { Paragraph } from "../../components/Paragraph";
import { Image } from "../../components/Image";
import editIcon from "../../assets/settings/edit.svg";
import { AddEditAdditionalDocument } from "../../components/Modals/AddEditAdditionalDocument";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "../../components/Link";
import { env } from "../../utils/env";

const SettingsAdditionalDocumentsRequests = () => {
  const { data, isLoading } = useAllAdditionalDocuments();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const addAdditionalDoc = () => {
    setIsEditModalOpen(true);
    setSelectedDocumentId(null);
  };

  const handleEdit = (id: number) => {
    setIsEditModalOpen(true);
    setSelectedDocumentId(id);
  };

  return (
    <>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <Flex justifyContent={"space-between"} alignItems={"center"} mb={2}>
          <H2 mb={0}>Manage additional document requests</H2>

          <Button
            Icon={FaPlus}
            iconSize="16px"
            onClick={addAdditionalDoc}
            variant="primary"
            type="button"
            fontWeight={"bold"}
          >
            Add additional document
          </Button>
        </Flex>

        <Paragraph color={"gray.60"} fontSize={1} mt={"0"} mb={3}>
        Customise requests for additional documents when more information or supporting materials are needed.
          {/* <Link fontSize="1" href={env.verifyTermsAndConditionsUrl} target="_blank" ml={2}>
           Learn more
        </Link> */}
        </Paragraph>

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        ) : (
          data?.map((item) => (
            <Flex
              key={item.id}
              py={2}
              borderBottom="1px solid #e0e0e0"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <H3>{item.name}</H3>
                <Paragraph my={0} color="gray.60">
                  {item.description}
                </Paragraph>
              </Box>
              <Box>
                <Image
                  src={editIcon}
                  alt="edit icon"
                  height="24px"
                  width="24px"
                  marginRight={"4px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEdit(item.id)}
                />
              </Box>
            </Flex>
          ))
        )}
      </Box>
      <AddEditAdditionalDocument
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        documentId={selectedDocumentId}
      />
    </>
  );
};

export default SettingsAdditionalDocumentsRequests;
