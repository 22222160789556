import { useRef, useState } from "react";
import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Image } from "../Image";
import { Paragraph } from "../Paragraph";
import logo from "../../assets/image-preview.svg";
import deleteIcon from "../../assets/settings/delete-red.svg";

interface Props {
  onChange: (file: File) => void;
  onDelete?: () => void;
  logoUrl?: string;
  label?: string;
  text?: string;
  buttonText?: string;
  hasDeleteButton?: boolean;
}

export const ImageUpload = (props: Props) => {
  const { onChange, onDelete, logoUrl } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleDelete = () => {
    setSelectedImage(null);
    onDelete(); // Call the onDelete prop function
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      // "image/gif",
      // "image/bmp",
    ];
    // if (file.type === "image/svg+xml") {
    if (file && !allowedTypes.includes(file.type)) {
      setErrorMessage("The allowed file formats are JPG, JPEG and PNG.");
      return false;
    }
    if (event.target.files[0].size > 2 * 1024 * 1024) {
      setErrorMessage("Image size must be less than 2MB.");
      return false;
    }
    if (file) {
      setErrorMessage(null);
      setSelectedImage(file);
      onChange(file);
    }
  };

  return (
    <Flex gap="50">
      {logoUrl || selectedImage ? (
        <Image
          alt="Image Not found"
          src={selectedImage ? URL.createObjectURL(selectedImage) : logoUrl}
          border="5px solid"
          borderColor="background"
          borderRadius={0}
          height={168}
          width={168}
        />
      ) : (
        <Flex
          border={"1px solid"}
          borderColor={"gray.10"}
          borderRadius={"8px"}
          width={240}
          height={120}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image alt="logo" src={logo} />
        </Flex>
      )}
      <Box>
        <Paragraph mt="0px" fontWeight="bold">
          Upload Logo
        </Paragraph>
        <Paragraph>
          Recommended logo size is 1024px x 1024px. The allowed file formats are
          JPG, JPEG and PNG.
        </Paragraph>
        <Flex gap="30" alignItems={"center"}>
          <Button onClick={handleClick}>
            {props.buttonText ? props.buttonText : "Browse"}
          </Button>
          {props.hasDeleteButton && (
            <Image
              style={{ cursor: "pointer" }}
              src={deleteIcon}
              alt="delete icon"
              height="25px"
              width="25px"
              marginRight={"4px"}
              onClick={handleDelete}
            />
          )}
        </Flex>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          accept=".jpg,.png,.jpeg"
        />

        {errorMessage && (
          <Paragraph color="red.primary" fontSize="1" mt="1" textAlign="right">
            {errorMessage}
          </Paragraph>
        )}
      </Box>
    </Flex>
  );
};
