import { CheckSubTitle } from "./CheckStyledComponents";
import { checksStatusName } from "./helper";

type Props = {
  status: string;
  statusText?: string;
};

export const CheckResults = (props: Props) => {
  const { status, statusText = "" } = props;

  return (
    <CheckSubTitle>
      Check Results -&nbsp;
      {statusText ? statusText : checksStatusName[status]}
    </CheckSubTitle>
  );
};
