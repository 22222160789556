import { Box } from "../../../components/Box";
import { FormDetails } from "./FormDetails";
import { SettingsFormFrame } from "./SettingsFormFrame";

const SettingsNewForm = () => {
  return (
    <>
      <SettingsFormFrame step="details">
        <Box pl={4} pr={2} flex={4} minWidth={400}>
          <FormDetails
            initialValues={{
              name: "",
              description: "",
            }}
            editMode={false}
          />
        </Box>
      </SettingsFormFrame>
    </>
  );
};

export default SettingsNewForm;
