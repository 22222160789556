import themeGet from "@styled-system/theme-get";
import { forwardRef } from "react";
import styled, { useTheme } from "styled-components";
import {
  space,
  color,
  layout,
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
} from "styled-system";

export type IconButtonProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps & {
    /** Must be a react-icon component instance. */
    Type: any;
    /** Must be a styled-system color key. */
    color?: string;
    hoverColor?: string;
    size?: string;
    cursor?: string;
    onClick?: (e: any) => void;
    isDisabled?: boolean;
  };

const StyledButton = styled.div<any>`
  ${({ theme: { colors, space, radii }, isDisabled }) => `
        cursor: pointer;
        background: transparent;
        padding: ${space[1]};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: ${radii[0]};
        // box-shadow: 1px 1px 11px rgba(33,33,33,.3);

        &:hover {
            background: ${colors.background};
            // box-shadow: 3px 3px 11px rgba(33,33,33,.3);
            box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 24px;
        }
        ${
          isDisabled
            ? `
          opacity: 0.5;
          cursor: not-allowed;
        `
            : ``
        }
  `}
  ${space}
  ${color}
    ${space}
    ${layout}
    ${position}
    ${border}
`;

export const IconButton = forwardRef((props: IconButtonProps, ref) => {
  const { Type, color, size, ...styleProps } = props;

  const getColor = (key?: string) =>
    themeGet(`colors.${key}`, "grey")({ theme });
  const theme = useTheme() as any;

  return (
    <StyledButton ref={ref} {...styleProps}>
      <Type size={size} color={getColor(color)} />
    </StyledButton>
  );
});
