import styled from "styled-components";
import { Box } from "../Box";
import { fadeIn } from "../animation";
import { Paragraph } from "../Paragraph";
import { ProfileOrb } from "../ProfileOrb";
import { Mandate } from "../../types/Mandate";
import { Link } from "react-router-dom";
import { Flex } from "../Flex";
import { Image } from "../Image";
import success from "../../assets/card-success-icon.svg";
import actions from "../../assets/card-indeterminate-icon.svg";
import archivedIcon from "../../assets/archived-icon.svg";
import personOrange from "../../assets/card-person-orange.svg";
import { DateTime } from "luxon";

const Wrapper = styled(Link)`
  animation: fadein 0.25s;
  ${fadeIn}

  ${({ theme: { colors, radii, space } }) => `
      display: block;
      text-decoration: none;
      color: inherit;
      background: ${colors.white};
      border-radius: ${radii[0]};
      height: 120px;
      margin-bottom: ${space[1]};
      padding: ${space[2]};
      position: relative;
      transition: all 0.2s ease 0s;
      border: 1px solid white;

      &:hover {
        transform: scale(1.025, 1.025);
        box-shadow: 0px 5px 9px rgb(0 0 0 / 12%);
      }

      &:active {
        transform: scale(1, 1);
      }
  `}
`;

export const ApplicationCard = (props: Mandate) => {
  const {
    id,
    reference,
    name,
    mandate_type,
    assigned_user,
    completed_actions,
    checks_in_review,
    total_actions,
    individual_count,
    complete_individual_count,
    archived,
    created_on,
  } = props;
  const hasCompletedActions = completed_actions === total_actions;
  const created = DateTime.fromISO(created_on).toFormat("dd/MM/yyyy");

  return (
    <Wrapper to={`${id}`}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        mb={1}
      >
        {total_actions > 0 && (
          <Flex title="Actions Complete" alignItems="center">
            <Image
              src={hasCompletedActions ? success : actions}
              alt="actions icon"
              height="20px"
              width="24px"
            />
            <Paragraph color="gray.70" my="0px" ml={1} fontSize={1}>
              {completed_actions}/{total_actions}
            </Paragraph>
          </Flex>
        )}

        {/* {checks_in_review > 0 && ( */}
        <Flex title="Individuals Complete" alignItems="center">
          <Image
            src={personOrange}
            alt="checks in review icon"
            height="20px"
            width="24px"
          />
          <Paragraph color="gray.70" my="0px" ml={1} fontSize={1}>
            {complete_individual_count}/{individual_count}
          </Paragraph>
        </Flex>
        {/* )} */}

        {archived && (
          <Image
            src={archivedIcon}
            title="archived"
            alt="Application archived"
            height="20px"
            width="24px"
          />
        )}
      </Flex>
      <Paragraph fontWeight="bold" my="0" ellipsisOverflow width="218px">
        {name}
      </Paragraph>
      <Paragraph
        color="gray.60"
        fontSize="1"
        my="0"
        ellipsisOverflow
        width="218px"
      >
        {mandate_type?.name}
      </Paragraph>
      <Box position="absolute" bottom={0}>
        <Paragraph color="gray.40" mb="0px" fontSize={0}>
          {reference?.length > 30
            ? reference.substring(0, 30) + "..."
            : reference}
        </Paragraph>
        <Paragraph color="gray.40" mt={0} fontSize={0}>
          {created}
        </Paragraph>
      </Box>
      <ProfileOrb
        firstName={assigned_user?.first_name}
        lastName={assigned_user?.last_name}
        position="absolute"
        colour={assigned_user?.colour}
        right={2}
        bottom={2}
      />
    </Wrapper>
  );
};
