import {
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState, useEffect, useMemo } from "react";
import { Flex } from "../Flex";
import { DateTime } from "luxon";
import theme from "../../theme";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import { Icon } from "../Icon";
import { useIndividualsList } from "../../data/useIndividualsList";
import { StyledLink } from "../Breadcrumbs";
import {
  address_verification_check,
  pep_sanctions_check,
  adverse_media_check,
} from "../../assets/checkpoints";
import success from "../../assets/card-success-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import pending from "../../assets/pending-icon.svg";
import { Image } from "../Image";
import { Loader } from "../Loader";
import { Paragraph } from "../Paragraph";
import { Tooltip } from "../Tooltip";
import { capitalise } from "../../utils/string";

export const ChecksTable = (props) => {
  const { searchTerm, filterUserIds, filters } = props;

  const [data, setData] = useState<any>({});
  const [filtersUpdate, setFiltersUpdate] = useState({ ...filters });
  const [searchTermUpdate, setSearchTermUpdate] = useState(props.searchTerm);
  const [filterUserIdsUpdate, setFilterUserIdsUpdate] = useState(
    props.filterUserIds
  );

  const checksIcons = {
    address_verification_check: address_verification_check,
    adverse_media_check: adverse_media_check,
    "pep_&_sanctions_check": pep_sanctions_check,
  };

  const cols = [
    {
      header: () => "Individual name",
      accessorKey: "first_name",
      cell: (info) => {
        const { first_name, last_name } = info.row.original; // Access the original row data
        return (
          <span
            style={{ fontWeight: "bold", fontSize: "18px", color: "#09093E" }}
          >
            {`${first_name} ${last_name}`}
          </span>
        );
      },
    },
    {
      header: () => "Reference",
      accessorKey: "reference",
      cell: (info) => {
        return (
          <Flex
            justifyContent="start"
            alignItems="center"
            position="relative"
            color={theme.colors.gray[40]}
          >
            {info.getValue()}
          </Flex>
        );
      },
    },
    {
      header: () => "Checks",
      accessorKey: "checks",
      cell: (info) => {
        const checks = info.getValue();
        return (
          <Flex justifyContent="start" alignItems="center">
            {checks.map((check) => (
              <span key={check.id} style={{ margin: "0 5px" }}>
                <Tooltip content={capitalise(check.name.replace(/_/g, " "))}>
                  <Image
                    src={checksIcons[check.name]}
                    alt="checks"
                    height="auto"
                    width="22px"
                    mr={1}
                  />
                </Tooltip>
              </span>
            ))}
          </Flex>
        );
      },
    },
    {
      header: () => "Result",
      accessorKey: "result",
      cell: (info) => {
        const { checks_in_review, checks_completed, checks_in_pending } =
          info.row.original;
        return (
          <Flex justifyContent="start" alignItems="center">
            <Flex gap="6" mr={2} alignItems="center">
              <Tooltip content="Checks completed">
                <Image src={success} alt="success" height="20px" width="24px" />
              </Tooltip>
              {checks_completed}
            </Flex>
            <Flex gap="6" mr={2} alignItems="center">
              <Tooltip content="Checks in review">
                <Image
                  src={inReview}
                  alt="inReview"
                  height="20px"
                  width="24px"
                />
              </Tooltip>
              {checks_in_review}
            </Flex>
            <Flex gap="6" mr={2} alignItems="center">
              <Tooltip content="Checks pending">
                <Image src={pending} alt="pending" height="20px" width="24px" />
              </Tooltip>
              {checks_in_pending}
            </Flex>
          </Flex>
        );
      },
    },
    {
      header: () => "Created",
      accessorKey: "created_on",
      cell: (info) => {
        return (
          <Flex color={theme.colors.gray[40]}>
            {DateTime.fromISO(info.getValue()).toFormat("dd/MM/yyyy")}
          </Flex>
        );
      },
    },
    {
      header: () => "",
      id: "view_id",
      accessorKey: "id",
      cell: (info) => {
        const { id, mandate, has_address, checks_completed_date } =
          info.row.original;
        let path = `${mandate}/individuals/${id}`;
        if (!has_address) {
          path = `${mandate}/address/${id}`;
        } else if (checks_completed_date == null) {
          path = `${mandate}/run-checks/${id}`;
        }
        return (
          <StyledLink to={path}>
            <span
              style={{
                color: theme.colors.blue.primary,
              }}
            >
              View
            </span>
          </StyledLink>
        );
      },
    },
  ];

  const [columns] = useState([...cols]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([
    { id: "created_on", desc: true },
  ]);

  const getMandatesData = useIndividualsList(
    filterUserIdsUpdate,
    searchTermUpdate,
    filtersUpdate,
    pageIndex + 1,
    pageSize,
    sorting[0]?.desc ? "dsc" : "asc",
    sorting[0]?.id
  );

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    // if (filters) {
    setFiltersUpdate(filters);
    // }
    // if (searchTerm) {
    setSearchTermUpdate(searchTerm);
    // }
    // if (filterUserIds) {
    setFilterUserIdsUpdate(filterUserIds);
    // }
  }, [searchTerm, searchTermUpdate, filterUserIds, filters]);

  useEffect(() => {
    setData(() => getMandatesData?.data?.data);
  }, [getMandatesData]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data?.results ?? [],
    columns,
    state: {
      pagination,
      sorting,
    },
    pageCount: Math.ceil(data?.count / pageSize) ?? -1,
    debugTable: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      {getMandatesData.isFetching && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {!getMandatesData.isFetching && data?.results?.length === 0 && (
        <Paragraph m={3} color="gray.40" textAlign="center">
          No Checks
        </Paragraph>
      )}

      {!getMandatesData.isFetching && data?.results?.length > 0 && (
        <div
          className="tableContainer"
          style={{ height: "calc(100vh - 191px)" }}
        >
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "select-none cursor-pointer flex items-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: (
                                <Icon
                                  display="inline-block"
                                  ml={1}
                                  size="20px"
                                  color="gray.40"
                                  Type={FaSortDown}
                                ></Icon>
                              ),
                              desc: (
                                <Icon
                                  display="inline-block"
                                  ml={1}
                                  size="20px"
                                  color="gray.40"
                                  Type={FaSortUp}
                                ></Icon>
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Flex
        className="pagination"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <span>
            Rows per page &nbsp;&nbsp;
            <select
              className="pageSizeDropdown"
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
            >
              {[5, 10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </span>
        </Flex>

        <Flex>
          <span style={{ display: "flex", alignItems: "center" }}>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </span>
          &nbsp;&nbsp;&nbsp;
          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.setPageIndex(0)}
          >
            &lt;&lt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
          >
            &lt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            &gt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          >
            &gt;&gt;
          </button>
        </Flex>
      </Flex>
    </div>
  );
};
