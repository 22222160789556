import { Navigate } from "react-router-dom";
import { Box } from "../components/Box";
import { Flex } from "../components/Flex";
import { SIDEBAR_WIDTH } from "../components/layoutConfig";
import Sidebar from "../components/Sidebar";
import { useAuth, UserVerificationStatus } from "../context/AuthContext";
import { ROUTE } from "../routes";

const AuthenticatedRoute = ({ children, showMenu = true }: any) => {
  const { isLoggedIn, userVerificationStatus } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to={ROUTE.LOGIN} replace />;
  }

  if (
    userVerificationStatus === UserVerificationStatus.EmailVerificationRequired
  ) {
    return <Navigate to={ROUTE.VERIFY} replace />;
  }

  if (
    userVerificationStatus ===
    UserVerificationStatus.CompanyRegistrationRequired
  ) {
    return <Navigate to={ROUTE.ADD_COMPANY} replace />;
  }

  if (userVerificationStatus === UserVerificationStatus.BillingInfoRequired) {
    return <Navigate to={ROUTE.ADD_BILLING_INFORMATION} replace />;
  }

  if (userVerificationStatus === UserVerificationStatus.PrimaryPurposeRequired) {
    return <Navigate to={ROUTE.ADD_PRIMARY_PURPOSE} replace />;
  }

  if (userVerificationStatus === UserVerificationStatus.DPORequired) {
    return <Navigate to={ROUTE.ADD_DPO} replace />;
  }

  if (userVerificationStatus === UserVerificationStatus.AccountPending) {
    return <Navigate to={ROUTE.ACCOUNT_PENDING} replace />;
  }

  return (
    isLoggedIn &&
    userVerificationStatus !== undefined && (
      <Flex>
        {showMenu && <Sidebar />}
        <Box
          bg={showMenu ? "background" : "transparent"}
          ml={showMenu ? SIDEBAR_WIDTH : "0px"}
          width={showMenu ? `calc(100% - ${SIDEBAR_WIDTH}px)` : "100%"}
          minHeight="100vh"
        >
          {children}
        </Box>
      </Flex>
    )
  );
};

export default AuthenticatedRoute;
