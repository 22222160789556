import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import { queryClient } from "./queryClient";
import ErrorBoundary from "./pages/ErrorBoundary";
import { MandateStatusProvider } from "./context/MandateStatusContext";
import { AxiosInterceptor } from "./data/axios";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthProvider>
        <AxiosInterceptor>
          <MandateStatusProvider>
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </ThemeProvider>
            <ToastContainer />
            <ReactQueryDevtools initialIsOpen={false} position="top-left" />
          </MandateStatusProvider>
        </AxiosInterceptor>
      </AuthProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
