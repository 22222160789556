import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { form } from "../types/Forms";

interface FormResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: form[];
  }

const fetch = (authHeader: string) => () =>
  axios.get<FormResponse>(
    `${env.verifyServiceUrl}individual/form/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useForms = () => {
  const { authHeader } = useAuth();
  return useQuery(["forms"], fetch(authHeader), {
    retry: false,
    select: (response) => response.data.results,
  });
};
