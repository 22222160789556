import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useMutation } from "@tanstack/react-query";

export const useSOFScoreReset = () => {
  const { authHeader } = useAuth();

  return useMutation(() => {
    return axios.get(
      `${env.verifyServiceUrl}individual/sof_risk_level/reset/`,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
