import ReactDateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { Box, BoxProps } from "../Box";
import { Label } from "../Label";
import "./styles.css";
import "react-calendar/dist/Calendar.css";

type DateRangePickerProps = BoxProps & {
  value: Date[];
  onChange: (value: any) => void;
  name?: string;
  label?: string;
};

export const DateRangePicker = (props: DateRangePickerProps) => {
  const { value, label, onChange, ...styleProps } = props;

  const handleChange = (value: any) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    if (startDate > endDate) {
      value[1] = null;
    }
    onChange(value);
  };

  return (
    <Box {...styleProps}>
      {label && <Label>{label}</Label>}
      <ReactDateRangePicker
        onChange={handleChange}
        value={value}
        showLeadingZeros
        locale="en-GB"
        className="react-date-range-picker"
      />
    </Box>
  );
};
