export interface TenantFilter {
  by_dob: {
    include_all_dob: boolean;
    include_full_dob: boolean;
    include_year_only_dob: boolean;
    include_year_only_dob_tolerance: number;
    exclude_if_source_dob_unknown: boolean;
  };
  by_name: {
    name_match_type: string;
    name_match_method: string;
    name_match_method_tolerance: number;
  };
  by_gender: {
    exclude_gender_match: boolean;
    exclude_if_source_gender_unknown: boolean;
  };
  by_pep_type: {
    include_all_pep_types: boolean;
    exclude_pep_types: [];
    exclude_pep_by_family_association: boolean;
  };
  by_adverse_media_type: {
    include_all_adverse_media_types: boolean;
    exclude_adverse_media_types: [];
  };
}

export enum NameMatchMethod {
  Exact = "Exact",
  Fuzzy = "Fuzzy",
}

export enum NameMatchType {
  Full = "Full",
  Surname = "Surname",
}

export enum Subcategory {
  critical_adverse_media_types = "Critical adverse media types",
  investigate_types = "Investigate types",
  probative_types = "Probative types",
  valuable_adverse_media_types = "Valuable adverse media types",
}

export interface TenantFilterPayload {
  exclude_gender_match: boolean;
  exclude_if_source_dob_unknown: boolean;
  include_all_dob: boolean;
  include_year_only_dob: boolean;
  include_year_only_dob_tolerance: number;
  name_match_method: string;
  name_match_method_tolerance: number;
  name_match_type: string;
  include_full_dob: boolean;
  exclude_pep_types: string[];
  exclude_adverse_media_types: string[];
  // exclude_pep_by_family_association: boolean;
  include_all_adverse_media_types: boolean;
  include_all_pep_types: boolean;
}

export interface FilterOption {
  id: number;
  name: string;
  label: string;
  category: string;
  subcategory: string | null;
}
