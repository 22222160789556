import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { Country } from "../types/Country";
import { env } from "../utils/env";


const fetch = () =>
  axios.get<Country[]>(`${env.verifyServiceUrl}common/country/`);

export const useCountries = () => {
  return useQuery(["countries"], fetch, {retry: false});
};
