import { useAuth } from "../../context/AuthContext";
import { Formik, Form } from "formik";
import { H1, H2 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Navigate } from "react-router-dom";
import { ROUTE } from "../../routes";
import { Checkbox } from "../../components/Checkbox";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { useState } from "react";
import RadioButton from "../../components/Radio";
import { Flex } from "../../components/Flex";

export const PrimaryPurposeStep = ({ onSubmit }: any) => {
  const { isLoggedIn } = useAuth();
  const [selectedOption, setSelectedOption] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);

  /** User is not ready for this step of the flow. */
  if (!isLoggedIn) {
    return <Navigate to={ROUTE.SIGN_UP} replace />;
  }

  function handleCheckboxChange(event) {
    const elements = document.getElementsByName(
      "checkbox"
    ) as NodeListOf<HTMLInputElement>;
    let checkedCount = 0;
    elements.forEach((element) => {
      if (element.checked) {
        checkedCount++;
      }
    });
    if (checkedCount >= 1) {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          kyc: false,
          aml_and_cft: false,
          data_accuracy: false,
          identity_fraud_prevention: false,
          product_restriction: false,
          improve_efficiency_speed: false,
          dpo: null,
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <H1 color="darkBlue">Primary purpose details</H1>
            <Paragraph color="darkBlue">
              Select one or more primary purpose for using our services.
            </Paragraph>

            <Checkbox
              onChange={(event) => {
                handleCheckboxChange(event);
                props.setFieldValue("kyc", event.target.checked);
              }}
              label={
                <Paragraph mt="0px">
                  As part of Know your Customer (KYC) to determine customer risk
                  and acceptability which includes:
                  <br /> a. Customer Identification Program (CIP)
                  <br /> b. Customer Due Diligence (CDD)
                  <br /> c. Ongoing Monitoring
                </Paragraph>
              }
              mt="2"
              alignValue="start"
              isChecked={props.values.kyc}
              name="checkbox"
            />

            <Checkbox
              onChange={(event) => {
                handleCheckboxChange(event);
                props.setFieldValue("aml_and_cft", event.target.checked);
              }}
              label={
                <Paragraph mt="0px">
                  Compliance with Anti-Money Laundering (AML) & Countering the
                  Financing of Terrorism (CFT)
                </Paragraph>
              }
              mt="2"
              alignValue="start"
              name="checkbox"
              isChecked={props.values.aml_and_cft}
            />

            <Checkbox
              onChange={(event) => {
                handleCheckboxChange(event);
                props.setFieldValue("data_accuracy", event.target.checked);
              }}
              label={
                <Paragraph mt="0px">
                  Compliance with Data Privacy (Accuracy of data held)
                </Paragraph>
              }
              mt="2"
              alignValue="start"
              name="checkbox"
              isChecked={props.values.data_accuracy}
            />

            <Checkbox
              onChange={(event) => {
                handleCheckboxChange(event);
                props.setFieldValue(
                  "identity_fraud_prevention",
                  event.target.checked
                );
              }}
              label={
                <Paragraph mt="0px">Prevention of Identity Fraud</Paragraph>
              }
              mt="2"
              alignValue="start"
              name="checkbox"
              isChecked={props.values.identity_fraud_prevention}
            />

            <Checkbox
              onChange={(event) => {
                handleCheckboxChange(event);
                props.setFieldValue(
                  "product_restriction",
                  event.target.checked
                );
              }}
              label={
                <Paragraph mt="0px">
                  Restrictions related to the Product or Service being offered
                  to the customer (e.g. Geographic/Regional restrictions)
                </Paragraph>
              }
              mt="2"
              alignValue="start"
              name="checkbox"
              isChecked={props.values.product_restriction}
            />
            <Checkbox
              onChange={(event) => {
                handleCheckboxChange(event);
                props.setFieldValue(
                  "improve_efficiency_speed",
                  event.target.checked
                );
              }}
              label={
                <Paragraph mt="0px">
                  Improving the speed and efficiency of onboarding remote
                  customers
                </Paragraph>
              }
              mt="2"
              alignValue="start"
              name="checkbox"
              isChecked={props.values.improve_efficiency_speed}
            />
            <H2 color="darkBlue">
              Do you have a Data Protection Officer (DPO)?
            </H2>

            <Flex justifyContent="space-between">
              <Box flex={0.3}>
                <RadioButton
                  label="Yes"
                  value="true"
                  checked={selectedOption === "true"}
                  onChange={(event) => {
                    setSelectedOption(event.target.value);
                    props.setFieldValue("dpo", true);
                  }}
                />
              </Box>
              <Box flex={1}>
                <RadioButton
                  label="No"
                  value="false"
                  checked={selectedOption === "false"}
                  onChange={(event) => {
                    setSelectedOption(event.target.value);
                    props.setFieldValue("dpo", false);
                  }}
                />
              </Box>
            </Flex>

            <Box>
              <Button
                size="large"
                width="100%"
                mt="2"
                mb="0"
                type="submit"
                isDisabled={
                  !props.isValid ||
                  !props.dirty ||
                  props.values.dpo == null ||
                  !btnStatus
                }
              >
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
