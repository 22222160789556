import { Flex } from "../components/Flex";
import { Box } from "../components/Box";
import { Image } from "../components/Image";
import { H1 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Button } from "../components/Button";
import tillerVerifySvg from "../assets/verify-tiller.svg";
import notFound from "../assets/not-found.svg";
import { Link } from "react-router-dom";
import { ROUTE } from "../routes";

const NotFound = () => {
  return (
    <Flex height="100vh">
      <Flex
        width="900px"
        justifyContent="space-between"
        flexDirection="column"
        p="60px"
      >
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="80px"
          width="100px"
        />
        <Box>
          <H1 color="darkBlue">
            Looks like you're lost, this page is not found!
          </H1>
          <Link to={ROUTE.ROOT}>
            <Button size="large" width="100%" mt="4">
              Go back to website
            </Button>
          </Link>
        </Box>
        <Box />
      </Flex>
      <Flex
        bg={"background"}
        height="100vh"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        pt="5"
      >
        <Box width="55%">
          <H1 color="darkBlue">404, page not found!</H1>
          <Paragraph color="gray.50" fontSize="3">
            The page you are looking for might have been removed.
          </Paragraph>
        </Box>
        <Image src={notFound} alt="folder" display="block" maxWidth="60%" />
      </Flex>
    </Flex>
  );
};

export default NotFound;
