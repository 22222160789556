import { Box } from "../Box";
import { DateRangePicker } from "../DateRangePicker/index";
import { Paragraph } from "../Paragraph";
import { Slider } from "../Slider";
import { Form, Formik } from "formik";
import Select from "../Select";
import { Button } from "../Button";
import { capitalise } from "../../utils/string";
import { DatePicker } from "../DatePicker";
import { Flex } from "../Flex";
import { useMonitoringStatus } from "../../data/useMonitoringStatus";

type FiltersProps = {
  isOpen?: boolean;
  onRequestClose: () => void;
  filters: any;
  onSubmit: (values: any) => void;
};

const initialState = {
  created_on_date: [null, null],
  last_activity_date:[null, null],
};

export const MonitoringDateFilters = (props: FiltersProps) => {
  const { isOpen, onRequestClose, onSubmit, filters } = props;
  const { data } = useMonitoringStatus();
  const statusOptions = data?.data?.map(
    ({id, status }) => ({
      value: id,
      label: `${status}`,
    })
  );


  return (
    <Slider isOpen={isOpen} onRequestClose={onRequestClose}>
      <Box p={3} overflowY="auto" height={"calc(100vh - 138px)"}>
        <Formik
          initialValues={filters}
          onSubmit={(values, helpers) => {
            onSubmit(values);
            helpers.resetForm({ values });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Paragraph as="h2" fontSize={3} mt="0px" display="flex">
                Filters
                <Button
                  type="reset"
                  px={2}
                  variant="text"
                  size="small"
                  position="absolute"
                  top="26px"
                  right="75px"
                  onClick={() => {
                    props.resetForm({ values: initialState });
                    props.submitForm();
                  }}
                >
                  Clear All
                </Button>
              </Paragraph>
              <Select
                  onChange={(option: any) => {
                    props.setFieldValue("status", option.value);
                  }}
                  getOptionLabel={(options) => {
                    return (
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        position="relative"
                        width={"100%"}
                      >
                        <span>
                          {capitalise(options.label)}
                        </span>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          position="relative"
                        >
                        </Flex>
                      </Flex>
                    );
                  }}
                  value={
                    statusOptions?.find(
                      ({ value }) => value === props.values.status
                    ) || { label: "All", value: "all" }
                  }
                  options={statusOptions}
                  placeholder="Select status"
                  label="Status"
                  name="status"
                />

              <DateRangePicker
                value={props.values.created_on_date}
                onChange={(option: any) =>
                  props.setFieldValue("created_on_date", option)
                }
                mt={3}
                label="Date Started"
                name="created_on_date"
              />
              <DateRangePicker
                value={props.values.last_activity_date}
                onChange={(option: any) =>
                  props.setFieldValue("last_activity_date", option)
                }
                mt={3}
                label="Last Activity"
                name="last_activity_date"
              />
              {props.dirty && (
                <Button type="submit" mt="2" size="large" width="100%">
                  Apply
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Slider>
  );
};
