import React from "react";
import { Box } from "../../components/Box";
import { Input, InputProps } from "../../components/Input";
import { Flex } from "../../components/Flex";
import { CheckAddress } from "../../types/CheckAddress";
import { Field, FormikProps } from "formik";

interface AddressLongFormProps {
  formik: FormikProps<CheckAddress>;
}

export const useAddressLongForm = () => {
  const getInitialValues = (address?: CheckAddress): CheckAddress => ({
    building_number: address?.building_number || "",
    flat_appartment_subbuilding: address?.flat_appartment_subbuilding || "",
    building_name: address?.building_name || "",
    road_street: address?.road_street || "",
    town_city: address?.town_city || "",
    district: address?.district || "",
    state_province_name: address?.state_province_name || "",
    state_province_code: address?.state_province_code || "",
    post_zip_code: address?.post_zip_code || "",
    country_code: address?.country_code || "",
  });

  const rowProps: any = {
    flexWrap: "wrap",
    gap: "16",
    my: "4",
  };

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  const AddressLongForm: React.FC<AddressLongFormProps> = ({ formik }) => {
    return (
      <Box>
        <Flex {...rowProps}>
          <Field name="building_number">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                {...field}
                onBlur={() => formik.setFieldTouched(field.name)} // Trigger validation on blur
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                placeholder="Enter building number"
                label="Building number"
                hasError={
                  meta.touched && (formik.errors[field.name] || !!meta.error)
                }
                errorMessage={
                  meta.touched ? formik.errors[field.name] || meta.error : ""
                }
              />
            )}
          </Field>

          <Field name="flat_appartment_subbuilding">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                {...field}
                onBlur={() => formik.setFieldTouched(field.name)}
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                placeholder="Enter flat number"
                label="Flat number"
                hasError={
                  meta.touched && (formik.errors[field.name] || !!meta.error)
                }
                errorMessage={
                  meta.touched ? formik.errors[field.name] || meta.error : ""
                }
                // hasError={meta.touched && !!meta.error}
                // errorMessage={meta.touched ? meta.error : ""}
              />
            )}
          </Field>
        </Flex>

        <Flex {...rowProps}>
          <Field name="building_name">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                onBlur={() => formik.setFieldTouched(field.name)}
                {...field}
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                placeholder="Enter building name"
                label="Building name"
                // hasError={meta.touched && !!meta.error}
                // errorMessage={meta.touched ? meta.error : ""}
                hasError={
                  meta.touched && (formik.errors[field.name] || !!meta.error)
                }
                errorMessage={
                  meta.touched ? formik.errors[field.name] || meta.error : ""
                }
              />
            )}
          </Field>

          <Field name="road_street">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                onBlur={() => formik.setFieldTouched(field.name)}
                {...field}
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                label="Street"
                placeholder="Enter street"
                name="road_street"
                hasError={
                  meta.touched && (formik.errors[field.name] || !!meta.error)
                }
                errorMessage={
                  meta.touched ? formik.errors[field.name] || meta.error : ""
                }
              />
            )}
          </Field>
        </Flex>
        <Flex {...rowProps}>
          <Field name="town_city">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                {...field}
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                label="Town/city"
                placeholder="Enter town/city"
                name="town_city"
                hasError={meta.touched && !!meta.error}
                errorMessage={meta.touched ? meta.error : ""}
              />
            )}
          </Field>

          <Field name="district">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                {...field}
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                label="Country/district"
                placeholder="Enter country/district"
                name="district"
                hasError={meta.touched && !!meta.error}
                errorMessage={meta.touched ? meta.error : ""}
              />
            )}
          </Field>
        </Flex>
        <Flex {...rowProps}>
          <Field name="state_province_name">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                {...field}
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                placeholder="Enter province name"
                label="Province name"
                name="state_province_name"
                hasError={meta.touched && !!meta.error}
                errorMessage={meta.touched ? meta.error : ""}
              />
            )}
          </Field>

          <Field name="state_province_code">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                {...field}
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                placeholder="Enter province code"
                label="Province code"
                name="state_province_code"
                hasError={meta.touched && !!meta.error}
                errorMessage={meta.touched ? meta.error : ""}
              />
            )}
          </Field>
        </Flex>
        <Flex {...rowProps}>
          <Field name="post_zip_code">
            {({ field, meta }) => (
              <Input
                {...inputProps}
                {...field}
                onChange={(e) =>
                  formik.setFieldValue(field.name, e.target.value)
                }
                placeholder="Enter post or ZIP code"
                label="Post/ZIP code"
                name="post_zip_code"
                hasError={meta.touched && !!meta.error}
                errorMessage={meta.touched ? meta.error : ""}
              />
            )}
          </Field>
        </Flex>
      </Box>
    );
  };

  return { AddressLongForm, getInitialValues };
};

export default useAddressLongForm;
