import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { MandateStatus } from "../types/MandateStatus";
import { env } from "../utils/env";

const fetch = (authHeader: string) => () =>
  axios.get<MandateStatus[]>(
    `${env.verifyServiceUrl}common/mandate_status_list/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useMandateStatuses = () => {
  const { authHeader, isLoggedIn } = useAuth();
  return useQuery(["mandateStatuses"], fetch(authHeader), { enabled: isLoggedIn, retry: false } );
};
