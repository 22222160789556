import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";

const fetchImages = (authHeader: string, urls: string[]) => async () => {
  if (urls[0] !== undefined && urls[0] !== "") {
    const images = await axios.all(
      urls.map((endpoint) =>
        axios.get(endpoint, {
          headers: {
            Authorization: authHeader,
          },
          responseType: "arraybuffer",
        })
      )
    );
    return images.map((res) =>
      window.btoa(
        new Uint8Array(res.data).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, "")
      )
    );
  }
};

export const useGetImages = (urls: string[]) => {
  const { authHeader } = useAuth();
  return useQuery(["getImages", urls], fetchImages(authHeader, urls), {
    enabled: !!urls && urls.length > 0 && urls[0] !== "",
    // retry: false,
  });
};
