import success from "../../assets/card-success-icon.svg";
import pending from "../../assets/card-indeterminate-icon-gray.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import manuallyRejected from "../../assets/manually_rejected.svg";
import manuallyAccepted from "../../assets/manually_accepted.svg";
import inProgress from "../../assets/card-indeterminate-icon-gray.svg";

export const checksStatusIconsMap = {
  review: inReview,
  pending: pending,
  accepted: success,
  manually_accepted: manuallyAccepted,
  manually_rejected: manuallyRejected,
};

export const checksStatusName = {
  review: "Review",
  pending: "Pending",
  accepted: "Accepted",
  manually_accepted: "Manually Accepted",
  manually_rejected: "Manually Rejected",
};

export const checksIcons = {
  pending: inProgress,
  review: inReview,
  completed: success,
};

export const checksColor = {
  pending: "gray",
  review: "orange",
  completed: "green",
};

export const IndividualChecksMap = {
  address_verification_check: {
    name: "address_verification_check",
    title: "Address Check",
  },
  proof_of_address_check: {
    name: "proof_of_address_check",
    title: "Proof Of Address",
  },
  "pep_&_sanctions_check": {
    name: "pep_&_sanctions_check",
    title: "PEP & Sanctions",
  },
  bank_account_details_check: {
    name: "bank_account_details_check",
    title: "Bank Account",
  },
  personal_details_match_check: {
    name: "personal_details_match_check",
    title: "Personal Details",
  },
  "id_&_liveliness_check": {
    name: "id_&_liveliness_check",
    title: "Identity & Liveness Check",
  },
  geo_location_check: {
    name: "geo_location_check",
    title: "Geolocation Check",
  },
  adverse_media_check: {
    name: "adverse_media_check",
    title: "Adverse Media",
  },
  source_of_funds_check: {
    name: "source_of_funds_check",
    title: "Source Of Funds Check",
  },
};
