import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Box } from "../../../components/Box";
import { Button } from "../../../components/Button";
import { Flex } from "../../../components/Flex";
import { H2, H3 } from "../../../components/Heading";
import { Paragraph } from "../../../components/Paragraph";
import { ROUTE } from "../../../routes";
import { Image } from "../../../components/Image";
import editIcon from "../../../assets/settings/edit.svg";
import { useForms } from "../../../data/useForms";
import { Loader } from "../../../components/Loader";

const SettingsForms = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useForms();

  const handleEdit = (id: number) => {
    navigate(`/settings/forms/${id}`);
  };

  return (
    <>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}
      {!isLoading && (
        <Box bg="white" borderRadius="16px" p={5} overflow="hidden">
          <Flex justifyContent="space-between" alignItems="center">
            <H2 fontSize="3">Forms</H2>

            <Button
              Icon={FaPlus}
              iconSize="16px"
              onClick={() => navigate(ROUTE.SETTINGS_FORM_NEW)}
            >
              New Form
            </Button>
          </Flex>

          {data?.map((item) => (
            <Flex
              key={item.id}
              py={2}
              borderBottom="1px solid #e0e0e0"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <H3 fontSize="16px">{item.name}</H3>
                <Paragraph fontSize={1} my={0} color="gray.60">
                  {item.description}
                </Paragraph>
              </Box>
              <Box>
                <Image
                  src={editIcon}
                  alt="edit icon"
                  height="22px"
                  width="22px"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEdit(item.id)}
                />
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </>
  );
};

export default SettingsForms;
