import { Formik, Form } from "formik";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { FormRow } from "../../components/Form";
import { H3 } from "../../components/Heading";
import { Input, InputProps } from "../../components/Input";
import { Loader } from "../../components/Loader";
import { useAuth } from "../../context/AuthContext";
import { useUpdateUserDetails } from "../../data/useUpdateUserDetails";
import { FeatureFlagKey, isEnabled } from "../../feature-flags";
import { queryClient } from "../../queryClient";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";

export const ProfileUserDetails = () => {
  const { mutate: updateUser, isLoading: isLoadingUpdate } =
    useUpdateUserDetails();
  const { userDetails, isLoadingUserDetails } = useAuth();

  const handleSubmit = (data) => {
    const payload = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      job_title: data.jobTitle,
      password: data.password,
    };

    updateUser(
      {
        id: userDetails.id,
        ...payload,
      },
      {
        onSuccess: () => {
          notify("User Updated", {
            type: "success",
          });
          queryClient.invalidateQueries(["userDetails"]);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while updating the user.");
          // displayError(
          //   collapseErrors(extractErrors(err)) ||
          //     "A problem occurred while updating the user."
          // );
          // logError(err);
        },
      }
    );
  };

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
    isDisabled: !isEnabled(FeatureFlagKey.CAN_EDIT_PROFILE),
  };

  return (
    <Box bg="white" borderRadius={0} p={5} maxWidth={900}>
      <H3>My Info</H3>

      {isLoadingUserDetails && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {!isLoadingUserDetails && (
        <Formik
          initialValues={{
            firstName: userDetails?.first_name || "",
            lastName: userDetails?.last_name || "",
            email: userDetails?.email || "",
            jobTitle: userDetails?.job_title || "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Box>
                <FormRow my={3}>
                  <Input
                    onChange={props.handleChange}
                    value={props.values.firstName}
                    placeholder="Enter your first name"
                    label="First name"
                    name="firstName"
                    {...inputProps}
                  />
                  <Input
                    onChange={props.handleChange}
                    value={props.values.lastName}
                    placeholder="Enter your last name"
                    label="Last name"
                    name="lastName"
                    {...inputProps}
                  />
                </FormRow>
                <FormRow my={3}>
                  <Input
                    onChange={props.handleChange}
                    value={props.values.email}
                    placeholder="Enter your email address"
                    label="Email Address"
                    name="email"
                    {...inputProps}
                  />
                  <Input
                    onChange={props.handleChange}
                    value={props.values.jobTitle}
                    placeholder="Enter your job title"
                    label="Job Title"
                    name="jobTitle"
                    {...inputProps}
                  />
                </FormRow>
              </Box>
              {isEnabled(FeatureFlagKey.CAN_EDIT_PROFILE) && (
                <Box>
                  <Button
                    type="submit"
                    size="large"
                    width="100%"
                    mt="4"
                    mb="0"
                    isDisabled={isLoadingUpdate}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      )}
      <Box />
    </Box>
  );
};
