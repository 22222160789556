import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes";
import styled from "styled-components";
import { Loader } from "../../components/Loader";
import { Flex } from "../../components/Flex";
import { Box } from "../../components/Box";
import { H2 } from "../../components/Heading";
import { Button } from "../../components/Button";
import { FaPlus } from "react-icons/fa";
import { ApplicationTypeCard } from "../../components/ApplicationTypeCard";
import { useMandateTypes } from "../../data/useMandateTypes";
import { MandateType } from "../../types/MandateType";
import { useBillingInfo } from "../../data/useBillingInfo";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Icon } from "../../components/Icon";
import { Tooltip } from "../../components/Tooltip";
import { Paragraph } from "../../components/Paragraph";
import { Link } from "../../components/Link";
import { env } from "../../utils/env";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  ${({ theme: { colors, radii, space } }) => `
  grid-gap: ${space[3]};
  overflow: hidden;
  margin-top: 32px;
  `}
`;

const SettingsApplicationTypes = () => {
  const navigate = useNavigate();
  const { data: mandateTypeQuery, isLoading: mandateLoading } =
    useMandateTypes();
  const { data: billingData, isLoading: billingInfoLoading } = useBillingInfo();

  const mandateTypes: MandateType[] = mandateTypeQuery?.data || [];

  // Safeguard to check if billingData and nested objects are available
  const billingInfo =
    billingData?.data?.results?.length > 0
      ? billingData?.data?.results[0]
      : null;

  // Check if either of the data calls are still loading
  const isLoading = mandateLoading || billingInfoLoading;

  return (
    <Box bg="white" borderRadius="16px" p={5} overflow="hidden">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="8" alignItems={"center"}>
          <H2 fontSize="3">Application Types</H2>

          {!billingInfoLoading &&
            billingInfo?.mandate_type_created &&
            billingInfo?.mandate_type_limit && (
              <H2 color={"gray.60"} fontSize="3">
                {billingInfo.mandate_type_created}/
                {billingInfo.mandate_type_limit}
              </H2>
            )}

          <Tooltip
            position="bottom"
            content="Check our plans and upgrade in order to unlock additional checks that you can add to your application type."
          >
            <Icon Type={IoMdInformationCircleOutline} aria-hidden="true" />
          </Tooltip>
        </Flex>

        {billingInfo?.billing_type === "Basic" &&
        billingInfo?.mandate_type_created >= 2 ? (
          <Tooltip
            position="bottom"
            content="You can only create two applications. To create more templates, please upgrade your plan."
          >
            <Button
              Icon={FaPlus}
              iconSize="16px"
              onClick={() => navigate(ROUTE.SETTINGS_APPLICATION_TYPE_NEW)}
              isDisabled={
                billingInfo?.billing_type === "Basic" &&
                billingInfo?.mandate_type_created >= 2
              }
            >
              New Application Type
            </Button>
          </Tooltip>
        ) : (
          <Button
            Icon={FaPlus}
            iconSize="16px"
            onClick={() => navigate(ROUTE.SETTINGS_APPLICATION_TYPE_NEW)}
          >
            New Application Type
          </Button>
        )}
      </Flex>
      <Paragraph fontWeight="500"
        fontSize={1}
        color={"gray.60"} mt={"0"} mb={3}>Manage and customise application types to fit your specific business needs.  
        <Link fontSize="1" href= {env.verifyApplicationTypesLearnMoreUrl} target="_blank" ml={2}>
           Learn more
        </Link></Paragraph>

      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      ) : (
        <Grid>
          {mandateTypes?.map((applicationType) => (
            <ApplicationTypeCard
              key={applicationType.id}
              id={applicationType.id}
              name={applicationType.name}
              checkpoint={applicationType?.checkpoint}
              created_on={applicationType?.created_on}
              is_disabled={applicationType?.is_disabled}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default SettingsApplicationTypes;
