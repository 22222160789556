import { Formik, Form } from "formik";
import { Navigate } from "react-router-dom";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { H1 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { UserVerificationStatus, useAuth } from "../../context/AuthContext";
import { ROUTE } from "../../routes";
import { useAddressForm } from "../../utils/forms/useAddressForm";
import { companyAddressValidation } from "../../utils/validationSchema";
import { Checkbox } from "../../components/Checkbox";
import { useState } from "react";
import { useClientDetails } from "../../data/useClientDetails";
import { useUserDetails } from "../../data/useUserDetails";
import { Flex } from "../../components/Flex";
import { Loader } from "../../components/Loader";

export const BillingAddressStep = ({
  onSubmit,
  doubleClickBilling,
  doubleClick,
}: any) => {
  const { isLoggedIn, authHeader, userVerificationStatus } = useAuth();

  const { data: user, isLoading: isLoadingUserDetails } = useUserDetails(
    isLoggedIn,
    authHeader
  );

  const { data: client, isLoading } = useClientDetails(user?.data.tenant);

  const { AddressForm, getInitialValues } = useAddressForm();

  const [isChecked, setIsChecked] = useState(true);

  /** User is not ready for this step of the flow. */
  if (!isLoggedIn) {
    return <Navigate to={ROUTE.SIGN_UP} replace />;
  }

  if (
    userVerificationStatus === UserVerificationStatus.PrimaryPurposeRequired
  ) {
    return <Navigate to={ROUTE.ADD_PRIMARY_PURPOSE} replace />;
  }

  if (userVerificationStatus === UserVerificationStatus.DPORequired) {
    return <Navigate to={ROUTE.ADD_DPO} replace />;
  }

  const checkHandler = async (event, fields) => {
    setIsChecked(!isChecked);
    if (isChecked) {
      fields.setFieldValue("address_line_1", client.data.address_line_1);
      fields.setFieldValue("address_line_2", client.data.address_line_2);
      fields.setFieldValue("city", client.data.city);
      fields.setFieldValue("country", client.data.country);
      fields.setFieldValue("postcode", client.data.postcode);

      await fields.setFieldTouched("address_line_1", true, false);
      await fields.setFieldTouched("address_line_2", true, false);
      await fields.setFieldTouched("city", true, false);
      await fields.setFieldTouched("country", true, false);
      await fields.setFieldTouched("postcode", true, false);

      fields.validateForm();
    } else {
      fields.resetForm();
    }
  };

  return (
    <Box>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}
      {!isLoading && !isLoadingUserDetails && (
        <>
          <Formik
            initialValues={{
              same_as_company_address: false,
              ...getInitialValues(),
            }}
            validationSchema={companyAddressValidation}
            onSubmit={(values) => {
              doubleClick(true);
              onSubmit(values);
            }}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <H1 color="darkBlue">Billing address details</H1>
                <AddressForm {...props} />

                <Box>
                  <Button
                    size="large"
                    width="100%"
                    mt="2"
                    mb="0"
                    type="submit"
                    isDisabled={
                      !props.isValid || !props.dirty || doubleClickBilling
                    }
                  >
                    Next
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Box>
  );
};
