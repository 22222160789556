import { Industry } from "../../types/Industry";

export const industriesFallback = [
  {
    id: 1,
    name: "Accounting",
  },
  {
    id: 2,
    name: "Banking",
  },
  {
    id: 3,
    name: "Financial Services",
  },
  {
    id: 4,
    name: "Law",
  },
  {
    id: 5,
    name: "Wealth Management",
  },
  {
    id: 6,
    name: "Other",
  },
] as Industry[];
