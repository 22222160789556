import { Flex } from "../components/Flex";
import { Box } from "../components/Box";
import { Image } from "../components/Image";
import { H1 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Navigate } from "react-router-dom";
import signUpSvg from "../assets/sign-up.svg";
import tillerVerifySvg from "../assets/verify-tiller.svg";
import lock from "../assets/lock.svg";
import { useAuth, UserVerificationStatus } from "../context/AuthContext";
import { Button } from "../components/Button";
import { ROUTE } from "../routes";

const AccountPending = () => {
  const { logout, userVerificationStatus, isLoggedIn } = useAuth();

  /** User has already completed sign-up and verification. */
  if (!isLoggedIn || userVerificationStatus === UserVerificationStatus.Verified) {
    return <Navigate to={ROUTE.ROOT} replace />;
  }

  return (
    <Flex height="100vh">
      <Flex
        width="900px"
        justifyContent="space-between"
        flexDirection="column"
        p="60px"
      >
        <Box>
          <Image
            src={tillerVerifySvg}
            alt="tiller verify logo"
            height="80px"
            width="100px"
          />
          <H1 color="darkBlue" mt={4}>Welcome</H1>
          <Paragraph color="darkBlue">
            Thank you for setting up an account with Verify by Tiller
          </Paragraph>
          <Paragraph color="darkBlue" mt="3">
            One of our client relationship managers will be in contact shortly to complete the process.
          </Paragraph>
        </Box>
        <Box />

        <Button width="100%" onClick={() => logout()}>
          Finish & Log out
        </Button>
      </Flex>
      <Flex
        bg={"background"}
        height="100vh"
        width="100%"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
      >
        <Box width="55%">
          <Image src={lock} height="80px" width="100px" mb="60px" mt="2" />
          <H1 color="darkBlue">
            This is the first step to streamlining your KYC/AML process.
          </H1>
          <Paragraph color="gray.50" fontSize="3">
            We will be in contact shortly to complete your company profile and get you started.
          </Paragraph>
        </Box>
        <Image
          src={signUpSvg}
          alt="lock image"
          display="block"
          width="55%"
          height="60%"
        />
      </Flex>
    </Flex>
  );
};

export default AccountPending;
