import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  name: string;
  description: string;
  document_capture_type: number[];
}
export const useCreateAdditionalDocument = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(
      `${env.verifyServiceUrl}individual/additional_document/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
