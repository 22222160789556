import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLogin } from "../data/useLogin";
import { extractErrors } from "../utils/api";
import { logError } from "../utils/error-handling";
import { env } from "../utils/env";
import { DateTime } from "luxon";
import { useUserDetails } from "../data/useUserDetails";
import { User } from "../types/User";
import { queryClient } from "../queryClient";
import { ROUTE } from "../routes";
import axios from "axios";

export enum UserVerificationStatus {
  /** User has not completed sign-up, needs to verify email. */
  EmailVerificationRequired,

  /** User has not completed sign-up, needs to create company. */
  CompanyRegistrationRequired,

  /** User has not completed sign-up, needs to create billing. */
  BillingInfoRequired,

  /** User has not completed sign-up, needs to create primary purpose. */
  PrimaryPurposeRequired,

  /** User has not completed sign-up, needs to create dpo. */
  DPORequired,

  /** User has completed sign-up, Tiller doing manual checks. */
  AccountPending,

  /** User has completed sign-up, allow to navigate to all routes. */
  Verified,
}

const determineUserVerificationStatus = (user: User) => {
  let userVerificationStatus: UserVerificationStatus;

  if (user) {
    if (!user.is_confirmed) {
      userVerificationStatus = UserVerificationStatus.EmailVerificationRequired;
    } else if (!user.tenant) {
      userVerificationStatus =
        UserVerificationStatus.CompanyRegistrationRequired;
    } else if (user.is_pending) {
      if (!user.is_billinginfo_added) {
        userVerificationStatus = UserVerificationStatus.BillingInfoRequired;
      } else if (user.is_billinginfo_added && !user.is_primary_purpose_added) {
        userVerificationStatus = UserVerificationStatus.PrimaryPurposeRequired;
      } else if (
        user.is_billinginfo_added &&
        user.is_primary_purpose_added &&
        !user.is_dpo_added
      ) {
        userVerificationStatus = UserVerificationStatus.DPORequired;
      } else {
        userVerificationStatus = UserVerificationStatus.AccountPending;
      }
    } else {
      userVerificationStatus = UserVerificationStatus.Verified;
    }
  }

  return userVerificationStatus;
};

interface Credentials {
  email: string;
  accessToken: string;
  refreshToken: string;
}

interface Auth {
  credentials?: Credentials;
  isLoggedIn: boolean;
  login: {
    call: (email: string, password: string, redirectTo?: string) => void;
    isLoading: boolean;
    errors: any;
  };
  logout: () => void;
  authHeader: string;
  userDetails: User | undefined;
  userVerificationStatus: UserVerificationStatus;
  isLoadingUserDetails: boolean;
  isAdmin: boolean;
}

export const AUTH_COOKIE_NAME = env.cookieName;
export const AuthContext = createContext<Auth | null>(null);

export const AuthProvider = (props: any) => {
  const { children } = props;
  const [cookies, setCookie, removeCookie] = useCookies();

  const [credentials, setCredentials] = useState<Credentials | undefined>(
    cookies[AUTH_COOKIE_NAME]
  );

  const isLoggedIn =
    Boolean(credentials?.accessToken) && Boolean(credentials?.email);

  const authHeader = `Bearer ${credentials?.accessToken}`;

  const login = useLogin();
  const navigate = useNavigate();

  const { data: user, isLoading: isLoadingUserDetails } = useUserDetails(
    isLoggedIn,
    authHeader
  );

  let userVerificationStatus = determineUserVerificationStatus(user?.data);

  const callLogin: Auth["login"]["call"] = (email, password, redirectTo) => {
    return new Promise((resolve, reject) => {
      login.mutate(
        { email, password },
        {
          onSuccess: (res) => {
            resolve(res);
            if (res?.status?.toString().startsWith("2")) {
              const creds = {
                email,
                accessToken: res.data.access,
                refreshToken: res.data.refresh,
              };
              setCredentials(creds);
              setCookie(AUTH_COOKIE_NAME, creds, {
                expires: DateTime.now()
                  .plus({ milliseconds: env.cookieExpiryMs })
                  .toJSDate(),
                path: "/",
              });

              navigate(redirectTo || ROUTE.ROOT);
            }
          },
          onError: (err: any) => logError(err),
        }
      );
    });
  };

  const logout = () => {
    if (authHeader) {
      axios
        .post(
          `${env.verifyServiceUrl}user/logout/`,
          {},
          {
            headers: {
              Authorization: authHeader,
            },
          }
        )
        .then((res) => {
          setCredentials(undefined);
          queryClient.removeQueries();
          removeCookie(AUTH_COOKIE_NAME);
          navigate(ROUTE.ROOT);
        })
        .catch((err) => {
          logError(err);
        })
        .finally(() => {
          setCredentials(undefined);
          queryClient.removeQueries();
          removeCookie(AUTH_COOKIE_NAME);
          navigate(ROUTE.ROOT);
        });
    } else {
      setCredentials(undefined);
      queryClient.removeQueries();
      removeCookie(AUTH_COOKIE_NAME);
      navigate(ROUTE.ROOT);
    }
  };

  const value = {
    credentials,
    isLoggedIn,
    login: {
      call: callLogin,
      isLoading: login.isLoading,
      errors: extractErrors(login.error),
    },
    logout,
    authHeader,
    userDetails: user?.data,
    isAdmin: Boolean(user?.data?.groups?.find(({ name }) => name === "Admin")),
    isLoadingUserDetails,
    userVerificationStatus,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext) as Auth;
};
