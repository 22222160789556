import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { PagedResponse } from "../types/Common";
import { Individual } from "../types/Individual";
import { env } from "../utils/env";

//TODO Hardcoded pagination deemed that we wouldn't need as it's unlikely that there will be more than 25 individuals against a mandate
const fetchIndividuals = (authHeader: string, mandateId?: number) => () =>
  axios.get<PagedResponse<Individual[]>>(
    `${env.verifyServiceUrl}individual/mandate/${mandateId}/individual_list/?page=1`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useIndividuals = (mandateId?: number) => {
  const { authHeader } = useAuth();
  return useQuery(["individuals", mandateId], fetchIndividuals(authHeader, mandateId), {enabled: !!mandateId, retry: false});
};
