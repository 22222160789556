import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  id: number;
  action: string;
  comment: string;
  comment_for: string;
  isMonitoring: boolean;
}

export const useManualAcceptReject = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    const payload = {
      reference: params.id,
      comment: params.comment,
      comment_for: params.comment_for,
    };
    if(params.isMonitoring) {
      return axios.patch(
        `${env.verifyServiceUrl}individual/monitoring_activity/${params.id}/${params.action}/`,
        payload,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

    } else {
      return axios.patch(
        `${env.verifyServiceUrl}individual/check/${params.id}/${params.action}/`,
        payload,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
    }
   
  });
};
