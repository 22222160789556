import { useEffect, useRef } from "react";
import { Box } from "../Box";
import { Slider } from "../Slider";
import { Paragraph } from "../Paragraph";
import { Comment } from "../Comment";
import { useMandateComments } from "../../data/useMandateComments";
import React from "react";
import { Formik, Form } from "formik";
import { Button } from "../Button";
import { CommentTextArea } from "../CommentTextArea";
import { useInView } from "react-intersection-observer";
import { Flex } from "../Flex";
import { useCreateComment } from "../../data/useCreateComment";
import { useAuth } from "../../context/AuthContext";
import { showError } from "../../utils/error-handling";
import { queryClient } from "../../queryClient";
import { scrollToTopOfContainer } from "../../utils/scroll";

export const Comments = (props: any) => {
  const { referenceId, commentType, isOpen, onRequestClose } = props;
  const { userDetails } = useAuth();
  const { ref: messageBottomRef, inView } = useInView();
  const messagesRef = useRef(null);
  const { mutate: createComment } = useCreateComment();

  const {
    data: comments,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useMandateComments(referenceId, commentType);

  const resetMessageScroll = () => scrollToTopOfContainer(messagesRef);

  const handleSend = ({ comment }, { resetForm }) => {
    resetForm();

    createComment(
      {
        comment,
        comment_for: commentType,
        reference: referenceId,
        user: userDetails?.id,
      },
      {
        onError: (err: any) => {
          showError(err, "A problem occurred while send your message.");
        },
        onSuccess: () => {
          resetMessageScroll();
          queryClient.invalidateQueries([
            "mandateComments",
            referenceId,
            commentType,
          ]);
        },
      }
    );
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <>
      <Slider
        isOpen={isOpen}
        onRequestClose={() => onRequestClose(false)}
        width="420px"
      >
        <Box bg="white">
          <Box>
            <Paragraph
              as="h2"
              fontSize={3}
              mt="0px"
              borderBottom="1px solid"
              borderBottomColor="gray.20"
              mb="0px"
              p={3}
            >
              Comments ({comments?.pages?.[0]?.data?.count || 0})
            </Paragraph>
          </Box>
          <Box
            overflowY="auto"
            p={3}
            // Full height minus header and footer
            height="calc(100vh - 73px - 242px)"
            ref={messagesRef}
          >
            {comments?.pages?.map((page) => (
              <React.Fragment key={page.data.next}>
                {page.data.results.map((comment) => (
                  <Comment {...comment} key={comment?.id} />
                ))}
              </React.Fragment>
            ))}
            <Flex justifyContent="center">
              {hasNextPage && (
                <Button
                  ref={messageBottomRef}
                  variant="secondary"
                  color="blue.primary"
                  onClick={() => fetchNextPage()}
                  isDisabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? "Loading more..." : "Load More"}
                </Button>
              )}
            </Flex>
          </Box>
          <Box overflowY="auto" p={2}>
            <Formik
              initialValues={{
                comment: "",
              }}
              onSubmit={handleSend}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <CommentTextArea
                    onChange={props.handleChange}
                    value={props.values.comment}
                    placeholder="Write a comment..."
                    name="comment"
                    onSendClick={props.submitForm}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Slider>
    </>
  );
};
