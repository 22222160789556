import { MandateStatus } from "../context/MandateStatusContext";

export const TOPBAR_HEIGHT = 115;
export const SIDEBAR_WIDTH = 72;


export const MandateStatusColours: { [key in MandateStatus]: string } = {
    [MandateStatus.New]: 'gray.60',
    [MandateStatus.InProgress]: 'blue.primary',
    [MandateStatus.InReview]: 'orange.50',
    [MandateStatus.Completed]: 'green.primary',
    [MandateStatus.Rejected]: 'red.primary',
};