import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { PermissionGroup } from "../types/PermissionGroup";
import { env } from "../utils/env";
import { PagedResponse } from "../types/Common";

const fetch = (authHeader: string) => () =>
  axios.get<PagedResponse<PermissionGroup[]>>(`${env.verifyServiceUrl}user/groups/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const usePermissionGroups = () => {
  const { authHeader } = useAuth();
  return useQuery(["permissionGroups"], fetch(authHeader), {retry: false});
};
