import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { useBillingInfo } from "../../data/useBillingInfo";
import { Loader } from "../../components/Loader";
import { Formik, Form } from "formik";
import {
  billingInfoValidation,
  companyAddressValidation,
} from "../../utils/validationSchema";
import { useState } from "react";
import { useUpdateBillingDetails } from "../../data/useUpdateBillingDetails";
import { Button } from "../../components/Button";
import { Input, InputProps } from "../../components/Input";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { useAddressForm } from "../../utils/forms/useAddressForm";
import { Address } from "../../types/Address";
import { queryClient } from "../../queryClient";
import { BillingDetails } from "../../types/billingInfo";
import { useDirtyForm } from "./DirtyFormContext";
import { useCreateBillingInfo } from "../../data/useCreateBillingInfo";

const SettingsBillingDetails = () => {
  const { data: billingData, isLoading } = useBillingInfo();
  const [doubleClick, setDoubleClick] = useState(false);
  const { mutate: updateBillingDetails, isLoading: isUpdatingBillingDetails } =
    useUpdateBillingDetails();

  const { mutate: createBillingInfo } = useCreateBillingInfo();

  const { AddressForm, getInitialValues: getAddressInitialValues } =
    useAddressForm(true);
  const { setFormDirty, formRef } = useDirtyForm();

  // TODO make dynamic
  // const cardNumber = "8436";

  const handleSubmit = (data: BillingDetails) => {
    setDoubleClick(true);
    setFormDirty(false); // Reset the form dirty state before submitting

    if (
      billingData.data.results.length > 0 &&
      billingData?.data?.results[0].id
    ) {
      updateBillingDetails(
        {
          id: billingData?.data.results[0]?.id,
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          phone_number: data.telephone,
          tax_registeration_number: data.taxRegNumber,
        },
        {
          onSuccess: (res) => {
            setDoubleClick(false);
            if (res?.status?.toString().startsWith("2")) {
              notify("Billing details updated", {
                type: "success",
              });
              queryClient.invalidateQueries(["billingInfoData"]);
            }
          },
          onError: (err: any) => {
            setDoubleClick(false);
            showError(
              err,
              "A problem occurred while updating the billing details."
            );
          },
        }
      );
    } else {
      createBillingInfo(
        {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          phone_number: data.telephone,
          tax_registeration_number: data.taxRegNumber,
          same_as_company_address: true
        },
        {
          onSuccess: (res) => {
            setDoubleClick(false);
            if (res?.status?.toString().startsWith("2")) {
              notify("Billing details updated", {
                type: "success",
              });
              queryClient.invalidateQueries(["billingInfoData"]);
            }
          },
          onError: (err: any) => {
            setDoubleClick(false);
            showError(
              err,
              "A problem occurred while updating the billing details."
            );
          },
        }
      );
    }
  };

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  const rowProps: any = {
    flexWrap: "wrap",
    gap: "16",
    my: "4",
  };

  const handleAddressSubmit = (data: Address) => {
    setDoubleClick(true);
    setFormDirty(false); // Reset the form dirty state before submitting

    updateBillingDetails(
      {
        id: billingData?.data.results[0]?.id,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        country: data.country,
        postcode: data.postcode,
      },
      {
        onSuccess: (res) => {
          setDoubleClick(false);
          if (res?.status?.toString().startsWith("2")) {
            notify("Billing address updated", {
              type: "success",
            });
            queryClient.invalidateQueries(["billingInfoData"]);
          }
        },
        onError: (err: any) => {
          setDoubleClick(false);
          showError(
            err,
            "A problem occurred while updating the billing address."
          );
        },
      }
    );
  };

  return (
    <>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <H2>Billing contact details</H2>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                firstName: billingData?.data?.results[0]?.first_name || "",
                lastName: billingData?.data?.results[0]?.last_name || "",
                email: billingData?.data?.results[0]?.email || "",
                telephone: billingData?.data?.results[0]?.phone_number || "",
                taxRegNumber:
                  billingData?.data?.results[0]?.tax_registeration_number || "",
              }}
              enableReinitialize
              validate={() => null}
              validationSchema={billingInfoValidation}
              onSubmit={(values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false); // Make sure to set submitting to false
              }}
              innerRef={formRef} // Assign formRef to the Formik form
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Box>
                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.firstName}
                        placeholder="Enter first name"
                        label="First name"
                        name="firstName"
                        {...inputProps}
                        isRequired
                        hasError={Boolean(props.errors.firstName)}
                        errorMessage={props.errors.firstName}
                      />
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.lastName}
                        placeholder="Enter last name"
                        label="Last name"
                        name="lastName"
                        {...inputProps}
                        isRequired
                        hasError={Boolean(props.errors.lastName)}
                        errorMessage={props.errors.lastName}
                      />
                    </Flex>

                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.email}
                        placeholder="Enter email address"
                        label="Email address"
                        name="email"
                        isRequired
                        {...inputProps}
                        hasError={Boolean(props.errors.email)}
                        errorMessage={props.errors.email}
                      />
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.telephone}
                        placeholder="Enter telephone number"
                        label="Telephone number"
                        name="telephone"
                        isRequired
                        {...inputProps}
                        hasError={Boolean(props.errors.telephone)}
                        errorMessage={props.errors.telephone}
                      />
                    </Flex>

                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.taxRegNumber}
                        placeholder="Enter Tax registration number"
                        label="Tax registration number (VAT/ISE)"
                        name="taxRegNumber"
                        {...inputProps}
                        hasError={Boolean(props.errors.taxRegNumber)}
                        errorMessage={props.errors.taxRegNumber}
                      />
                    </Flex>
                  </Box>

                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          isUpdatingBillingDetails ||
                          doubleClick
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>

      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <H2>Billing address</H2>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                ...getAddressInitialValues({
                  address_line_1:
                    billingData?.data?.results[0]?.address_line_1 || "",
                  address_line_2:
                    billingData?.data?.results[0]?.address_line_2 || "",
                  city: billingData?.data?.results[0]?.city || "",
                  country: billingData?.data?.results[0]?.country || "",
                  postcode: billingData?.data?.results[0]?.postcode || "",
                }),
              }}
              enableReinitialize
              validate={() => null}
              validationSchema={companyAddressValidation}
              onSubmit={(values, actions) => {
                handleAddressSubmit(values);
                actions.setSubmitting(false); // Make sure to set submitting to false
              }}
              innerRef={formRef}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <AddressForm {...props} />
                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          isUpdatingBillingDetails ||
                          doubleClick
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </>

    // <Box bg="white" borderRadius={0} p={5}>
    //   <H2>Billing details</H2>
    //   <Paragraph>
    //     Bank account details where service charges are deducted.
    //   </Paragraph>

    //   <Paragraph mt={5} fontWeight="bold">
    //     Primary
    //   </Paragraph>
    //   <Flex bg="background" p={4} borderRadius={0} alignItems="center">
    //     <Icon color="gray.50" size="24" Type={FaCreditCard} />
    //     <Paragraph m="0px" ml={2}>
    //       Bank account ending in {cardNumber}
    //     </Paragraph>
    //   </Flex>
    // </Box>
  );
};

export default SettingsBillingDetails;
