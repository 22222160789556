import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  kyc: boolean;
  aml_and_cft: boolean; // TODO will probably be split into separate fields
  data_accuracy: boolean;
  identity_fraud_prevention: boolean;
  product_restriction: boolean;
}

export const useCreatePrimaryPurpose = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(`${env.verifyServiceUrl}user/primary_purpose/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
