import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";

export const useSignUpUser = () => {
  return useMutation(({ firstName, lastName, email, password }: any) => {
    return axios.post(
        `${env.verifyServiceUrl}user/user/`,
      {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      }
    );
  });
};
