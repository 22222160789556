import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { User } from "../types/User";
import { env } from "../utils/env";

const fetchUser = (authHeader: string) => () =>
  axios.get<User>(`${env.verifyServiceUrl}user/details/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useUserDetails = (enabled: boolean, authHeader: string) => {
  return useQuery(["userDetails"], fetchUser(authHeader), {
    enabled, retry: false
  });
};
