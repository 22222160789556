import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";

export const useCreatePassword = () => {
  return useMutation(({ password, link }: { password: string, link: string }) => {
    return axios.post(`${env.verifyServiceUrl}user/forgot_password/${link}/create_password/`, {
      new_password: password,
      confirm_password: password,
    });
  });
};
