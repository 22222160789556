import styled from "styled-components";
import {
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  PositionProps,
  color,
  layout,
  position,
  space,
} from "styled-system";

type BadgeProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  PositionProps & {
    /** Must be a styled-system color key. */
    children: any;
  };

const StyledBadge: any = styled.div`
  ${({ theme: { fontSizes, colors, radii } }) => `
      position: relative;
      font-family: Inter sans-serif;
      display: inline-flex;
      flex: 0 0 auto;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      min-height: 16px;
      line-height: 14px;
      font-size: ${fontSizes[0]};
      color: ${colors.red.primary};
      background: ${colors.red.primary};
      border-radius: ${radii[0]};
      width: 24px;
      height: 20px;
    `}

  ${space}
    ${color}
    ${space}
    ${layout}
    ${position}
`;

const StyledBadgeContent: any = styled.div`
  ${({ theme: { fontSizes, colors } }) => `
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[0]};
        color: ${colors.white};
    `}

  ${color}
`;

export const Badge = (props: BadgeProps) => {
  const { children, color, ...styleProps } = props;
  return (
    <StyledBadge {...styleProps}>
      <StyledBadgeContent color={color}>{children}</StyledBadgeContent>
    </StyledBadge>
  );
};
