import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { MandateTypeResponse } from "../types/MandateType";

export const useCreateMandateTypeFromCheckpointIds = () => {
  const { authHeader } = useAuth();

  return useMutation((params: { checkpoint: number[] }) => {
    return axios.post<MandateTypeResponse>(
      `${env.verifyServiceUrl}individual/mandate_type/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
