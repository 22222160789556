import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { Consultant } from "../types/Consultant";

interface Params {
  name: string;
  email: string;
}

export const useCreateConsultant = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post<Consultant>(
      `${env.verifyServiceUrl}individual/mandate_consultant/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
