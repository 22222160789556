import { PagedResponse } from "../types/Common";
import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { DPOPeriod } from "../types/DPO";

const fetch = (authHeader: string) => () =>
  axios.get<PagedResponse<DPOPeriod[]>>(`${env.verifyServiceUrl}individual/data_retention_setting/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useDPOPeriodDetails = () => {
  const { authHeader } = useAuth();
  return useQuery(["dpoPeriodDetails"], fetch(authHeader), {
    retry: false,
  });
};
