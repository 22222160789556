import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { IndividualDetails } from "../types/Individual";

const fetch = (authHeader: string, individualId: number) => () =>
  axios.get<IndividualDetails>(
    `${env.verifyServiceUrl}individual/individual_v2/${individualId}/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useIndividual = (id) => {
  const { authHeader } = useAuth();
  return useQuery(["individual", id], fetch(authHeader, id), {retry: false});
};
