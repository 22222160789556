import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { SOFRiskLevel } from "../types/SOFRiskLevel";

interface SOFRiskLevelResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: SOFRiskLevel[];
}

const fetch = (authHeader: string) => () =>
  axios.get<SOFRiskLevelResponse>(
    `${env.verifyServiceUrl}individual/sof_risk_level/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useSOFRiskLevel = () => {
  const { authHeader } = useAuth();
  return useQuery(["sofRiskLevel"], fetch(authHeader), {
    retry: false,
    select: (response) => response.data.results,
  });
};
