import { Link } from "react-router-dom";
import styled from "styled-components";
import { space, color, typography } from "styled-system";

export const RouteLink = styled(Link)`
  font-family: "Inter", sans-serif;
  ${({ theme: { fontSizes, colors } }) => `
    font-size: ${fontSizes[2]};
    color: ${colors.blue.primary};
    cursor: pointer;
  `}

  ${typography}
  ${color}
  ${space}
`;
