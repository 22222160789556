import styled from "styled-components";
import { fadeIn } from "../animation";
import { Paragraph } from "../Paragraph";
import type { MandateType } from "../../types/MandateType";
import { Link } from "react-router-dom";
import { Flex } from "../Flex";
import { Box } from "../Box";
import { Image } from "../Image";
import { DateTime } from "luxon";
import * as icons from "../../assets/checkpoints";

const Wrapper = styled(Link)`
  animation: fadein 0.25s;
  ${fadeIn}

  ${({ theme: { colors, radii, space } }) => `
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;
      color: inherit;
      background: ${colors.gray[10]};
      border-radius: ${radii[1]};
      padding: ${space[2]};
      position: relative;
      transition: all 0.2s ease 0s;
      border: 1px solid white;

      &:hover {
        cursor: pointer;
        transform: scale(1.010, 1.010);
        box-shadow: 0px 5px 9px rgb(0 0 0 / 8%);
      }

      &:active {
        transform: scale(1, 1);
      }
  `}
`;

const StyledList = styled.ul`
  list-style-type: none;
  margin-top: 0;
  padding: 0;
  min-height: 100px;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  line-height: 1.8em;
  font-weight: 400 !important;
  ${({ theme: { fontFamily, fontSizes, colors } }) => `
  font-family: ${fontFamily};
  font-size: ${fontSizes[1]};
  color: ${colors.gray[60]};
  `}
`;
const ViewLink = styled(Box)`
  ${({ theme: { colors, fontFamily, fontSizes } }) => `
  font-family: ${fontFamily};
  font-size: ${fontSizes[1]};
  color: ${colors.blue["primary"]};
  text-decoration: none;
  `}
`;

export const ApplicationTypeCard = (props: MandateType) => {
  const { id, name, checkpoint, created_on } = props;

  const created = DateTime.fromISO(created_on).toFormat("d LLL, yyyy");

  return (
    <Wrapper
      to={
        checkpoint && checkpoint.length > 0
          ? `/settings/application-types/${id}`
          : `/settings/application-types/${id}/checks`
      }
    >
      <Paragraph>{name}</Paragraph>
      <Flex flexDirection="column" justifyContent="start">
        <StyledList>
          {checkpoint.length <= 5 ? (
            checkpoint.map((item) => (
              <StyledListItem key={item.id}>
                <Image
                  src={icons[item.name.replace("_&", "")]}
                  alt={`${item.actual_name} icon`}
                  width="22px"
                  mr={1}
                />
                {item.actual_name}
              </StyledListItem>
            ))
          ) : (
            <>
              {checkpoint.slice(0, 4).map((item) => (
                <StyledListItem key={item.id}>
                  <Image
                    src={icons[item.name.replace("_&", "")]}
                    alt={`${item.actual_name} icon`}
                    width="22px"
                    mr={1}
                  />
                  {item.actual_name}
                </StyledListItem>
              ))}
              <StyledListItem key="more">
                {" "}
                <Image
                  src={icons.additional_checks}
                  alt="Additional checks"
                  width="22px"
                  mr={1}
                />
                +{checkpoint.length - 4} checks
              </StyledListItem>
            </>
          )}
        </StyledList>
      </Flex>
      <Flex justifyContent="space-between" pt={1}>
        <Paragraph color="gray.20" my="0px" fontSize={1} fontWeight={400}>
          {created}
        </Paragraph>
        <ViewLink>View</ViewLink>
      </Flex>
    </Wrapper>
  );
};
