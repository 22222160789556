import { useEffect } from "react";
import { Slider } from "../../components/Slider";
import { Box } from "../../components/Box";
import { H3 } from "../../components/Heading";
import { useTheme } from "styled-components";
import { useSupportContactForm } from "../../data/useSupportContactForm";
import { Flex } from "../../components/Flex";
import { Loader } from "../../components/Loader";

const injectHubSpotFormStyles = (theme) => {
  const style = document.createElement("style");
  style.innerHTML = `
      .hs-form label {
        color: ${theme.colors.blue[100]};
        font-family: ${theme.fontFamily};
        font-szie: ${theme.fontSizes[0]};
      }
      .hs-form input, .hs-form textarea, .hs-form select {
        // background-color: ${theme.colors.background};
        color: ${theme.colors.blue[100]};
        // border: 1px solid ${theme.colors.secondary};
      }
      .hs-form input[type="submit"] {
        background-color: ${theme.colors.blue.primary};
        color: ${theme.colors.white};
      }
    //   .hs-form input[type="submit"]:hover {
    //     background-color: ${theme.colors.secondary};
    //   }
    `;
  document.head.appendChild(style);
};

const HubSpotForm = ({ isOpen, onRequestClose }) => {
  const { data, isLoading } = useSupportContactForm();
  const theme = useTheme();
  useEffect(() => {
    if (isOpen) {
      const script = document.createElement("script");
      script.src = data.data.src;
      script.async = true;
      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: data.data.region, // Replace with your region if different
            portalId: data.data.portal_id,
            formId: data.data.form_id,
            target: "#hubspotForm",
          });
          // Inject custom styles
          injectHubSpotFormStyles(theme);
        }
      };
      document.body.appendChild(script);

      // Cleanup function to remove the script
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen]); // This effect will run whenever isOpen changes

  return (
    <Slider isOpen={isOpen} onRequestClose={onRequestClose} width="420px">
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}
      {!isLoading && (
        <Box px={3} pt={2}>
          <H3>Contact Support</H3>
          <Box py={2} id="hubspotForm"></Box>
        </Box>
      )}
    </Slider>
  );
};

export default HubSpotForm;
