import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { PagedResponse } from "../types/Common";
import { User } from "../types/User";
import { env } from "../utils/env";


const fetchUsers = (authHeader: string) => () =>
  axios.get<PagedResponse<User[]>>(`${env.verifyServiceUrl}user/user/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useUsers = () => {
  const { authHeader } = useAuth();
  const query = useQuery(["users"], fetchUsers(authHeader), {retry: false});

  return query;
};
