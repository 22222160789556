import { useState } from "react";
import styled from "styled-components";
import Topbar from "../../components/Topbar";
import { Box } from "../../components/Box";
import { H2 } from "../../components/Heading";
import { Card, GrayCard } from "../../components/Card";
import { Paragraph } from "../../components/Paragraph";
import getInTouch from "../../assets/get-in-touch.svg";
import { Image } from "../../components/Image";
import { Flex } from "../../components/Flex";
import { Button } from "../../components/Button";
import HubSpotForm from "./HubSpotForm";
import { useHelpSupportOptions } from "../../data/useHelpSupportOptions";
import { Loader } from "../../components/Loader";

const CardLine = ({ children, ...styleProps }) => (
  <Paragraph
    fontSize={3}
    color="blue.100"
    fontWeight={600}
    my={0}
    {...styleProps}
  >
    {children}
  </Paragraph>
);

const CardSubline = ({ children, ...styleProps }) => (
  <Paragraph fontSize={1} color="gray.50" my={0} {...styleProps}>
    {children}
  </Paragraph>
);

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${({ theme: { space } }) => `
    grid-gap: ${space[3]};
  `}
`;

const ImageOverlay = styled(Box)`
  ${({ theme: { space, colors } }) => `
    border-radius: 50%;
    background-color: ${colors.gray[100]};
    min-width:60px;
    height:60px;
    text-align: center;
  `}
`;

export const HelpSupport = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const { data: helpSupportOptionList, isLoading } = useHelpSupportOptions();

  return (
    <>
      <Topbar title="Help/Support" />
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {!isLoading && (
        <Box height="calc(100vh - 83px)" overflowY="auto" p={3}>
          <H2>How we can help?</H2>

          <Grid>
            {helpSupportOptionList.data.map((helpCard, index) => (
              <Card
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() =>
                  window.open(helpCard.redirect_link, "_blank", "")
                }
              >
                <Flex gap="12">
                  <ImageOverlay>
                    <Image
                      src={`data:image/jpg;base64,${helpCard.icon}`}
                      alt="download pdf"
                      height="100%"
                      width="100%"
                    />
                  </ImageOverlay>
                  <Box>
                    <CardLine>{helpCard.name}</CardLine>
                    <CardSubline>{helpCard.description}</CardSubline>
                  </Box>
                </Flex>
              </Card>
            ))}
          </Grid>

          <GrayCard backgroundColor={"gray.30"} mx={"0px"} my={3}>
            <Flex justifyContent={"space-between"}>
              <Box maxWidth={365}>
                <H2>Can't find what you're looking for?</H2>
                <CardSubline my={2}>
                  If you can't find the answer you're looking for, our support
                  team is here to help
                </CardSubline>

                <Button
                  onClick={() => setIsContactFormOpen(!isContactFormOpen)}
                  variant="primary"
                  mt={3}
                >
                  Get in touch
                </Button>
              </Box>
              <Box position="relative" width="50%">
                <Image
                  src={getInTouch}
                  alt="Get in touch"
                  width="70%"
                  height="100%"
                  mt={3}
                />
              </Box>
            </Flex>
          </GrayCard>
        </Box>
      )}

      <HubSpotForm
        isOpen={isContactFormOpen}
        onRequestClose={() => setIsContactFormOpen(false)}
      />
    </>
  );
};

export default HelpSupport;
