import { Flex } from "../components/Flex";
import { Box } from "../components/Box";
import { Image } from "../components/Image";
import { H1 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Button } from "../components/Button";
import tillerVerifySvg from "../assets/verify-tiller.svg";
import notFound from "../assets/not-found.svg";
import React from "react";

class ErrorBoundary extends React.Component<{ children: any }, { errorInfo: any, error: any, hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex height="100vh">
          <Flex
            width="900px"
            justifyContent="space-between"
            flexDirection="column"
            p="60px"
          >
            <Image
              src={tillerVerifySvg}
              alt="tiller verify logo"
              height="80px"
              width="100px"
            />
            <Box>
              <H1 color="darkBlue">
                Something went wrong!
              </H1>
              <Button size="large" width="100%" mt="4" onClick={() => window.location.reload()}>
                Refresh page
              </Button>
            </Box>
            <Box />
          </Flex>
          <Flex
            bg={"background"}
            height="100vh"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
            pt="5"
          >
            <Box width="55%">
              <H1 color="darkBlue">Something went wrong!</H1>
              <Paragraph color="gray.50" fontSize="3">
                An error has occurred while viewing this page.
              </Paragraph>
            </Box>
            <Image src={notFound} alt="folder" display="block" maxWidth="60%" />
          </Flex>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
