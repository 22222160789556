import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { User } from "../types/User";
import { env } from "../utils/env";

const fetchUser = (authHeader: string, id: number) => () =>
  axios.get<User>(`${env.verifyServiceUrl}user/user/${id}/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useOtherUserDetails = (id, enabled: boolean) => {
  const { authHeader } = useAuth();

  return useQuery(["userDetails", id], fetchUser(authHeader, id), {
    enabled, retry: false
  });
};
